import { useEffect, useState } from "react";
import ButtonCreated from "../components/ButtonCreated";
import CardProject from "../components/CardProject";
import HeaderProfile from "../components/HeaderProfile";
import HeaderSearch from "../components/HeaderSearch";
import ModalContainer from "../components/ModalContainer";
import ModalCreateProject from "../components/ModalCreateProject";
import notExistProject from "../../../images/Svg/notExistProject.svg"
import api from "../../../services/api";

const DashboardMobile = () => {
  const [modal, setModal] = useState(false);
  const [projects, setProjects] = useState()

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id")


  const container = {
    padding: "19px 0",
    backgroundColor: "#E5E5E520",
  };

  const getProjects = async () => {

    const response = await api.post("project/pay", {

      mail: localStorage.getItem("@MAIL")

    })
    setProjects(response.data)


  }
  const getLastId = async () => {
    const response = await api.post("project/last/",{
        mail:localStorage.getItem("@MAIL")
    })
    if(response.status == 200){
      window.location.href=`functionalities/?id=${response.data.id}&create_project=true`
    }

  }

  useEffect(() => {
    getProjects()
  }, [])


  return (
    <div style={container}>
      <HeaderProfile />

      <HeaderSearch />

      {
        projects?.length > 0 ? 
        projects.map((item) => {
       return (
      <CardProject
        id={item.id}
        projectLenght={projects.length}
        name={item.name}
        global_id={item.global_id}
        project_id={item.project_id}
        type_project={item.type_project}
      />
      )
    })
      :

      <div style={{marginTop: "87px", marginBottom: "25vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <img style={{maxWidth: "422px", width: "80vw"}} src={notExistProject} />
      </div>
    }


      <ButtonCreated onClick={() => getLastId()} />

      <ModalContainer isActive={modal} closeModal={() => setModal(false)}>
        <ModalCreateProject closeModal={() => setModal(false)} />
      </ModalContainer>
    </div>
  );
};

export default DashboardMobile;
