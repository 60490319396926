import React,{createContext,useEffect,useState} from 'react'

export const PlatFormContext = createContext({})

function PlatFormProvider({children}){

    const [type,setType] = useState(1)

    const typePlatform = (value) => {

        setType(value)
        
    }

  
    return(
    <PlatFormContext.Provider value={{typePlatform,type}}>
        {children}
    </PlatFormContext.Provider>
    )

}

export default PlatFormProvider