import { useState } from "react"


const CardVelocityProject = ({velocity,setVelocity}) => {

    return (

        <div style={{
            padding: "24px 25px"
        }}>
            <h3 className="textDefinition">Definição geral</h3>

            <div style={{
                border: "1px solid #D7D7D7",
                borderRadius: "15px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "23px 24px"
            }}>



                <p className="textVelocityProject">Velocidade de entrega</p>

                <input style={{width: "78%"}} type="range" min="1" step="1" defaultValue="2" onChange={(e) => setVelocity(e.target.value)} max="3" className='input_range' id="myRange" />

                {velocity == 1

                    ?
                    <p className='textTypeVelocity'>Lento</p>
                    :
                    <></>
                }

                {velocity == 2

                    ?
                    <p className='textTypeVelocity'>Normal</p>
                    :
                    <></>
                }


                {velocity == 3

                    ?
                    <p className='textTypeVelocity'>Rápido</p>
                    :
                    <></>
                }


            </div>
        </div>
    )
}

export default CardVelocityProject