import React, { createContext, useState, useEffect } from 'react'
import api from '../services/api'
import {toast } from 'react-toastify';

export const CartContext = createContext({})

function CartProvider({ children }) {

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id")


  const [updateCart, setUpdateCart] = useState(false)
  const [updateComponent, setUpdateComponent] = useState(false)
  const [nextDataValue, setNextDataValue] = useState(0)

  const [selectUserItens, setSelectUserItens] = useState([])
  const [price, setPrice] = useState()
  const [developTime, setDevelopTime] = useState()

  const [itemsInCart, setItemsInCart] = useState()
  const [selectItems, setSelectItems] = useState()
  const [selectValue, setSelectValue] = useState()

  const [itemsCart2, setItemsCart2] = useState([])
  const [itemsSelected, setItemsSelected] = useState()

  async function addItemToCart(componentId, category, name, description, idUrl, page) {

    if (page == "update") {


      toast.success('Adicinando componente...', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,

        progress: undefined,
        theme: "light",
      });
      const response = await api.post("cart/update/component/", {

        "componentSelect": componentId,
        "project_id": idUrl,
        "mail": localStorage.getItem("@MAIL"),
        "category": category,
        "name": name,
        "description": description,
        "project_name": localStorage.getItem("@NAME_PROJECT"),
        "check": "true"
      })


      const items = await api.post("cart/items/cart/update/", {


        "mail": localStorage.getItem("@MAIL"),
        "project_id": idUrl

      })

      setItemsSelected(items.data.items)



      if (response.status == 200) {
        setUpdateCart(!updateCart)
      }
      return false
    }

    toast.success('Adicinando componente...', {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,

      progress: undefined,
      theme: "light",
    });
    const response = await api.post("cart/component", {

      "componentSelect": componentId,
      "project_id": idUrl,
      "mail": localStorage.getItem("@MAIL"),
      "category": category,
      "name": name,
      "description": description,
      "project_name": localStorage.getItem("@NAME_PROJECT"),
      "check": "true"
    })

  

    const selectItens = await api.post("cart/category", {

      "category": category,
      "mail": localStorage.getItem("@MAIL"),

    })

    setSelectUserItens({ items: selectItens.data.items, category: category })
    setUpdateCart(!updateCart)

    if (response.status == 200) {

      setUpdateCart(!updateCart)

    }


    const items = await api.post("cart/items", {


      "mail": localStorage.getItem("@MAIL"),
      "category": category,
      "project_id": idUrl

    })

    setSelectItems(items.data.total)
    setItemsSelected(items.data.total)

  }
  async function getPriceCart() {

    const responseInfoComponent = await api.post(`cart/value/`, {
      mail: localStorage.getItem("@MAIL"),
      project_id: id

    })

    setPrice(responseInfoComponent.data.price)
    setDevelopTime(responseInfoComponent.data.developTime)
    setItemsInCart(responseInfoComponent.data.items)

  }
  async function removeItemCart(componentId, category, page) {

    if (page == "update") {


      toast.success('Removendo componente...', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,

        progress: undefined,
        theme: "light",
      });

      const response = await api.post(`cart/update/delete`, {
        "id": componentId,
      })

      if (response.status == 200) {

        const urlParams = new URLSearchParams(window.location.search);
        const id_info = urlParams.get("id")

        const items = await api.post("cart/items/cart/update/", {


          "mail": localStorage.getItem("@MAIL"),
          "project_id": id_info
        })

        setItemsSelected(items.data.items)

        setUpdateCart(!updateCart)

      }
      return false
    }

    const urlParams = new URLSearchParams(window.location.search);
    const id_info = urlParams.get("id")

    const response = await api.post(`cart/delete`, {

      "id": componentId,
      "project_id": id_info

    })



    toast.success('Removendo componente...', {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,

      progress: undefined,
      theme: "light",
    });



    if (response.status == 200) {

      setUpdateCart(!updateCart)
      console.log("update :))")
    }


    const selectItens = await api.post("cart/category", {

      "category": category,
      "mail": localStorage.getItem("@MAIL"),

    })

    setSelectUserItens({ items: selectItens.data.items, category: category })

    const items = await api.post("cart/items", {


      "mail": localStorage.getItem("@MAIL"),
      "category": category,
      "project_id": id_info

    })



    setSelectItems(items.data.total)

  }
  async function removeItemCartProject(componentId, project_id) {
    const response = await api.post(`/cart/delete`, {
      "id": componentId,
      "project_id": project_id
    })

    setUpdateCart(!updateCart)
  }
  async function storageAllComponents(category) {

    const projectChange = localStorage.getItem("@PROJECT_CHANGE_ID")

    if (projectChange) {

      const response = await api.post("/cart/all/component", {

        "project_id": projectChange,
        "mail": localStorage.getItem("@MAIL"),
        "category": category
      })


      if (response.status == 200) {

        window.location.href = "/functionalities"

      }
      return false
    }


  }
  async function getItems(category, idUrl, page) {

    if (page == "update") {


      const items = await api.post("cart/items/cart/update/", {
        "mail": localStorage.getItem("@MAIL"),
        "category": category,
        "project_id": idUrl,
      })

      setItemsSelected(items.data.total)

      return false
    }

    const items = await api.post("cart/items", {
      "mail": localStorage.getItem("@MAIL"),
      "category": category,
      "project_id": idUrl,
    })



    setItemsSelected(items.data.total)

  }
  async function getItemsProjectPay(category, idUrl) {


    const items = await api.post("components/project/cart/items", {

      "mail": localStorage.getItem("@MAIL"),
      "category": category,
      "project_id": idUrl
    })

    setItemsSelected(items.data.total)

  }
  async function getItemsInCart(category) {


    const getItemsCart = await api.post("cart/items", {

      "mail": localStorage.getItem("@MAIL"),
      "category": category,
      "project_id": localStorage.getItem("@PROJECT_CHANGE_ID")
    })


    setSelectItems(getItemsCart.data.total)
  }
  const removeCustomComponent = async (id) => {

    const response = await api.delete(`custom/project/component/${id}`)
    setUpdateCart(!updateCart)

  }

  useEffect(() => {

    getPriceCart()

  }, [])
  useEffect(() => {
    const getItemsInCart = async () => {


      const responseInfoComponent = await api.post(`cart/value/`, {
        mail: localStorage.getItem("@MAIL"),
        project_id: id

      })

      setPrice(responseInfoComponent.data.price)
      setDevelopTime(responseInfoComponent.data.developTime)
      setItemsInCart(responseInfoComponent.data.items)

    }

    getItemsInCart()
  }, [selectItems, updateCart, updateComponent, selectValue])

  useEffect(() => {
    const getItemsInCart = async () => {

      const responseInfoComponent = await api.post(`cart/value/`, {
        mail: localStorage.getItem("@MAIL"),
        project_id: id

      })

      setPrice(responseInfoComponent.data.price)
      setDevelopTime(responseInfoComponent.data.developTime)
      setItemsInCart(responseInfoComponent.data.items)

    }

    getItemsInCart()
  }, [])

  useEffect(() => {


    api.post(`cart/simple/sidebar`, {

      "id": id,
      "mail": localStorage.getItem("@MAIL"),
    }).then((response) => {

      setItemsCart2(response.data)



    })
  }, [updateCart])

  return (

    <CartContext.Provider value={{
      addItemToCart,
      removeItemCart,
      storageAllComponents,
      removeItemCartProject,
      removeCustomComponent,
      getItemsInCart,
      getItemsProjectPay,
      updateCart,
      nextDataValue,
      selectUserItens,
      updateComponent,
      price,
      developTime,
      itemsInCart,
      selectItems,
      setSelectValue,
      itemsCart2,
      setUpdateCart,
      getPriceCart,
      getItems,
      itemsSelected
    }}>
      {children}
    </CartContext.Provider>


  )

}

export default CartProvider