import React from 'react'
import notFoundIcon from '../../images/Svg/notFoundIcon.svg'
const NotFound = () => {

    const btn = {
        width: "288px",
        height: "48px",
        background: "#1172EB",
        borderRadius: 60,
        marginTop: "6.68rem",
        marginBottom: "3.68rem",
        border: "none"
    }

    return (

        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", paddingTop: "2rem" }}>

            <img src={notFoundIcon} className='notFoundImg'/>
            <button className="textBtnNotFound" onClick={() => window.location.href = "/login"} style={btn}>Voltar para a tela de inicio</button>
        </div>

    )

}

export default NotFound