import React, { useState, useEffect, useContext } from 'react'
import checkIcon from '../../images/Svg/checkComponent.svg'

import api from '../../services/api'
import { CartContext } from '../../context/cart'
import { ComponentContext } from '../../context/component'
var lz = require('lz-string');


const SubFunctionalitieUpdate = ({ id, name, price, category, description, arrowTop, develop_time, icon, project_id, page }) => {

    const { addItemToCart, removeItemCart } = useContext(CartContext)
    const { loadImage } = useContext(ComponentContext)

    const [check, setCheck] = useState(false)
    const [showComponent, setShowComponent] = useState(false)

    const [cartItems, setCartItems] = useState([])
    const [cartItems2, setCartItems2] = useState([])
    const [imageDecompress, setImageDecompress] = useState()

    const [stateUpdate, setStateUpdate] = useState()

    const urlParams = new URLSearchParams(window.location.search);
    const id_info = urlParams.get("id")


    function addItemCart(idItem, category, name, description, page) {

        addItemToCart(idItem, category, name, description, id_info, page)
    }
    function deleteItemCart(idItem, category,page) {
        
        removeItemCart(idItem, category,page)
        setCheck("trash")
    }

    function showImage(idImage) {

        loadImage(idImage, description, price)
    }
    const getItemsInCart = async () => {

        const response = await api.post("cart/simple/user/pay", {

            "mail": localStorage.getItem("@MAIL"),
            "project_id": id_info,
        })



        if (response.status == 200) {

            setCartItems(response.data)
            localStorage.setItem("@TOTAL_FEATURES", response.data.length)
        }


    }
    const getItemsInCartUpdate = async () => {

        const response = await api.post("cart/updates/simple/user/pay", {

            "mail": localStorage.getItem("@MAIL"),
            "project_id": id_info,
        })



        if (response.status == 200) {

            setCartItems2(response.data)
            localStorage.setItem("@TOTAL_FEATURES", response.data.length)
        }


    }
    const getItemsChecked = (id) => {
        localStorage.setItem("@LAST_CHECK", id)
    }

    useEffect(() => {
        getItemsInCart()
        getItemsInCartUpdate()

    }, [])
    useEffect(() => {

        const image = lz.decompressFromUTF16(icon)

        setImageDecompress(image)

    }, [icon])
    useEffect(() => {

        cartItems2.map(item => {
            if (item.componentSelect == id) {
                setStateUpdate("remove")
            }
        })


    }, [cartItems2])
    useEffect(() => {

        cartItems.map(item => {
            if (item.id == id) {
                setStateUpdate(false)
            }
        })


    }, [cartItems])


    return (

        arrowTop == true

            ?

            <div id={category}>

                <div class="card card-body" style={{ background: "#F9F9FA", borderRadius: "0px", height: "auto", border: "none", borderBottom: "1px solid #D7D7D7" }}>

                    <div style={{ display: "flex", alignItems: 'center', height: "50px", justifyContent: "space-between" }}>

                        {showComponent == true && localStorage.getItem("@LAST_CHECK") == id

                            ?
                            <div className="borderLeftSubFunctionalitie"></div>

                            :
                            <></>

                        }

                        {imageDecompress == undefined

                            ?

                            <div class="ssc-line" style={{ width: "21px", height: "21px", borderRadius: "2px" }}></div>

                            :

                            <>

                                {stateUpdate == "check" || stateUpdate == false || stateUpdate == "remove"

                                    ?
                                    <img src={checkIcon} style={{ zIndex: "1", background: "#F9F9FA", marginLeft: "0", width: "24px" }} />

                                    :

                                    <img style={{ objectFit: 'contain', width: '19px', height: "19px", borderRadius: '5px' }} src={"data:image/png;base64," + imageDecompress} />
                                }
                            </>

                        }

                        <div style={{ height: 'auto', paddingLeft: "0.5rem" }}>

                            <div style={{ width: "170px" }}>
                                <p className="textSubFunctionlitie">{name}</p>
                            </div>
                            {arrowTop == true

                                ?

                                <div style={{ display: "flex", width: "220px", height: "0.5rem" }}>
                                    <p className="textValueFunction">{String(develop_time) == 1 ? `${develop_time} dia` : `${develop_time} dias`}</p>
                                    <p className="textDevelopFunction">{price != undefined ? Number(price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : <></>}</p>

                                </div>
                                :
                                <></>

                            }
                        </div>




                        {arrowTop == true

                            ?


                            <div style={{ display: "flex" }}>

                                {showComponent == true && localStorage.getItem("@LAST_CHECK") == id

                                    ?
                                    <div style={{ cursor: "pointer", marginLeft: "-60px" }} onClick={() => { setShowComponent(!showComponent); showImage(id, description) }}>



                                        <svg width="30" height="30" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="1" width="23" height="23" rx="4.5" fill="#1172EB" stroke="#1172EB" />
                                            <path d="M18.5744 10.9945C17.6697 9.521 15.6117 7.04883 11.9997 7.04883C8.38766 7.04883 6.32966 9.521 5.42491 10.9945C5.14522 11.4469 4.99707 11.9682 4.99707 12.5001C4.99707 13.0319 5.14522 13.5533 5.42491 14.0057C6.32966 15.4792 8.38766 17.9513 11.9997 17.9513C15.6117 17.9513 17.6697 15.4792 18.5744 14.0057C18.8541 13.5533 19.0023 13.0319 19.0023 12.5001C19.0023 11.9682 18.8541 11.4469 18.5744 10.9945ZM17.5798 13.3949C16.8028 14.6584 15.0441 16.7847 11.9997 16.7847C8.95525 16.7847 7.1965 14.6584 6.4195 13.3949C6.25333 13.126 6.16531 12.8162 6.16531 12.5001C6.16531 12.184 6.25333 11.8741 6.4195 11.6052C7.1965 10.3417 8.95525 8.2155 11.9997 8.2155C15.0441 8.2155 16.8028 10.3394 17.5798 11.6052C17.746 11.8741 17.834 12.184 17.834 12.5001C17.834 12.8162 17.746 13.126 17.5798 13.3949Z" fill="white" />
                                            <path d="M11.9997 9.58301C11.4228 9.58301 10.8589 9.75407 10.3793 10.0746C9.89962 10.395 9.52578 10.8506 9.30503 11.3835C9.08427 11.9165 9.02651 12.5029 9.13905 13.0687C9.25159 13.6345 9.52938 14.1542 9.93728 14.5621C10.3452 14.97 10.8649 15.2477 11.4307 15.3603C11.9964 15.4728 12.5829 15.4151 13.1158 15.1943C13.6488 14.9736 14.1043 14.5997 14.4248 14.1201C14.7453 13.6404 14.9163 13.0765 14.9163 12.4997C14.9154 11.7264 14.6078 10.9851 14.061 10.4383C13.5143 9.89152 12.7729 9.58393 11.9997 9.58301ZM11.9997 14.2497C11.6536 14.2497 11.3152 14.147 11.0274 13.9547C10.7396 13.7624 10.5153 13.4891 10.3829 13.1694C10.2504 12.8496 10.2158 12.4977 10.2833 12.1583C10.3508 11.8188 10.5175 11.507 10.7622 11.2622C11.007 11.0175 11.3188 10.8508 11.6583 10.7833C11.9977 10.7158 12.3496 10.7504 12.6694 10.8829C12.9891 11.0153 13.2625 11.2396 13.4547 11.5274C13.647 11.8152 13.7497 12.1536 13.7497 12.4997C13.7497 12.9638 13.5653 13.4089 13.2371 13.7371C12.9089 14.0653 12.4638 14.2497 11.9997 14.2497Z" fill="white" />
                                        </svg>
                                    </div>

                                    :
                                    <div style={{ cursor: "pointer", marginLeft: "-60px" }} onClick={() => { setShowComponent(true); showImage(id, description); getItemsChecked(id) }}>

                                        <svg width="30" height="30" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="1" width="23" height="23" rx="4.5" fill="white" stroke="#D7D7D7" />
                                            <g clip-path="url(#clip0_2414_2488)">
                                                <path d="M19.5442 11.3867C18.5748 9.80793 16.3698 7.15918 12.4998 7.15918C8.62985 7.15918 6.42485 9.80793 5.45547 11.3867C5.1558 11.8714 4.99707 12.43 4.99707 12.9998C4.99707 13.5697 5.1558 14.1282 5.45547 14.6129C6.42485 16.1917 8.62985 18.8404 12.4998 18.8404C16.3698 18.8404 18.5748 16.1917 19.5442 14.6129C19.8439 14.1282 20.0026 13.5697 20.0026 12.9998C20.0026 12.43 19.8439 11.8714 19.5442 11.3867ZM18.4786 13.9586C17.6461 15.3123 15.7617 17.5904 12.4998 17.5904C9.23797 17.5904 7.3536 15.3123 6.5211 13.9586C6.34306 13.6705 6.24876 13.3385 6.24876 12.9998C6.24876 12.6611 6.34306 12.3292 6.5211 12.0411C7.3536 10.6873 9.23797 8.40918 12.4998 8.40918C15.7617 8.40918 17.6461 10.6848 18.4786 12.0411C18.6566 12.3292 18.7509 12.6611 18.7509 12.9998C18.7509 13.3385 18.6566 13.6705 18.4786 13.9586Z" fill="black" />
                                                <path d="M12.5 9.875C11.8819 9.875 11.2777 10.0583 10.7638 10.4017C10.2499 10.745 9.8494 11.2331 9.61288 11.8041C9.37635 12.3751 9.31447 13.0035 9.43505 13.6096C9.55563 14.2158 9.85325 14.7727 10.2903 15.2097C10.7273 15.6467 11.2842 15.9444 11.8903 16.0649C12.4965 16.1855 13.1249 16.1236 13.6959 15.8871C14.2669 15.6506 14.755 15.25 15.0983 14.7361C15.4417 14.2222 15.625 13.6181 15.625 13C15.624 12.1715 15.2944 11.3772 14.7086 10.7914C14.1228 10.2056 13.3285 9.87599 12.5 9.875ZM12.5 14.875C12.1292 14.875 11.7666 14.765 11.4583 14.559C11.15 14.353 10.9096 14.0601 10.7677 13.7175C10.6258 13.3749 10.5887 12.9979 10.661 12.6342C10.7334 12.2705 10.912 11.9364 11.1742 11.6742C11.4364 11.4119 11.7705 11.2334 12.1342 11.161C12.4979 11.0887 12.8749 11.1258 13.2175 11.2677C13.5601 11.4096 13.853 11.65 14.059 11.9583C14.265 12.2666 14.375 12.6292 14.375 13C14.375 13.4973 14.1775 13.9742 13.8258 14.3258C13.4742 14.6774 12.9973 14.875 12.5 14.875Z" fill="black" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_2414_2488">
                                                    <rect width="15" height="15" fill="white" transform="translate(5 5.5)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                }




                                {stateUpdate == false

                                    ?
                                    <></>
                                    :

                                    stateUpdate == "check" || stateUpdate == "remove"
                                    
                                    ?

                                    
                                    <>

                                        <div onClick={() => {deleteItemCart(id,category,page);setStateUpdate(true)}} style={{ cursor: "pointer", marginLeft: "9px", position: "absolute", right: "22px", top: "27px", zIndex: "3", background: "white" }}>

                                            <svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_2415_2634)">
                                                    <path d="M12.2498 2.33333H10.4415C10.3061 1.67499 9.9479 1.08345 9.42724 0.658418C8.90657 0.233386 8.25529 0.000848472 7.58317 0L6.41651 0C5.74439 0.000848472 5.09311 0.233386 4.57244 0.658418C4.05178 1.08345 3.69356 1.67499 3.55817 2.33333H1.74984C1.59513 2.33333 1.44675 2.39479 1.33736 2.50419C1.22796 2.61358 1.1665 2.76196 1.1665 2.91667C1.1665 3.07137 1.22796 3.21975 1.33736 3.32914C1.44675 3.43854 1.59513 3.5 1.74984 3.5H2.33317V11.0833C2.3341 11.8566 2.64169 12.5979 3.18847 13.1447C3.73525 13.6915 4.47658 13.9991 5.24984 14H8.74984C9.5231 13.9991 10.2644 13.6915 10.8112 13.1447C11.358 12.5979 11.6656 11.8566 11.6665 11.0833V3.5H12.2498C12.4046 3.5 12.5529 3.43854 12.6623 3.32914C12.7717 3.21975 12.8332 3.07137 12.8332 2.91667C12.8332 2.76196 12.7717 2.61358 12.6623 2.50419C12.5529 2.39479 12.4046 2.33333 12.2498 2.33333ZM6.41651 1.16667H7.58317C7.945 1.16711 8.29783 1.27947 8.59328 1.48834C8.88873 1.69721 9.11233 1.99237 9.23343 2.33333H4.76626C4.88735 1.99237 5.11095 1.69721 5.4064 1.48834C5.70185 1.27947 6.05468 1.16711 6.41651 1.16667ZM10.4998 11.0833C10.4998 11.5475 10.3155 11.9926 9.98728 12.3208C9.65909 12.649 9.21397 12.8333 8.74984 12.8333H5.24984C4.78571 12.8333 4.34059 12.649 4.0124 12.3208C3.68421 11.9926 3.49984 11.5475 3.49984 11.0833V3.5H10.4998V11.0833Z" fill="#23262F" />
                                                    <path d="M5.83333 10.5002C5.98804 10.5002 6.13641 10.4387 6.24581 10.3293C6.35521 10.2199 6.41666 10.0715 6.41666 9.91683V6.41683C6.41666 6.26212 6.35521 6.11375 6.24581 6.00435C6.13641 5.89495 5.98804 5.8335 5.83333 5.8335C5.67862 5.8335 5.53025 5.89495 5.42085 6.00435C5.31146 6.11375 5.25 6.26212 5.25 6.41683V9.91683C5.25 10.0715 5.31146 10.2199 5.42085 10.3293C5.53025 10.4387 5.67862 10.5002 5.83333 10.5002Z" fill="#23262F" />
                                                    <path d="M8.16684 10.5002C8.32155 10.5002 8.46992 10.4387 8.57932 10.3293C8.68872 10.2199 8.75017 10.0715 8.75017 9.91683V6.41683C8.75017 6.26212 8.68872 6.11375 8.57932 6.00435C8.46992 5.89495 8.32155 5.8335 8.16684 5.8335C8.01212 5.8335 7.86375 5.89495 7.75435 6.00435C7.64495 6.11375 7.5835 6.26212 7.5835 6.41683V9.91683C7.5835 10.0715 7.64495 10.2199 7.75435 10.3293C7.86375 10.4387 8.01212 10.5002 8.16684 10.5002Z" fill="#23262F" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_2415_2634">
                                                        <rect width="14" height="14" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>

                                    </>


                                    :



                                    <div style={{ cursor: "pointer", marginLeft: "5px" }} onClick={() => { addItemCart(id, category, name, description, page); setStateUpdate("check"); getItemsInCart(category) }} >
                                        <svg width="30" height="30" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="1" width="23" height="23" rx="4.5" fill="white" stroke="#D7D7D7" />
                                            <path d="M18.125 11.625H12.875V6.375C12.875 5.89175 12.4832 5.5 12 5.5C11.5168 5.5 11.125 5.89175 11.125 6.375V11.625H5.875C5.39175 11.625 5 12.0168 5 12.5C5 12.9832 5.39175 13.375 5.875 13.375H11.125V18.625C11.125 19.1082 11.5168 19.5 12 19.5C12.4832 19.5 12.875 19.1082 12.875 18.625V13.375H18.125C18.6082 13.375 19 12.9832 19 12.5C19 12.0168 18.6082 11.625 18.125 11.625Z" fill="black" />
                                        </svg>
                                    </div>
                                }


                            </div>


                            :
                            <></>
                        }


                    </div>


                </div>

            </div>

            :
            <></>


    )

}

export default SubFunctionalitieUpdate