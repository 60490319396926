import React,{createContext,useState} from 'react'

import api from '../services/api'

export const CitysContext = createContext({})

function CitysProvider({children}){

    const [citys,setCitys] = useState([])
    const [update,setUpdate] = useState(false)

    const [state,setState] = useState()
    const [city,setCity] = useState()


    const changeCity = async (citys) => {

        setCitys(citys)
        setUpdate(!update)
    }

    const getState = (e) => {

        setState(e)
    }

    const getCity = (e) => {

        setCity(e)
    }

    
    return(
    <CitysContext.Provider value={{changeCity,getState,getCity,citys,update,state,city}}>
        {children}
    </CitysContext.Provider>
    )

}

export default CitysProvider