import React from 'react'
import documentIcon from '../../images/Svg/documentIcon.svg'

import pdf from '../../images/png/PDF.png'
import png from '../../images/png/PNG.png'
import svg from '../../images/png/SVG.png'

import jpeg from '../../images/png/JPEG.png'

import api from '../../services/api'


const Document = ({id,name,type,file}) => {

          
    const urlParams = new URLSearchParams(window.location.search);
    const idProject = urlParams.get("id") 
    async function remove(id){

        const response = await api.delete(`file/files/${id}`)

        window.location.href=`/follow/?id=${idProject}`

    }

    return(
        
        <div style={{width:"190px",height:"68px",borderRadius:"10px",border:"1px solid #D7D7D7",paddingLeft:"10px",display:"flex",alignItems:"center",position:"relative",marginBottom:"15px"}}>
        <a href={file}  download={file} style={{outline:"none"}}>



        {type == "application/pdf" 
        
        ?
        <div style={{border:"1px solid #D7D7D7",width:"41.29px",height:"45px",display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"15px"}}>

        <img src={pdf} style={{width:"25px",height:"30px"}}/>
        
        </div>

        :

        type == "image/png"

        ?
        <div style={{border:"1px solid #D7D7D7",width:"41.29px",height:"45px",display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"15px"}}>

        <img src={png} style={{width:"25px",height:"30px"}}/>
        
        </div>
        :

        type == "image/svg+xml" 

        ?
        <div style={{border:"1px solid #D7D7D7",width:"41.29px",height:"45px",display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"15px"}}>

        <img src={svg} style={{width:"25px",height:"30px"}}/>
        
        </div>
        :

        type == "image/jpeg"

        ?
        <div style={{border:"1px solid #D7D7D7",width:"41.29px",height:"45px",display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"15px"}}>

        <img src={jpeg} style={{width:"25px",height:"30px"}}/>
        
        </div>

        :
        
        <></>

        }

        </a>

        <input type={"text"} value={name} maxLength={10} disable={true}  style={{background:"none",border:"none",width:"100px",outline:"none"}} />


        <div style={{position:"absolute",right:"5px"}} onClick={() => remove(id)}>
        <svg width="20" height="20" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.2107 2.83333H13.0711C12.9109 2.03392 12.4871 1.31562 11.8711 0.799508C11.2551 0.283397 10.4845 0.00103029 9.68929 0L8.30895 0C7.51373 0.00103029 6.74317 0.283397 6.12715 0.799508C5.51112 1.31562 5.0873 2.03392 4.92711 2.83333H2.78758C2.60454 2.83333 2.42899 2.90796 2.29956 3.0408C2.17013 3.17364 2.09741 3.3538 2.09741 3.54166C2.09741 3.72953 2.17013 3.90969 2.29956 4.04253C2.42899 4.17537 2.60454 4.25 2.78758 4.25H3.47775V13.4583C3.47885 14.3973 3.84277 15.2975 4.4897 15.9614C5.13662 16.6254 6.01372 16.9989 6.92861 17H11.0696C11.9845 16.9989 12.8616 16.6254 13.5086 15.9614C14.1555 15.2975 14.5194 14.3973 14.5205 13.4583V4.25H15.2107C15.3937 4.25 15.5693 4.17537 15.6987 4.04253C15.8281 3.90969 15.9008 3.72953 15.9008 3.54166C15.9008 3.3538 15.8281 3.17364 15.6987 3.0408C15.5693 2.90796 15.3937 2.83333 15.2107 2.83333ZM8.30895 1.41667H9.68929C10.1174 1.4172 10.5348 1.55364 10.8844 1.80727C11.234 2.0609 11.4985 2.41931 11.6418 2.83333H6.35646C6.49973 2.41931 6.76428 2.0609 7.11384 1.80727C7.46341 1.55364 7.88086 1.4172 8.30895 1.41667ZM13.1402 13.4583C13.1402 14.0219 12.922 14.5624 12.5337 14.9609C12.1454 15.3594 11.6188 15.5833 11.0696 15.5833H6.92861C6.37948 15.5833 5.85283 15.3594 5.46454 14.9609C5.07624 14.5624 4.8581 14.0219 4.8581 13.4583V4.25H13.1402V13.4583Z" fill="black"/>
        <path d="M7.61961 12.7502C7.80266 12.7502 7.9782 12.6755 8.10764 12.5427C8.23707 12.4099 8.30978 12.2297 8.30978 12.0418V7.79183C8.30978 7.60397 8.23707 7.4238 8.10764 7.29096C7.9782 7.15812 7.80266 7.0835 7.61961 7.0835C7.43657 7.0835 7.26102 7.15812 7.13159 7.29096C7.00216 7.4238 6.92944 7.60397 6.92944 7.79183V12.0418C6.92944 12.2297 7.00216 12.4099 7.13159 12.5427C7.26102 12.6755 7.43657 12.7502 7.61961 12.7502Z" fill="black"/>
        <path d="M10.3804 12.7502C10.5634 12.7502 10.739 12.6755 10.8684 12.5427C10.9978 12.4099 11.0705 12.2297 11.0705 12.0418V7.79183C11.0705 7.60397 10.9978 7.4238 10.8684 7.29096C10.739 7.15812 10.5634 7.0835 10.3804 7.0835C10.1973 7.0835 10.0218 7.15812 9.89233 7.29096C9.7629 7.4238 9.69019 7.60397 9.69019 7.79183V12.0418C9.69019 12.2297 9.7629 12.4099 9.89233 12.5427C10.0218 12.6755 10.1973 12.7502 10.3804 12.7502Z" fill="black"/>
        </svg>
        </div>
        </div>
    )

}

export default Document