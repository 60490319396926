import iconPrototype from "../../../images/Svg/iconPrototype.svg"
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useState } from "react";
import api from "../../../services/api";
import { toast } from "react-toastify";

const CardPrototypeMobile = () => {


    const [total,setTotal] = useState(0)
    const [dueDay,setDueDay] = useState()

            
    const urlParams = new URLSearchParams(window.location.search);
    const  id = urlParams.get("id")

    const getInfoPrototype = async () => {

      const projectChange = localStorage.getItem("@PROJECT_CHANGE_ID")

      const response = await api.get(`duedates/design/${id}`)

      const getPercentage = await api.post("cheklist/count/card/design/by/id",{
        project_id:id

      })

      if(getPercentage.data.total == undefined){
        setTotal(0)
        return false
      }

      setTotal(getPercentage.data.total)
      setDueDay(response.data[0].dueDate)

    }

    const getLinkToRedirectWebPage = async () => {

      const response = await api.post(`info/project/`,{

        project_id:id,
        type:"figma",

      })
      

      window.open(`${response.data[0].figmaUrl}`, '_blank');

    }


    useEffect(() => {

      getInfoPrototype()

    },[])

    
    setTimeout(() => {
      
      getInfoPrototype()

    },[60000])

    return (
        <div style={{
            border: "1px solid #D7D7D7",
            borderRadius: "12px"
        }}>

            <div style={{
                borderBottom: "1px solid  #D7D7D7",
                padding: "13px 14px"
            }}>

                <div style={{
                    border: "1px solid #D7D7D7",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "14px 13px",
                    borderRadius: "15px"
                }}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "18px"
                    }}>
                        <img src={iconPrototype} />

                        <p className="textTitleCardEndProject">Protótipo</p>
                    </div>

                    <svg onClick={() => getLinkToRedirectWebPage()} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.2461 13.9298C6.14108 15.101 4.34152 15.209 3.10999 14.1781C2.37655 13.529 2.00191 12.5531 2.1078 11.5675C2.19951 10.8408 2.53181 10.1681 3.04944 9.66116L5.05104 7.61397C5.45857 7.19731 5.45857 6.5219 5.05104 6.10524C4.64338 5.68871 3.98256 5.68871 3.5749 6.10524L1.61578 8.10836C0.722746 8.98853 0.15957 10.1606 0.0240855 11.421C-0.228973 14.2598 1.81745 16.7708 4.59494 17.0294C6.08651 17.1683 7.56147 16.6233 8.6213 15.5417L10.7148 13.4027C11.1223 12.9861 11.1223 12.3107 10.7148 11.894C10.3071 11.4775 9.6463 11.4775 9.23863 11.894L7.2461 13.9298Z" fill="black" />
                        <path d="M14.7281 1.0433C12.6497 -0.517374 9.76371 -0.272699 7.96538 1.61665L5.9951 3.63113C5.58757 4.04779 5.58757 4.7232 5.9951 5.13986C6.40276 5.55639 7.06358 5.55639 7.47124 5.13986L9.47284 3.0962C10.5537 1.95362 12.3025 1.82491 13.5324 2.79744C14.7983 3.85837 14.983 5.76725 13.945 7.06108C13.8843 7.1368 13.82 7.20946 13.7523 7.27881L11.6588 9.4185C11.2513 9.83516 11.2513 10.5106 11.6588 10.9272C12.0665 11.3438 12.7273 11.3438 13.135 10.9272L15.2284 8.78754C17.1993 6.76673 17.1942 3.49556 15.2171 1.48117C15.0634 1.32466 14.9001 1.17841 14.7281 1.0433Z" fill="black" />
                        <path d="M5.99522 9.42067L9.2388 6.10549C9.64649 5.6888 10.3075 5.6888 10.7152 6.10549C11.1229 6.52218 11.1229 7.19776 10.7152 7.61445L7.47159 10.9296C7.0639 11.3463 6.40292 11.3463 5.99522 10.9296C5.58753 10.5129 5.58753 9.83736 5.99522 9.42067Z" fill="black" />
                    </svg>

                </div>

            </div>

            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "27px",
                padding: "22px 18px"
            }}>
                <div style={{ width: "48px", height: "48px" }}>

                    <CircularProgressbar
                        value={total}
                        text={`${Number(total).toFixed(0)}%`}
                        strokeWidth={"16"}
                        className="text_circular_progressbar_followUp"
                        styles={buildStyles({
                            rotation: 0.50,
                            strokeLinecap: 'round',
                            textSize: '25px',
                            pathTransitionDuration: 0.5,
                            pathColor: `var(--color-primary)`,
                            textColor: '#000000',
                            trailColor: '#00000010',
                            backgroundColor: '#3e98c7',
                        })}

                    />
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px"
                }}>
                    <p className="textDateTitleCardEndProject">Data de entrega</p>
                    <p className="textDateContentCardEndProject">{dueDay}</p>
                </div>
            </div>

        </div>
    )
}

export default CardPrototypeMobile