import React,{useEffect,useState} from "react"
import fileIcon from "../../../images/Svg/fileIcon.svg"
import trashIconFunctionalitis from "../../../images/Svg/trashIconFunctionalitis.svg"
import api from "../../../services/api"


import pdf from '../../../images/png/PDF.png'
import png from '../../../images/png/PNG.png'
import svg from '../../../images/png/SVG.png'
import jpeg from '../../../images/png/JPEG.png'


const FileOrImage = ({ name, size, onDelete,type}) => {

    const [kb,setKb] = useState()

    const kbInFile = async () => {
    
        const response = await api.post("file/size/file/",{
            file:size
        })
        setKb(response.data.kb)

    }

    useEffect(() => {

        kbInFile()

    },[])

    return (
        <a href={size}  download={size} style={{outline:"none",color:"white"}}>

        <div style={{
            border: "1px solid #D7D7D7",
            borderRadius: "15px",
            padding: "11px 15px 11px 10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        }}>

            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "11px"
            }}>

                <div style={{
                    border: "1px solid #D7D7D7",
                    padding: "7px 9px",
                    borderRadius: "15px",
                }}>

                    
                {type == "application/pdf" 
                
                ?
                <div style={{border:"1px solid #D7D7D7",width:"41.29px",height:"45px",display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"15px"}}>

                <img src={pdf} style={{width:"25px",height:"30px"}}/>
                
                </div>

                :

                type == "image/png"

                ?
                <div style={{border:"1px solid #D7D7D7",width:"41.29px",height:"45px",display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"15px"}}>

                <img src={png} style={{width:"25px",height:"30px"}}/>
                
                </div>
                :

                type == "image/svg+xml" 

                ?
                <div style={{border:"1px solid #D7D7D7",width:"41.29px",height:"45px",display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"15px"}}>

                <img src={svg} style={{width:"25px",height:"30px"}}/>
                
                </div>
                :

                type == "image/jpeg"

                ?
                <div style={{border:"1px solid #D7D7D7",width:"41.29px",height:"45px",display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"15px"}}>

                <img src={jpeg} style={{width:"25px",height:"30px"}}/>
                
                </div>

                :
                
                <></>
                    }

                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px"
                }}>
                    <p className="textTitlelFileOrImageName">{name}</p>
                    <p className="textTitlelFileOrImage">KB,{Number(kb).toFixed(2)}</p>
                </div>
            </div>

            <img onClick={onDelete} src={trashIconFunctionalitis} />

        </div>
        </a>
    )
}

export default FileOrImage