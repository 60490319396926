import { useContext, useEffect, useState } from "react"
import showSelectIcon from "../../../images/Svg/showSelect.svg"
import mobileIcon from "../../../images/Svg/mobile.svg"
import webIcon from "../../../images/Svg/web.svg"
import {ProjectsContext} from '../../../context/project'
import { useNavigate } from "react-router-dom"
import api from "../../../services/api"
const Select = ({id, value, setValue, list, name, type, unique, top,page,typeProject,onClick}) => {
    
    
    const [showSelect, setShowSelect] = useState(false)
    const parentElement = document.querySelector("#selectAdd")
    const [width, setWidth] = useState(parentElement?.offsetWidth - 41 || "100%")

    const urlParams = new URLSearchParams(window.location.search);
    const idProject = urlParams.get("id")
    

    const navigate = useNavigate()



    window.addEventListener("resize", function () {
        setWidth(parentElement?.offsetWidth - 41 || "100%")
    });

    const redirectToSelectProject = async (changeToId) => {
        navigate(`/follow/?id=${changeToId}`)
    }



    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #E5E5E5"
        }}>
            <div id="selectAdd" style={{ position: "relative", width: "100%" }} >
                {page == "payment" 
                
                ?            
                <></>
                :
                value && <p className="textLabelInput" style={{ marginBottom: "14px",position:"absolute",zIndex:3,left:"60px",top:"15px" }}>{value}</p>

                }
                
                <button className="textSelectProject" onClick={() => setShowSelect(!showSelect)} style={{
                    backgroundColor: "#fff",
                    color: "#000000",
                    border: "1px solid #D7D7D7",
                    width: "100%",
                    height: "45px",
                    borderRadius: type == "payment" ? "60px" : "10px",
                    position: "relative",
                    padding: "0 35px 0 10px",
                    overflow: "hidden",
                    display: "flex",
                    gap: "20px",
                    alignItems: "center"

                }}>
                    {page == "payment" ? value : <></>}
                    <img src={typeProject == "web" && webIcon ||typeProject == "mobile" && mobileIcon || typeProject == "web" && webIcon || typeProject == "mobile" && mobileIcon} />
                    <img style={{ transform: showSelect ? "rotate(0deg)" : "rotate(180deg)", transition: "0.3s", position: "absolute", right: "16px", top: "18px" }} src={showSelectIcon} />
                </button>
                {
                    showSelect && (
                        <ul style={{
                            position: "absolute",
                            top: top || "75px",
                            zIndex: "1",
                            backgroundColor: "#fff",
                            border: "1px solid #D7D7D7",
                            borderRadius: "0 0 10px 10px",
                            width: unique ? "100%" : width,
                            padding: "20px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                        }}>
                            {
                                type == "payment" ?
                                    list.map(item => {
                                        return (
                                            <li className="textSelectProject" onClick={() => {
                                                setShowSelect(false)
                                                setValue(item)
                                            }}>{item}</li>
                                        )
                                    })
                                    :
                                    list.map(item => {
                                        if(item.name != value){

                                            return (
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: item?.type_project == "web" ? "20px" : "27px",
                                                    borderBottom: "1px solid #d7d7d7d7",
                                                    paddingBottom: "13px"
                                                }}
                                                >
                                                    <img style={{ marginLeft: item?.type_project == "mobile" && "5px", width: item?.type_project == "web" && "29px" }} src={item?.type_project == "web" && webIcon || item?.type_project == "mobile" && mobileIcon} />
    
                                                    <li className="textSelectProject" onClick={() => {setShowSelect(false);redirectToSelectProject(item.project_id)}}>{item?.name}</li>
                                                </div>
                                            )

                                        }
                                    })
                            }
                        </ul>
                    )
                }
            </div>
        </div>
    )
}

export default Select