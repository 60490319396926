import { useContext, useState } from "react"
import { CartContext } from "../../../context/cart"
import trashIconFunctionalitis from "../../../images/Svg/trashIconFunctionalitis.svg"
import iconCustom from "../../../images/Svg/iconCustom.svg"
import iconCustomMobile from "../../../images/Svg/iconCustomMobile.svg"
import ModalContainer from "./ModalContainer"
import ModalAddedFeature from "./ModalAddedFeature"


const CardCustomFunctionaLities = ({ id, name, description }) => {
    const { removeCustomComponent } = useContext(CartContext)
    const [modal, setModal] = useState(false)

    const [nameCustom, setNameCustom] = useState(name)
    const [descriptionCustom, setDescriptionCustom] = useState(description)

    return (
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "12px 23px",
            borderBottom: "1px solid #E5E5E5",
        }}>

            <div style={{
                display: "flex",
                gap: "20px",
                alignItems: "center"
            }}>

                <img src={localStorage.getItem("@TYPE_PROJECT") == "web" ? iconCustom : iconCustomMobile} />


                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "14px"
                }}>
                    <p className="textSelectFeatSection" >{nameCustom}</p>

                    <div style={{
                        display: "flex",
                        gap: "5px"
                    }}>

                        <p onClick={() => setModal(true)} className="textSelectFeatSectionEdit">Editar</p>
                    </div>
                </div>
            </div>
            <img onClick={() =>removeCustomComponent(id)} src={trashIconFunctionalitis} />

            <ModalContainer isActive={modal} closeModal={() => setModal(false)}>
                <ModalAddedFeature
                    edit={true}
                    nameCustom={nameCustom}
                    descriptionCustom={descriptionCustom}
                    setNameCustom={setNameCustom} 
                    setDescriptionCustom={setDescriptionCustom}
                    setListProjects={[]}
                    closeModal={() => setModal(false)} />
            </ModalContainer>
        </div>
    )
}

export default CardCustomFunctionaLities