import React from 'react'
import Sidebar from "../../components/Sidebar";
import HeaderProfile from '../../components/HeaderProfile';

const ThreeColumProfile = ({ colum2Data, colum3Data, colum4Data, page, typeHeader }) => {


    const container_three_column = {
        'display': 'grid',
        'gridTemplateColumns': '0 1fr',
    }

    const column1 = {
        gridColumnStart: '1',
        gridColumnEnd: '2',
    }

    const column2 = {

        gridColumnStart: '2',
        gridColumnEnd: '2',
        paddingLeft: "0rem",
    }

    return (
        <div style={container_three_column}>
            <HeaderProfile type={typeHeader} />
            <div style={column1}>
                <Sidebar page={page} />
            </div>{/* End column1*/}

            <div style={column2}>
                {colum2Data}
            </div>{/* End column2 */}

        </div>
    )

}

export default ThreeColumProfile