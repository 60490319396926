import {useState,useEffect,useContext } from "react";
import { ComponentContext } from "../../../context/component";
import cartIcon from "../../../images/Svg/cartIcon.svg";
import Button from "./Button";
import { CartContext } from "../../../context/cart";
import api from "../../../services/api";
const FooterPrice = ({ onClick, onCart, priceTotal, totalDays,page,id }) => {
  const { itemsInCart, developTime, price,getPriceCart} = useContext(CartContext);
  
  const [totalCartUser,setTotalCartUser] = useState()

  async function getTotalValueCart(){

    const response = await api.post("cart/total",{
      mail:localStorage.getItem("@MAIL")
    })

    setTotalCartUser(response.data.value)

  }



  useEffect(() => {

    getTotalValueCart()

  },[])

  useEffect(() => {

    getPriceCart()

  },[])


  return (
    <div
      style={{
        position: "fixed",
        bottom: "0",
        right: "0",
        zIndex: "20",
        left: "0",
        backgroundColor: "#fff",
        padding: "18px 19px",
        borderTop: "1px solid #E5E5E5",
        boxShadow: "1px 30px 33px 17px #000",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "24px",
          alignItems: "center",
        }}
      >
        <div
          onClick={onCart}
          style={{
            height: "45px",
            width: "45px",
            border: "1px solid #D7D7D7",
            borderRadius: "8px",
            backgroundColor: "#F9F9FA",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "3px",
          }}
        >
          <p className="textPriceCard">{itemsInCart}</p>
          <img src={cartIcon} />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >

          {price == undefined
          
          ?
          <div class="ssc-line" style={{width:"80px",height:"21px",borderRadius:"2px"}}></div>

          :
          page == "general" 
          ?
          <p className="textFooterCard">
          {priceTotal
            ? Number(priceTotal).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            : priceTotal &&
              Number(priceTotal).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
        </p>
          :
          <>
          
          <p className="textFooterCard">
            {price
              ? Number(price).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              : price &&
                Number(price).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
          </p>

          </>
          }
          
          {developTime == undefined
          
          ?
          <div class="ssc-line" style={{width:"80px",height:"21px",borderRadius:"2px"}}></div>

          :
        
          <p className="textFooterCard">
            {totalDays
              ? totalDays == "1"
                ? `${totalDays} Dia`
                : `${totalDays} Dias`
              : totalDays == "1"
              ? `${developTime} Dia`
              : `${developTime} Dias`}
          </p>

          }

        </div>
      </div>

      <Button
        width="150px"
        height="40px"
        background="var(--color-primary)"
        borderRadius="60px"
        marginTop="0"
        name="Prosseguir"
        border="none"
        onClick={onClick}
      />
    </div>
  );
};

export default FooterPrice;
