import { useContext, useEffect, useState } from "react"
import { ComponentContext } from "../../../context/component"
import closeIcon from "../../../images/Svg/closeIcon.svg"

const ModalViewDetailsFeature = ({ closeModal }) => {
    const { imageBase64, name,description, developTime, price } = useContext(ComponentContext)
    const [typeProject, setTypeProject] = useState()
    const [an,setAn] = useState(true)

    useEffect(() => {
        setTypeProject(localStorage.getItem("@TYPE_PROJECT"))
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setAn(false)
        },[3000])

    },[])


    const imageWeb = {
        maxWidth: "100%",
        objectFit: "cover",
        borderRadius: "6px",
    }


    const containerImageWeb = {
        width: "100%",
        height:"220px",
        border: "1px solid var(--color-primary)",
        borderRadius: "6px",
        margin: "0 auto"

    }

    const containerImageMobile = {
        width: "178px",
        objectFit: "contain",
        border: "1px solid var(--color-primary)",
        borderRadius: "6px",
        margin: "0 auto"

    }

    const imageMobile = {
        width: "100%",
        height: "400px",
        objectFit: "cover",
        borderRadius: "6px",
        marginTop: "59px",
        margin: "0 auto"
    }


    return (
        <div style={{
            height: "100%",
            width: "100%",
            backgroundColor: "#fff"
        }}>

            <div style={{
                padding: "22px 23px 17px",
                borderBottom: "1px solid #E5E5E5",
                position: "relative",
                display: "flex",
                justifyContent: "center"
            }}>
                <img src={closeIcon} onClick={closeModal} style={{
                    position: "absolute",
                    left: "22px",
                    top: "24px"
                }} />

                <h3 className="textTitleModalViewDetailsFeature">Pré-visualização</h3>
            </div>
            

            <div style={{height:"100%",overflowY:"scroll"}}>
            {an == true && typeProject == "mobile"
            
            ?
            <div style={{display:"flex",width:"100%",height:"400px",justifyContent:"center",marginTop:"59px"}}>
            <div class="ssc-line" style={{width:"178px",height:"400px"}}></div>

            </div>
        
            :
            <></>
            }

            {an == true && typeProject == "web"
            
            ?
            <div style={{display:"flex",width:"100%",height:"220px",justifyContent:"center",paddingTop:"32px"}}>
            <div class="ssc-line" style={{width:"85%",height:"220px"}}></div>

            </div>
        
            :
            <></>
            }

            <div style={{
                padding: "50px 23px",
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
                gap: "40px",
                height: "100%",
            }}>

                {an == true 
                ?
                <></>
                :
                typeProject == "web" 
                
                ?
                <div style={containerImageWeb}>
                <img src={imageBase64} style={ typeProject == "web" ? imageWeb  : imageMobile} />

                </div>
                :
                <div style={containerImageMobile}>
                <img src={imageBase64} style={ typeProject == "web" ? imageWeb  : imageMobile} />

                </div>

                }                
                

                <div>
                    <h3 className="textTitleFeture">{name}</h3>
                    <p className="textContentFeture">{description}</p>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "50px 0 117px",
                    }}>


                        <div style={{
                            paddingRight: "23px",
                            borderRight: "1px solid #C1C1C1",
                            height:"50px"
                        }}>
                            <p className="textTitleDetailsFeatureModal">Tempo</p>
                            {an == true 
                            
                            ?
                            <div class="ssc-line" style={{width:"56px",height:"20px"}}></div>

                            :
                            <p className="textContentDetailsFeatureModal">{developTime == "1" ? `${developTime} dia` : `${developTime} dias`}</p>

                            }
                     
                        </div>

                        <div style={{
                            paddingLeft: "23px"
                        }}>
                            <p className="textTitleDetailsFeatureModal">Valor</p>
                            {an == true 
                            
                            ?
                            <div class="ssc-line" style={{width:"56px",height:"20px"}}></div>

                            :
                            <p className="textContentDetailsFeatureModal">{Number(price).toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            })}</p>       
                            }
                     
                        </div>
                        {an == false 
                        
                        ?
                        <div style={{height:"120px"}}></div>
                        :
                        <></>
                        }
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default ModalViewDetailsFeature