import closeIcon from "../../../images/Svg/closeIcon.svg"
import FooterPrice from "./FooterPrice"

const ModalNewDetailsFeature = ({ closeModal, picture, description, title, time, price }) => {

    return (
        <div style={{
            height: "100%",
            width: "100%",
            backgroundColor: "#fff"
        }}>

            <div style={{
                padding: "22px 23px 17px",
                borderBottom: "1px solid #E5E5E5",
                display: "flex",
                alignContent: "center",
                gap: "17px"
            }}>
                <img src={closeIcon} onClick={closeModal} />

                <h3 className="textTitleModalViewDetailsFeature">Funcionalidades</h3>
            </div>

            <div style={{
                overflowY: "auto",
                height: "78%"
            }}>
              
            </div>
            <FooterPrice />
            
        </div>
    )
}

export default ModalNewDetailsFeature