import { useState } from "react"
import logoPlatform from "../../../images/Svg/logoPlatform.svg"
import Input from "../components/Input"
import Button from "../components/Button"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import api from "../../../services/api"

const ForgotPasswordMobile = () => {
    const [btnState, setBtnState] = useState(false)
    const [mail,setMail] = useState()

    const navigate = useNavigate();

    const redirectToSendMailPage = async () => {

        const response = await api.post("recover/mail",{
            mail:mail
        })
        if(response.status == 200){

            localStorage.setItem("@RECOVER_MAIL",mail)
            window.location.href="/send/mail"
        }else{
            toast.error("Email não encontrado!")
        }

    }


    const container = {
        padding: "0 39px 10vh",
        backgroundColor: "#E5E5E520"
    }

    const containerLogo = {
        marginBottom: "69px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        paddingTop: "87px"
    }

    const boxInputs = {
        display: "flex",
        flexDirection: "column",
        marginTop: "28px",
        gap: "20px"
    }

    const boxFooter = {
        marginTop: "190px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "31px"
    }

    return (
        <div style={container}>
            <div style={containerLogo}>
                <img src={logoPlatform} />
            </div>

            <h3 className="textTitleFormSend">Esqueceu a senha ?</h3>

            <div style={{ marginTop: "25px" }}>
                <p className="textTitleFormDescription">Não se preocupe! Digite seu e-mail para receber
                    instruções de recuperação de senha
                </p>
            </div>

            <div style={boxInputs}>
                <Input name="E-mail" type={"email"} onChange={(e) => setMail(e.target.value)} placeholder="Seu e-mail de cadastro" />
            </div>

            <div style={boxFooter}>
                <Button width="100%" height="45px" background="var(--color-primary)"
                    borderRadius="60px" marginTop="0" name="Recuperar senha"
                    border="none" disabled={btnState} onClick={() =>redirectToSendMailPage()}/>

                <p className="textRememberedThePassword" onClick={() => navigate("/")}>Lembrou da senha?</p>
            </div>
        </div>
    )
}

export default ForgotPasswordMobile