import { useEffect, useState } from "react"
import showSelectIcon from "../../../images/Svg/showSelect.svg"
import mobileIcon from "../../../images/Svg/mobile.svg"
import webIcon from "../../../images/Svg/web.svg"
import api from "../../../services/api"
const SelectModalCartFunctionalities = ({ value, setValue, list, name, type, unique, top,typeProject}) => {
    const [showSelect, setShowSelect] = useState(false)
    const [projects,setProjects] = useState()
    const parentElement = document.querySelector("#selectAdd")

    const [width, setWidth] = useState(parentElement?.offsetWidth - 41 || "100%")

    window.addEventListener("resize", function () {
        setWidth(parentElement?.offsetWidth - 41 || "100%")
    });

    
  const getProjects = async () => {

    const response = await api.get(`project/all/${localStorage.getItem("@MAIL")}`)

    setProjects(response.data)

  }

  const redirecToSelectProject = (id) => {

    window.location.href=`/functionalities/?id=${id}`

  }

  useEffect(() => {

    getProjects()

  },[])
  

  
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #E5E5E5"
        }}>
            <div id="selectAdd" style={{ position: "relative", width: "100%" }} >

                {name && <p className="textLabelInput" style={{ marginBottom: "14px" }}>{name}</p>}
                <button className="textSelectProject" onClick={() => setShowSelect(!showSelect)} style={{
                    backgroundColor: "#fff",
                    color: "#000000",
                    border: "1px solid #D7D7D7",
                    width: "100%",
                    height: "45px",
                    borderRadius: type == "payment" ? "60px" : "10px",
                    position: "relative",
                    padding: "0 35px 0 10px",
                    overflow: "hidden",
                    display: "flex",
                    gap: "20px",
                    alignItems: "center"

                }}>

                    <img src={value?.type == "web" && webIcon || value?.type == "mobile" && mobileIcon || typeProject == "web" && webIcon || typeProject == "mobile" && mobileIcon} />
                    {value?.name || value}
                    <img style={{ transform: showSelect ? "rotate(0deg)" : "rotate(180deg)", transition: "0.3s", position: "absolute", right: "16px", top: "18px" }} src={showSelectIcon} />
                </button>
                {
                    showSelect && (
                        <ul style={{
                            position: "absolute",
                            top: top || "75px",
                            zIndex: "1",
                            backgroundColor: "#fff",
                            border: "1px solid #D7D7D7",
                            borderRadius: "0 0 10px 10px",
                            width: unique ? "100%" : width,
                            padding: "20px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                        }}>
                        {projects.map(item => {

                            if(item.name != value){
                                return (

                                    <div style={{display:"flex",alignItems:"center"}} onClick={() => redirecToSelectProject(item.project_id) }>
                                    <img src={item?.type == "web" && webIcon || item?.type == "mobile" && mobileIcon || item.type_project == "web" && webIcon || item.type_project == "mobile" && mobileIcon} />
                                    <p className="textLabelInput" style={{marginLeft:"10px"}}>{item.name}</p>
                                    </div>


                                )
                            }



                        })}
                        </ul>
                    )
                }
            </div>
        </div>
    )
}

export default SelectModalCartFunctionalities