import React from 'react'
import InputMask from 'react-input-mask';

const InputPayment = ({name,mask,type,onChange,onBlur,value,error,messageError}) => {

    
    const input = {

        width:"291px",
        height:"45px",
        borderRadius:"7px",
        border:"1px solid #D7D7D7",
        outline:"none",
        paddingLeft:"0.813rem",
        marginRight:"20px"
  
      }
  

    return(

        mask == true 
        
        ?
        <div style={{display:"flex",flexDirection:"column",marginBottom:"1.688rem",position:"relative"}}>
        <label>{name}</label>
        <InputMask mask={type} maskChar={false} onBlur={onBlur}  value={value} onChange={onChange} style={input}/>
        
        {error != undefined 
        
        ?
        <p style={{position:"absolute",color:"red",top:"70px"}}>{messageError}</p>
        :
        <></>
        }
        </div>
        :

        <div style={{display:"flex",flexDirection:"column",marginBottom:"1.688rem",position:"relative"}}>
        <label>{name}</label>
        <input onChange={onChange} value={value} onBlur={onBlur} style={input}/>
        {error != undefined 
        
        ?
        <p style={{position:"absolute",color:"red",top:"70px"}}>{messageError}</p>
        :
        <></>
        }
        </div>
    )

}
export default InputPayment