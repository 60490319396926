import React from 'react'

const ButtonLogin = ({onClick,disabled,register,margin}) =>{

    const button = {

        background:"#1172EB",
        width:"185px",
        height:"48px",
        border:"none",
        outline:"none",
        borderRadius:"60px",
        color:"white",
        margin: margin
    }
    
    return(

        register == true 
        
        ?

        <button style={button} onClick={onClick} disabled={disabled} className="textButtonLogin">
            Cadastre-se
        </button>
        :

        <button style={button} onClick={onClick} disabled={disabled} className="textButtonLogin">
            Login
        </button>

    )

}

export default ButtonLogin