import { useState } from "react"
import Input from "./Input"


const FormProfileUser = ({
    nameData,
    surNameData,
    mailData,
    passwordData,
    pictureData,
    setNameData,
    setSurNameData,
    setPasswordData,
    setPictureData,
    setMailData,
    setIdData,
    updateUser
}) => {


    return (
        <div style={{
            padding: "0 25px"
        }}>
            <div style={{
                border: "1px solid #D7D7D7",
                borderRadius: "15px",
                padding: "16px 26px",
            }}>
                <h3 className="textTitleFormProfileInfo">Dados de acesso</h3>

                <Input onBlur={(e) => updateUser({name: e.target.value})} value={nameData} margin={"20px 0"} onChange={(e) => setNameData(e.target.value)} name={"Nome"} placeholder={"Insira um nome"} />

                <Input onBlur={(e) => updateUser({surname: e.target.value})} value={surNameData} margin={"20px 0"} onChange={(e) => setSurNameData(e.target.value)} name={"Sobrenome"} placeholder={"Insira seu sobrenome"} />

                <Input onBlur={(e) => updateUser({mail: e.target.value})} value={mailData} margin={"20px 0"} onChange={(e) => setMailData(e.target.value)} name={"E-mail de acesso"} placeholder={"Insira seu email"} />

                <Input onBlur={(e) => updateUser({password: e.target.value})}  margin={"20px 0"} onChange={(e) => setPasswordData(e.target.value)} name={"Senha de acesso"} placeholder={"Insira uma nova senha"} type={"password"} />
            </div>
        </div>
    )
}

export default FormProfileUser