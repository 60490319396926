import React,{useState,useEffect} from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import api from '../../services/api'

const ModalCreateCustomComponent = ({show,onClick}) =>{
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id")

    const [name,setName] = useState()
    const [description,setDescription] = useState()

    const [nameError,setNameError] = useState()
    const [descriptionError,setDescriptionError] = useState()
    const getLastId = async () => {

      const response = await api.post("project/last/",{
          mail:localStorage.getItem("@MAIL")
      })


      window.location.href=`/functionalities/?id=${response.data.id}`
}


    const storage = async () => {

        if(name == undefined){


            setNameError("Informe um nome")

        }

        
        if(description == undefined){


            setDescriptionError("Informe uma descrição")

        }

        if(name == undefined || description == undefined){

            return false

        }

        const response = await api.post("custom/component/",{

            "name":name,
            "description":description,
            "project_id":id,
            "mail":localStorage.getItem("@MAIL")

          })

          if(response.status == 200){


            getLastId()

          }

    }

   

    return(

        show == false 
        
        ?
        <></>
        :

        <Modal show={show}  style={{borderRadius:"20px"}}   centered={true} animation={true}>
        
        <Modal.Header style={{border:"none",padding:0}}>
        
        <div style={{display:"flex",paddingBottom:"10px",paddingTop:"10px",paddingLeft:"30px",alignItems:"center",width:"100%",borderBottom:"1px solid #D7D7D7"}}>
        
        <div onClick={onClick} >
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.49773 6.36766L12.5007 1.35916C12.8113 1.04823 12.8113 0.544109 12.5007 0.2332C12.1902 -0.0777335 11.6867 -0.0777335 11.3761 0.2332L6.37365 5.24222L1.37119 0.2332C1.06064 -0.0777335 0.557125 -0.0777335 0.246591 0.2332C-0.0639429 0.544134 -0.0639678 1.04825 0.246591 1.35916L5.24956 6.36766L0.246591 11.3762C-0.0639678 11.6871 -0.0639678 12.1912 0.246591 12.5021C0.557149 12.8131 1.06066 12.8131 1.37119 12.5021L6.37365 7.4931L11.3761 12.5021C11.6867 12.8131 12.1902 12.8131 12.5007 12.5021C12.8113 12.1912 12.8113 11.6871 12.5007 11.3762L7.49773 6.36766Z" fill="black"/>
        </svg>
        </div>

        <p className="textHeaderModalCreateProject">Funcionalidade nova</p>

        </div> 
        
        </Modal.Header>
        
        <Modal.Body style={{width:"100%",paddingLeft:"52px",paddingRight:"30px"}}>

        
        <p className="textPrimaryModalCreateProject">Obervação</p>
        <p className="textSecondaryModalCreateProject">O preço e o prazo de entrega das funcionalidades novas e calculado manualmente e cobrado a parte.</p>

        <div style={{display:"flex",flexDirection:"column",width:"390px",position:"relative"}}>
          <label className="labelModalCreateProject">Título da funcionalidade</label>
          <input className="textInputModalCreateProject" maxLength={60} onChange={(e) => setName(e.target.value)} style={{outline:"none",height:"45px",border:"1px solid #D7D7D7",borderRadius:"7px",paddingLeft:"12px",marginTop:"14px"}} type={"text"} placeholder="Insira um nome"/>
          {nameError != undefined ?<p className="error_message_modal_create_custom">informe um título</p> :<></>}
        </div>

        <div style={{display:"flex",flexDirection:"column",width:"390px",marginTop:"33px",position:"relative"}}>
          <label className="labelModalCreateProject">Descrição da funcionalidade</label>
          <textarea className="textInputModalCreateProject"  maxLength={600} onChange={(e) => setDescription(e.target.value)} style={{outline:"none",height:"240px",border:"1px solid #D7D7D7",borderRadius:"7px",paddingLeft:"12px",marginTop:"14px",paddingTop:"12px",resize:"none"}} type={"text"} placeholder="Insira uma descrição completa"/>
          {descriptionError != undefined ?<p className="error_message_modal_create_custom">informe uma descrição</p> :<></>}

        </div>

        <button className="textBtnModalCreateProject" onClick={() => storage()}  style={{border:"none",color:"white",background:"#1172EB",width:"390px",height:"40px",borderRadius:"60px",marginTop:"2.5rem",marginBottom:"1rem"}}>Salvar</button>

        </Modal.Body>
       
      </Modal>
    )

}

export default ModalCreateCustomComponent