import React, { useEffect, useState, useContext } from 'react'
import "skeleton-screen-css";

import api from '../../services/api'
import customComponent from '../../images/Svg/customComponent.svg'
import customComponentWeb from '../../images/Svg/customComponentWeb.svg'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
const PreviewFunctionalitieCheckList = ({ id, checked, globalId, checkFollowUp, custom, project_id }) => {


  const [arrowTop, setArrowTop] = useState(false)
  const [image, setImage] = useState()
  const [price, setPrice] = useState()

  const [daysToDevelop, setDaysToDevelop] = useState()
  const [nameComponent, setNameComponent] = useState()
  const [check, setCheck] = useState(false)


  const [nameCustom, setNameCustom] = useState()
  const [descriptionCustom, setDescriptionCustom] = useState()
  const [typeProject, setTypeProject] = useState()

  const functionalitie = {

    width: "100%",
    height: arrowTop != false ? "96px" : "66px",
    paddingBottom: "2.25rem",
    height: "90px",
    borderBottom: "1px solid #D7D7D7"


  }

  const urlParams = new URLSearchParams(window.location.search);
  const idProject = urlParams.get("id")


  const getInfo = async () => {

    const typeProject = await api.post("project/project/info/pay", {
      mail: localStorage.getItem("@MAIL"),
      project_id: idProject
    });

    const response = await api.post(`components/simple/sidebar/`, {

      "type": typeProject.data[0].type_project,
      "id": id

    })

    setTypeProject(typeProject.data[0].type_project)

    if (typeProject.data[0].type_project == "web") {

      const imageDesktop = (response.data[0][0].desktop_icon)
      setImage(imageDesktop)


    } else {


      const imageMobile = response.data[0][0].mobile_icon
      setImage(imageMobile)

    }


    setNameComponent(response.data[0][0].name)
    setPrice(response.data[0][0].price)
    setDaysToDevelop(response.data[0][0].develop_time)
  }

  const getInfoCustom = async () => {
    const response = await api.get(`custom/project/component/${idProject}`)
    setNameCustom(response.data[0].name)
    setDescriptionCustom(response.data[0].description)

  }


  useEffect(() => {

    getInfo()
    getInfoCustom()

  }, [id])

  useEffect(() => {


    if (checked != 0 && checked != undefined) {
      setCheck(true)


    }

  }, [checked])


  setInterval(() => {


    if (checked != 0 && checked != undefined) {
      setCheck(true)


    }
  }, [1000])

  return (

    <>
      <ReactTooltip style={{ width: "220px", position: "absolute" }} anchorId={id} />
      <button id={id} data-tooltip-content={`${custom == "true" ? descriptionCustom : ""}`} className='btn' style={functionalitie} onClick={() => {/*(!check); updateState(id,globalId)} */ }} type="button">

        {nameComponent != undefined

          ?

          <div style={{ display: "flex", alignItems: 'center', width: "290px", justifyContent: "space-between" }}>

            <div>

              {typeProject == "web"
                ?

                custom == "false" ? <img style={{ objectFit: 'cover', width: '84px', borderRadius: '5px', borderRadius: '2px', border: "1px solid #1172EB", }} src={image} /> : <img src={customComponentWeb} />

                :

                custom == "false" ? <img style={{ objectFit: 'contain', width: '35px', height: "76px", borderRadius: '2px', border: "1px solid #1172EB" }} src={image} /> : <img src={customComponent} />


              }
            </div>
            <div style={{ height: '4.5rem', display: "flex", width: "400px", alignItems: "center", justifyContent: "space-between" }}>

              <div style={{ marginLeft: "1.25rem", width: "210px" }}>
                <p className="textFunctionlitieCartPrimary">{custom == "false" ? nameComponent : nameCustom}</p>
                <p className="textFunctionlitieCartSecondary">{check == true && checkFollowUp != 0 ? "Implementada" : "Em desenvolvimento"}</p>
              </div>



            </div>


            {checkFollowUp != undefined && checkFollowUp != 0

              ?
              <div style={{ position: "absolute", right: "20px" }}>
                <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <ellipse cx="10.49" cy="10.5752" rx="10.49" ry="10.5752" fill="#1172EB" />
                  <path d="M15.1529 7.05005L8.74238 13.5126L5.82849 10.5751" stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>

              :
              <></>
            }




          </div>


          :

          localStorage.getItem("@TYPE_PROJECT") == "mobile"

            ?

            <div style={{ display: "flex", alignItems: "center" }}>
              <div class="ssc-square" style={{ width: "35px", height: "76.4px", borderRadius: "2px" }}></div>

              <div style={{ marginLeft: "1.25rem" }}>
                <div class="ssc-line" style={{ width: "64px", height: "11.38px", borderRadius: "16.25px" }}></div>

                <div style={{ display: "flex", marginTop: "1.351rem" }}>

                  <div class="ssc-line" style={{ width: "64px", height: "11.38px", borderRadius: "16.25px" }}></div>
                  <div class="ssc-line" style={{ width: "64px", height: "11.38px", borderRadius: "16.25px", marginLeft: "0.313rem" }}></div>

                </div>
              </div>

            </div>

            :
            <div style={{ display: "flex", alignItems: "center", marginTop: "0.9rem" }}>
              <div class="ssc-square" style={{ width: "84px", height: "49.63px", borderRadius: "2px" }}></div>

              <div style={{ marginLeft: "1.25rem" }}>
                <div class="ssc-line" style={{ width: "64px", height: "11.38px", borderRadius: "16.25px" }}></div>

                <div style={{ display: "flex", marginTop: "1.351rem" }}>

                  <div class="ssc-line" style={{ width: "64px", height: "11.38px", borderRadius: "16.25px" }}></div>
                  <div class="ssc-line" style={{ width: "64px", height: "11.38px", borderRadius: "16.25px", marginLeft: "0.313rem" }}></div>

                </div>
              </div>

            </div>

        }


      </button>

    </>
  )

}

export default PreviewFunctionalitieCheckList