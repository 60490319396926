import { useNavigate } from "react-router-dom";
import logoPlatform from "../../../images/Svg/logoPlatform.svg";
import { useEffect, useState } from "react";
import BigHeads from "../../../components/BigHeads";

const HeaderProfile = () => {
  const navigate = useNavigate();
  const [picture, setPicture] = useState();

  const getData = async () => {
    const localEvent = localStorage.getItem("@USER_PICTURE")
    if(localEvent != "null"){
      setPicture(localEvent)
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const boxHeader = {
    display: "flex",
    justifyContent: "space-between",
    padding: "18px 22px 18px",
    borderBottom: "1px solid #E5E5E5",
    position: "fixed",
    zIndex: 1,
    backgroundColor: "#fff",
    left: 0,
    width: "100vw",
    top: 0,
  };

  return (
    <>
      <div style={boxHeader}>
        <img src={logoPlatform} style={{width:"80px"}} onClick={() => navigate("/dashboard")} />
        <div onClick={() => navigate("/profile")}>
          {picture != null ? 
            <img
              src={`${picture}`}
              style={{
                width: "35px",
                height: "35px",
                borderRadius: "60px",
                objectFit: "cover",
              }}
            />
           : 
            <BigHeads width={"35px"} />
          }
        </div>
      </div>

      <div style={{ height: "60px" }}></div>
    </>
  );
};

export default HeaderProfile;
