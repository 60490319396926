import { useEffect, useState } from "react"
import Input from "../components/Input"
import logoPlatform from "../../../images/Svg/logoPlatform.svg"
import FooterTextNavigate from "../components/FooterTextNavigate"
import TermsFooter from "../components/TermsFooter"
import Button from "../components/Button"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import api from "../../../services/api"

const RegisterMobile = () => {
    const [checkTerms, setCheckTerms] = useState(false)
    const [name,setName] = useState()

    const [lastName,setLastName] = useState()
    const [mail,setMail] = useState()
    const [password,setPassword] = useState()

    const [nameError,setNameError] = useState()
    const [lastNameError,setLastNameError] = useState()
    const [mailError,setMailError] = useState()

    const [passwordError,setPasswordError] = useState()
    const [btnDisable,setBtnDisble] = useState(false)


    const navigate = useNavigate();

    const register = async () => {

        if(name == undefined){
            
            setNameError("Informe um nome")
        }

        if(lastName == undefined){
            
            setLastNameError("Informe um sobrenome")
        }

        if(mail == undefined){
            
            setMailError("Informe um email")
        }


        if(password == undefined){
            
            setPasswordError("Informe uma senha")
        }

        if(name == undefined || lastName == undefined || mail == undefined || password == undefined){
            return false
        }else if(name != undefined && lastName != undefined && mail != undefined && password != undefined && !checkTerms){
            return toast.error('Aceite os termos de uso', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        }

        const response = await api.post("client/user",{

            name:`${name}`,
            mail:mail,
            surname:lastName,
            password_hash:password,

        })
        

        localStorage.setItem("@PROJECT_CHANGE_ID2",response.data.projectId)
        localStorage.setItem("@PROJECT_CHANGE_ID",response.data.projectId)

        if(response.status == 200){
            localStorage.setItem("@TOKEN",response.data.token)
            localStorage.setItem("@MAIL",mail)
            navigate(`/functionalities/?id=${response.data.projectId}`)

        }
        

        if(response.status == 400){

            toast.error('Ops.. Usuário já existente!', {
                position: "top-right",
                autoClose: 1300,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

    }
    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    useEffect(() =>{

        if(name != undefined){
            
            setNameError()
        }

        if(lastName != undefined){

            setLastNameError()
        }

        if(mail != undefined){

            setMailError()
        }



        if(mail !== undefined){

            const responseValidateMail = validateEmail(mail)

            if(responseValidateMail == false){
    
                setMailError('email inválido!')
                setBtnDisble(true)
            }else{



                setBtnDisble(false)
            }
    
        }
            
   
        if(password != undefined){

            setPasswordError()
        }


        if(String(password).length > 0){

            if(String(password).length < 8){

                setPasswordError("senha deve ter pelo menos 8 caracteres")
                setBtnDisble(true)

            }else{

                setBtnDisble(false)


            }

        }

    },[name,lastName,mail,password])

    const container = {
        padding: "39px 27px",
        backgroundColor: "#E5E5E520"
    }

    const containerImage = {
        marginBottom: "27px"
    }

    const boxInputs = {
        display: "flex",
        flexDirection: "column",
        marginTop: "28px",
        gap: "30px"
    }

    return (
        <div style={container}>
            <div style={containerImage}>
                <img src={logoPlatform} />
            </div>
            <h3 className="textTitleForm">Criar conta</h3>
            <div style={boxInputs}>
                <Input value={name} error={nameError} onChange={(e) => setName(e.target.value)} name="Nome" type={"text"} />
                <Input value={lastName} error={lastNameError} onChange={(e) => setLastName(e.target.value)} name="Sobrenome" type={"text"} />
                <Input value={mail} error={mailError} onChange={(e) => setMail(e.target.value)} name="E-mail" type={"email"} />
                <Input value={password} error={passwordError} onChange={(e) => setPassword(e.target.value)} name="Senha" type={"password"} />
            </div>

            <TermsFooter href="https://www.startpn.com/empresa/politica-de-privacidade-e-protecao-de-dados" checkTerms={checkTerms} setCheckTerms={setCheckTerms} />

            <div style={{ marginTop: "48px" }}>
                <Button width="100%" height="45px" background="var(--color-primary)"
                    borderRadius="60px" marginTop="0" name="Criar conta"
                    border="none" disabled={btnDisable} onClick={()=>register()} />
            </div>

            <div style={{ marginTop: "39px", width: "100%"}}>
                <FooterTextNavigate question={"Já é cadastrado?"} response={"Login"} onClick={() => navigate("/")} />
            </div>
        </div>
    )
}

export default RegisterMobile