import { useState } from "react"
import logoPlatform from "../../../images/Svg/logoPlatform.svg"
import sendEmailIcon from "../../../images/Svg/sendEmailIcon.svg"
import Button from "../components/Button"
import { useNavigate } from "react-router-dom"

const SendMailMobile = () => {
    const [nameUser, setNameUser] = useState("mateus@startpn.com")
    const [btnState, setBtnState] = useState(false)

    const navigate = useNavigate();

    const container = {
        padding: "0 39px 27px",
        backgroundColor: "#E5E5E520"
    }

    const containerLogo = {
        marginBottom: "69px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        paddingTop: "87px"
    }

    const boxFooter = {
        marginTop: "190px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "31px"
    }

    return (
        <div style={container}>
            <div style={containerLogo}>
                <img src={logoPlatform} />
            </div>

            <h3 className="textTitleFormSend">Esqueceu a senha ?</h3>

            <div style={{ marginTop: "25px" }}>
                <p className="textTitleFormDescription">Um link de recuperação de
                    senha foi enviado para o e-mail {" "}
                    <span className="bold">{localStorage.getItem("@RECOVER_MAIL")}</span>
                </p>
            </div>

            <div style={{ marginTop: "47px", display: "flex", justifyContent: "center" }}>
                <img src={sendEmailIcon} />
            </div>

            <div style={boxFooter}>
                <Button width="100%" height="45px" background="var(--color-primary)"
                    borderRadius="60px" marginTop="0" name="Entendi"
                    border="none" disabled={btnState} onClick={() => navigate("/login")} />

                <Button width="100%" height="45px" background="#fff" color="var(--color-primary)"
                    borderRadius="60px" marginTop="0" name="Reenviar e-mail"
                    border="1px solid var(--color-primary)" disabled={btnState} onClick={() => navigate("/reset/password")} />
            </div>

        </div>
    )
}

export default SendMailMobile