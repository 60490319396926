import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
export const ModalTermsMobile = ({
  status,
  name,
  request_signature_key,
  onClick,
  address,
  cpf,
  document_key,
  number,
  monthToDevelop,
  dueDate,
  cep,
  instalment,
  district,
  state,
  city,
  complement,
  mail,
  statusModal
}) => {
  const ref = useRef(false)

  function Clicksign(o) {
    "use strict";
    function n(n) {
      
      var t;
      (e[(t = n).name || t] || []).forEach(function (t) {
        t(n.data)
      })
    }
    var r, u, t = window.location.protocol + "//" + window.location.host, e = {}, a = function (t) {
      n(t.data)
    };

    return {
      endpoint: "https://app.clicksign.com",
      origin: t,
      mount: function (t) {

        var n = "/sign/" + o
          , e = "?embedded=true&origin=" + this.origin
          , i = this.endpoint + n + e;
        return  u = document.getElementById(t),
          (r = document.createElement("iframe")).setAttribute("src", i),
          r.setAttribute("style", "width: 100%; height: 100%;"),
          r.setAttribute("allow", "camera;geolocation"),
          window.addEventListener("message", a),
          u.appendChild(r)

      },
      unmount: function () {
        return r && (u.removeChild(r),
          r = u = null,
          window.removeEventListener("message", a)),
          !0
      },
      on: function (t, n) {
        return e[t] || (e[t] = []),
          e[t].push(n)
      },
      trigger: n
    }
  }

  const getDocument = async (docKey) => {

    const response = await api.get(`terms/verify/${docKey}`)
    if(response.status == 200){ 

      setTimeout(() => {
        
        window.location.href=`/follow/?id=${response.data.project_id_follow}`
      },[2000])

    }

  }
  

  useEffect(() => { 

      if (!ref.current && request_signature_key != undefined) {
        var widget = new Clicksign(request_signature_key);
        widget.endpoint = 'https://sandbox.clicksign.com/';
        widget.origin = 'http://localhost:3000/';
        widget.mount('container_contract');

        widget.on('signed', function(ev) { 
          
          getDocument(document_key)
            
           
        });
        
        
        ref.current = true
      }
      


  }, [ref.current,request_signature_key]);



  return (
    <>

      <Modal centered size="lg" contentClassName="test" style={{overflowY:"scroll"}} show={status}>
        <Modal.Header style={{ border: "none", padding: 0 }}>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "10px",
              paddingTop: "10px",
              paddingLeft: "4px",
              paddingRight: "20px",
              alignItems: "center",
              width: "100%",
              borderBottom: "1px solid #D7D7D7",
            }}
          >
            <div
              onClick={onClick}
              style={{
                marginLeft: "15px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.49773 6.36766L12.5007 1.35916C12.8113 1.04823 12.8113 0.544109 12.5007 0.2332C12.1902 -0.0777335 11.6867 -0.0777335 11.3761 0.2332L6.37365 5.24222L1.37119 0.2332C1.06064 -0.0777335 0.557125 -0.0777335 0.246591 0.2332C-0.0639429 0.544134 -0.0639678 1.04825 0.246591 1.35916L5.24956 6.36766L0.246591 11.3762C-0.0639678 11.6871 -0.0639678 12.1912 0.246591 12.5021C0.557149 12.8131 1.06066 12.8131 1.37119 12.5021L6.37365 7.4931L11.3761 12.5021C11.6867 12.8131 12.1902 12.8131 12.5007 12.5021C12.8113 12.1912 12.8113 11.6871 12.5007 11.3762L7.49773 6.36766Z"
                  fill="black"
                />
              </svg>

              <p className="textHeaderModalTerms">Assinatura de contrato</p>
            </div>


          </div>
        </Modal.Header>

        <div id="main_contract_mobile" style={{height:"100vh"}}>
          
          <div id="container_contract" style={{height:"100vh"}}>

          </div>
          </div>

      </Modal>

    </>
  );
};
