import searchIcon from "../../../images/Svg/searchIcon.svg"

const InputSearch = ({onChange}) => {

    const container = {
            display: "flex",
            alignItems: "center",
            gap: "10px",
            border: "1px solid #E8E8E8",
            padding: "10px 15px",
            width: "85%",
            borderRadius: "60px",
            backgroundColor: "#fff"
        }

    return (
        <div style={container}>
            <img src={searchIcon} />

            <input className="textInputSearchFunctionaLities" onChange={onChange} placeholder="Pesquisar" style={{width: "100%", outline: "none", border: "none"}}/>
        </div>
    )
}

export default InputSearch