import closeIcon from "../../../images/Svg/closeIcon.svg"
import Input from "./Input"
import { useState } from "react"
import Button from "./Button"
import Textarea from "./Textarea"
import { toast } from "react-toastify"
import api from "../../../services/api"

const ModalAddedFeature = ({ closeModal, setListProjects, edit, nameCustom, descriptionCustom, setNameCustom, setDescriptionCustom }) => {

    const [name, setName] = useState(nameCustom)
    const [description, setDescription] = useState(descriptionCustom)
            
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id")

    const storage = async () => {
        if (name == undefined || description == undefined) {

            return toast.error("Campo nome e descrição são obrigatórios", {
                position: "top-right",
                autoClose: 1200,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })

        }

        const response = await api.post("custom/component/", {
            "name": name,
            "description": description,
            "project_id": id,
            "mail": localStorage.getItem("@MAIL")
        })

        if (response.status == 200) {

            window.location.href = "/functionalities"
        }

    }

    const onEditFeture = async () => { 
        closeModal()
    }

    const container = {
        backgroundColor: "#fff",
        width: "100%",
        height: "70%",
        border: "1px solid transparent",
        borderRadius: "20px 20px 0"
    }

    const boxHeader = {
        display: "flex",
        alignItems: "center",
        gap: "21px",
        padding: "22px 25px 17px",
        borderBottom: "1px solid #D7D7D7"
    }

    return (
        <div className="modalCreate" style={container}>
            <div style={boxHeader}>
                <img src={closeIcon} onClick={closeModal} />
                <h3 className="textTitleCreateModal">{edit ? "Editar Funcionalidade" : "Funcionalidade nova"}</h3>
            </div>

            <div style={{ padding: "26px 24px", height: "75%", overflowY: "auto" }}>
                <h3 className="textTitleCreateSelectModal">
                    Obervação
                </h3>

                <p className="textDescriptionModalFeature">O preço e o prazo de entrega e calculado manualmente e cobrado a parte.</p>

                <div style={{
                    marginTop: "19px"
                }}>
                    <Input value={name} margin={"0 0 20px"} onChange={(e) => setName(e.target.value)} name={"Título da funcionalidade"} placeholder={"Insira um nome"} />

                    <Textarea value={description} height="110px" margin={"0 0 28px"} onChange={(e) => setDescription(e.target.value)} name={"Descrição da funcionalidade"} placeholder={"Insira uma descrição completa"} />

                    <Button width="100%" height="40px" background="var(--color-primary)"
                        borderRadius="60px" marginTop="0" name="Salvar"
                        border="none" onClick={edit ? onEditFeture : storage} />
                </div>
            </div>

        </div>
    )
}

export default ModalAddedFeature