import { useContext, useEffect, useState } from "react"
import arrowDown from "../../../images/Svg/arrowDown.svg"
import pictureFeat from "../../../images/Svg/pictureFeat.svg"
import ContentSelectionSection from "./ContentSelectionSection"
import { CartContext } from "../../../context/cart"
import api from "../../../services/api"
var lz = require('lz-string');

const FeatureSelectionSection = ({ picture, name }) => {
    const [showSelect, setShowSelect] = useState(false)
    const [imageDecompress, setImageDecompress] = useState()
    const { selectItems, getItemsInCart } = useContext(CartContext)
    const [items, setItems] = useState([])


    useEffect(() => {

        const image = lz.decompressFromUTF16(picture)

        setImageDecompress(image)

    }, [picture])

    const getComponentsSelect = async () => {
        setShowSelect(!showSelect)
        if (!showSelect) {
            const response = await api.get(`components/simple/${name}`)


            if (String(response).length == 0) {

                setItems([])
                return false
            }


            setItems(response.data)
        }

    }

    const getItems = (type) => {

        getItemsInCart(type)


    }

    useEffect(() => {

        const image = lz.decompressFromUTF16(picture)

        setImageDecompress(image)

    }, [picture])

    const container = {
        padding: "17px 24px 17px 21px",
        borderBottom: "1px solid #E5E5E5",
        display: "flex",
        justifyContent: "space-between"
    }

    const boxCard = {
        display: "flex",
        alignItems: "center",
        gap: "17px",
    }

    return (
        <>
            <div onClick={() => {
                getItems(name)
                getComponentsSelect()
            }} style={container}>

                <div style={boxCard}>
                    {imageDecompress == undefined 
                    
                    ? 
                    <div class="ssc-line" style={{width:"21px",height:"21px",borderRadius:"2px"}}></div>

                    :
                    <img style={{ width: "21px", height: "21px" }} src={"data:image/png;base64," + imageDecompress} />

                    }
                    <p className="textSelectFeatSection">{name}</p>
                </div>


                <img style={{ transform: showSelect ? "rotate(180deg)" : "rotate(360deg)", transition: "0.3s" }} src={arrowDown} />

            </div>

            {showSelect &&

                items.map(item => {
                    return (

                        <ContentSelectionSection
                            id={item.id} key={item.id} name={item.name}
                            category={item.category} time={`${item.develop_time} ${item.develop_time == "1" ? "dia" : "dias"}`}
                            price={item.price != undefined ? Number(item.price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                : <></>} icon={item.icon} picture={pictureFeat} description={item.description} />
                    )
                })
            }
        </>
    )
}

export default FeatureSelectionSection