import React from 'react'
import Sidebar from "../../components/Sidebar";
import Header from '../../components/HeaderFunctionalities';
const ThreeColumLayoutFollowUpPage = ({colum1Data,colum2Data,colum3Data,page,typeHeader}) =>{
    

    const container_three_column = {

        'display': 'grid',
        'grid-template-columns': '1fr ',
    }

    const column1 = {

        gridColumnStart: '1',
        gridColumnEnd: '1',
    }


    return(
        <div style={container_three_column}>

        <Header type={typeHeader}/>
        <Sidebar page={page}/>

        <div style={column1}>
        {colum1Data}
    
        </div>{/* End column1*/}


        <div >
    
        {colum2Data}

        </div>{/* End column2 */}


        </div> 
    )

}

export default ThreeColumLayoutFollowUpPage