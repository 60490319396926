import { useEffect, useState } from "react"
import uploadIcon from "../../../images/Svg/uploadIcon.svg"
import FileOrImage from "./FileOrImage"
import api from "../../../services/api"

const UploadImageOrFile = () => {
    const [image, setImage] = useState([])
    const [imagesFirebase, setImagesFirebase] = useState()
    const [fileChange, setFileChange] = useState(false)
    const [files, setFiles] = useState([])

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id")

    const deleteImage = (id) => {

        const filter = image.filter(item => item.id !== id)

        setImage(filter)
    }


    const getBase64 = async (file) => {

        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = async function () {

            const response = await api.post("file/files", {

                name: file.name,
                project_id: localStorage.getItem("@GLOBAL_ID_PROJECT2"),
                file: reader.result,
                type: file.type,
                size: file.size
            })

            setFileChange(!fileChange)

        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };


    }

    const remove = async (id) => {

        const response = await api.delete(`files/${id}`)

        window.location.href = "/follow"

    }

    const getFiles = async () => {


        const files = await api.get(`file/files/${localStorage.getItem("@GLOBAL_ID_PROJECT2")}`)

        setFiles(files.data)



    }

    const getFile = async () => {
        if(fileChange == false){
            return false
        }
        const response = await api.get(`file/files/${localStorage.getItem("@GLOBAL_ID_PROJECT2")}`)

        if (response.status == 200) {
            setFiles(response.data)
        }

    }

    useEffect(() => {

        getFile()

    }, [fileChange])


    useEffect(() => {

        getFiles()

    }, [])

    return (
        <div style={{
            padding: "24px 25px"
        }}>
            <div style={{
                border: "1px solid #D7D7D7",
                borderRadius: "15px",
                padding: "21px 0",
                display: "flex",
                flexDirection: "column",
                height: "292px",
                padding: "26px",
                marginBottom: "90px"
            }}>
                <label className="textPrimaryButton" htmlFor="file" style={{
                    border: "1px dashed #D7D7D7",
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                    padding: "16px 12px",
                    borderRadius: "10px",
                    marginBottom: "10px"
                }}>
                    <img src={uploadIcon} />

                    <div style={{
                        display: "flex",
                        gap: "11px",
                        flexDirection: "column"
                    }}>
                        <p className="textTitleUploadImg">Selecione um arquivo ou solte aqui</p>
                        <p className="textContentUploadImg">JPG, PNG, SVG ou PDF </p>
                    </div>

                </label>

                <div style={{
                    overflowY: "scroll",
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    gap: "14px"
                }}>

                    {
                        files.map(item => {

                            return (
                                <FileOrImage key={item.id} type={item.type} name={item.name} size={`${item.file}`} onDelete={() => remove(item.id)} />
                            )
                        })
                    }
                </div>


            </div>
            <input onChange={(e) => getBase64(e.target.files[0])} accept='application/pdf, image/*' name="file" id="file" type="file" style={{ display: "none" }} />
        </div>
    )
}

export default UploadImageOrFile