import React, { useState, useEffect } from 'react'
import ThowColumLayoutLogin from '../../../layouts/ThowColumLayoutLogin'
import forgotPasswordBackgrond from '../../../images/Svg/forgotPasswordBackgrond.svg'
import companyIcon from '../../../images/Svg/companyIcon.svg'
import api from '../../../services/api'
import { useNavigate } from 'react-router-dom'
import Button from '../components/Button'
import InputLogin from '../../../components/InputLogin'
import { toast } from 'react-toastify'

const ForgotPassword = () => {

    const [mail, setMail] = useState()
    const [password, setPassword] = useState()
    const navigate = useNavigate();

    const container = {
        width: "418px",
        margin: "0 auto"
    }

    const boxFooterButton = {
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',
        marginTop: "1.5rem"
    }


    /* MESSAGE ERROS */

    const [mailError, setMailError] = useState()
    const [paswordError, setPasswordError] = useState()

    /* BTN STATE */

    const [btnState, setBtnState] = useState()


    const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    const redirectToSendMailPage = async () => {

        const response = await api.post("recover/mail",{
            mail:mail
        })
        if(response.status == 200){

            localStorage.setItem("@RECOVER_MAIL",mail)
            window.location.href="/send/mail"
    
        }else{
            toast.error("Email não encontrado!",{
                autoClose:1000
            })
        }

    }

    const containerFigure = {
        width: "45vw",
        height: "100vh",
        backgroundColor: "#F8F9FF"
    }

    const image = {
        height: "100%",
        width: "100%"
    },


        containerColum2 = {
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
        }

    useEffect(() => {

        if (mail != undefined) {

            const validMail = validateEmail(mail)

            if (validMail == false) {
                setMailError("email inválido")
                setBtnState(true)

            } else {
                setMailError()
                setBtnState(false)

            }
        }

        if (password != undefined) {

            if (String(password).length <= 7) {

                setPasswordError("senha deve conter no mínimo 8 caracteres")
                setBtnState(true)

            } else {

                setBtnState(false)
                setPasswordError()

            }

        }


    }, [mail, password])

    return (

        <ThowColumLayoutLogin
            page={"ForgotPassword"}
            colum1={
                <div style={containerFigure}>
                    <img src={forgotPasswordBackgrond} style={image} />
                </div>
            }

            colum2={

                <div style={containerColum2}>
                    <div style={container}>
                        <img style={{ width: "156px" }} src={companyIcon} />
                        <h2 className="textPrimaryForgotPassword">Esqueceu a senha ?</h2>

                        <p className="textDescriptionForgotPassword">Não se preocupe! Digite seu e-mail para receber instruções de recuperação de senha</p>
                        <InputLogin marginBottom={"12px"} placeholder={"E-mail"} name={"Email"} error={mailError} onChange={(e) => setMail(e.target.value)}/>
                    
                        <div style={boxFooterButton}>
                            <Button width="202px" height="48px" background="var(--color-primary)"
                                borderRadius="60px" marginTop="0" name="Recuperar senha"
                                border="none" disabled={btnState} onClick={() => redirectToSendMailPage()} />

                            <div>
                                <p
                                    onClick={() => navigate("/")}
                                    style={{
                                        cursor: "pointer"
                                    }} className='textForgotPassword'>Lembrou da senha?</p>
                            </div>
                        </div>
                    </div>

                </div>

            }


        >

        </ThowColumLayoutLogin>

    )

}

export default ForgotPassword