import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Dashboard from "../pages/Dashboard";

/* Pages */

import Landing from "../pages/Landing";
import FunctionaLities from "../pages/FunctionaLities";
import GeneralDefinition from "../pages/GeneralDefinition";
import Payment from "../pages/Payment";

import FollowUp from "../pages/FollowUp";
import Register from "../pages/Register";

import Profile from "../pages/Profile";

/* Private Pages */
import PrivateRoute from "../pages/PrivateRoute";

/* Not Found Pages */
import NotFound from "../pages/NotFound";
import { useMediaQuery } from "react-responsive";
import RegisterMobile from "../pages/RegisterMobile/Initial";
import LandingMobile from "../pages/LandingMobile/Initial";
import ForgotPassword from "../pages/ResetPassword/Initial";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import SendMail from "../pages/ResetPassword/SendMail";
import FunctionaLitiesMobile from "../pages/DashboardMobile/FunctionaLities";
import GeneralDefinitionMobile from "../pages/DashboardMobile/GeneralDefinition";
import PaymentAndContractMobile from "../pages/DashboardMobile/PaymentAndContract";
import ProfileMobile from "../pages/DashboardMobile/Profile";
import ForgotPasswordMobile from "../pages/ForgotPasswordMobile/Initial";
import SendMailMobile from "../pages/ForgotPasswordMobile/EmailSuccessfullySent";
import ResetPasswordMobile from "../pages/ForgotPasswordMobile/CreateNewPassword";
import Search from "../pages/DashboardMobile/Search";
import DashboardMobile from "../pages/DashboardMobile/Initial";
import FollowUpMobile from "../pages/DashboardMobile/ProjectMonitoring";

import UpdateFeatures from "../pages/UpdateFeatures";
import GeneralDefinitionUpdate from "../pages/GeneralDefinitionUpdate";
import VerificationData from "../pages/VerificationData";
import Scale from "../pages/Scale";


const AppStack = () => {
  const isMobile = useMediaQuery({ maxWidth: 820 });

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace={true} />} />
        <Route path="*" element={<Navigate to="/not/found" replace={true} />} />

        <Route
          path="/register"
          element={isMobile ? <RegisterMobile /> : <Register />}
        />
        <Route
          path="/login"
          element={isMobile ? <LandingMobile /> : <Landing />}
        />
        <Route path="/not/found" element={<NotFound />} />
        <Route path="/dashboard/verify" element={<PrivateRoute><VerificationData/></PrivateRoute>} />
        <Route path="/dashboard/follow/scale" element={<PrivateRoute><Scale/></PrivateRoute>} />

        <Route
          path="/general"
          element={
            <PrivateRoute>
              {isMobile ? <GeneralDefinitionMobile /> : <GeneralDefinition />}
            </PrivateRoute>
          }
        />
        <Route
          path="/follow"
          element={
            <PrivateRoute>
              {isMobile ? <FollowUpMobile /> : <FollowUp />}
            </PrivateRoute>
          }
        />

        <Route path="/search" element={<Search />} />

        <Route path="/forgot/password" element={isMobile ? <ForgotPasswordMobile /> : <ForgotPassword />} />

        <Route path="/send/mail" element={isMobile ? <SendMailMobile /> : <SendMail />} />

        <Route path="/reset/password" element={isMobile ? <ResetPasswordMobile /> : <ResetPassword />} />


        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              {
                isMobile ?
                  <DashboardMobile />
                  :
                  <Dashboard />

              }
            </PrivateRoute>
          }
        />
        <Route
          path="/functionalities"
          element={
            <PrivateRoute>
              {isMobile ? <FunctionaLitiesMobile /> : <FunctionaLities />}
            </PrivateRoute>
          }
        />
          <Route
          path="/functionalities/update"
          element={
            <PrivateRoute>
              { <UpdateFeatures />}
            </PrivateRoute>
          }
        />

        <Route
          path="/general/update"
          element={
            <PrivateRoute>
              { <GeneralDefinitionUpdate />}
            </PrivateRoute>
          }
        />


        <Route
          path="/payment"
          element={
            <PrivateRoute>
              {isMobile ? <PaymentAndContractMobile /> : <Payment />}
            </PrivateRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <PrivateRoute>
              {isMobile ? <ProfileMobile /> : <Profile />}
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppStack;
