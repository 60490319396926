import { useContext, useEffect, useState } from "react"
import HeaderProfile from "../components/HeaderProfile"
import SelectAndAdded from "../components/SelectAndAdded"
import SearchFunctionaLities from "../components/SearchFunctionaLities"
import FeatureSelectionSection from "../components/FeatureSelectionSection"
import pictureFeat from "../../../images/Svg/pictureFeat.svg"
import FooterPrice from "../components/FooterPrice"
import ModalContainer from "../components/ModalContainer"
import ModalCreateFeature from "../components/ModalCreateFeature"
import ModalAddedFeature from "../components/ModalAddedFeature"
import ModalNewDetailsFeature from "../components/ModalNewDetailsFeature"
import { useNavigate } from "react-router-dom"
import api from "../../../services/api"
import { CartContext } from "../../../context/cart"
import { ComponentContext } from "../../../context/component"
import ModalCartFunctionalities from "../components/ModalCartFunctionalities"
import ContentSelectionSection from "../components/ContentSelectionSection"
import { toast } from "react-toastify"

const FunctionaLitiesMobile = () => {
    const [modalDetailsProject, setModalDetailsProject] = useState(false)
    const [modalFunctionaLities, setModalFunctionaLities] = useState(false)
    const [modalNewProject, setModalNewProject] = useState(false)

    const [modal, setModal] = useState(false)
    const [projectName, setProjectName] = useState()
    const [listProjects, setListProjects] = useState([])
    const [namePlatform, setNamePlatform] = useState()

    const [checkWeb, setCheckWeb] = useState()
    const [checkMobilie, setCheckMobilie] = useState()

    const navigate = useNavigate()

    const onCloseModal = (type) => {

        return setModal(false)

    }

    /* ---------------FUNCTION WEB---------------------*/


    const [idProject,setIdProject] = useState()

    const [items, setItems] = useState([])
    const [image, setImage] = useState()
    const [nameComponent, setNameComponent] = useState()
    const [priceComponent, setPriceComponent] = useState()

    

    const [daysToDevelop, setDaysToDevelop] = useState()
    const [itemsInCart, setItemsInCart] = useState()
    const [nameProject, setNameProject] = useState()

    const [projects, setProjects] = useState([])
    const [projectDelete, setProjectDelete] = useState(false)
    const [typeProject, setTypeProject] = useState()
    const [nameProjectChange, setNameProjectChange] = useState()

    const [typeProjectChange, setTypeProjectChange] = useState()
    const [nameSearch, setNameSearch] = useState()
    const [searchSubComponents, setSearchSubComponents] = useState([])

    const [setCustomComponents] = useState([])
    const { updateCart } = useContext(CartContext)
    const { imageBase64, name, price } = useContext(ComponentContext)

    const [itemsCart2, setItemsCart2] = useState([])

            
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id")
    
    const urlParamsCreateProject = new URLSearchParams(window.location.search)
    const create_project = urlParamsCreateProject.get("create_project")

    const limitPlatform = () => {
        if(listProjects.length >= 4){
            return toast.error('Número máximo de projetos atengido!', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
        
                progress: undefined,
                theme: "light",
              });
        }
        else{
            return setModal(true)
        }
    }
    const getItemsInCart = async () => {
        
        const responseInfoComponent = await api.post(`cart/value/`, {
            mail: localStorage.getItem("@MAIL"),
            project_id: id

        })

        setPriceComponent(responseInfoComponent.data.price)
        setDaysToDevelop(responseInfoComponent.data.developTime)
        setItemsInCart(responseInfoComponent.data.items)


    }
    const getAllCategorys = async () => {

        const responseLocal = JSON.parse(localStorage.getItem("@LOCAL_CATEGORYS"))
        if(responseLocal){
            setItems(responseLocal)
            return false
        }
        const response = await api.get("categorys/get/all")

        if(response.status == 200){
            localStorage.setItem("@LOCAL_CATEGORYS",JSON.stringify(response.data))            
            setItems(response.data)
        }

    }
    const loadImage = async () => {

        setImage(imageBase64)
        setNameComponent(name)

    }
    const getProject = async () => {

        const project = idProject

        if (project) {

            const response = await api.post("project/info/sidebar", {

                mail: localStorage.getItem("@MAIL"),
                project_id: project
            })

            localStorage.setItem("@TYPE_PROJECT", response.data[0].type_project)

            setTypeProjectChange(response.data[0].type_project)
            setNameProjectChange(response.data[0].name)

        }

    }
    const getInfoProject = async (changeToId) => {

        if (changeToId != undefined) {

            const response = await api.post("project/info", {

                mail: localStorage.getItem("@MAIL"),
                project_id: changeToId
            })

            setTypeProject(response.data[0].type_project)
            setNameProject(response.data[0].name)

            localStorage.setItem("@PROJECT_CHANGE_ID2", changeToId)
            navigate("/functionalities")

            return false

        }

        const responseInfo = await api.get(`project/all/${localStorage.getItem("@MAIL")}`)

        const response = await api.post("project/info", {

            mail: localStorage.getItem("@MAIL"),
            project_id:id
        })

        
        localStorage.setItem("@LOCAL_LENGTH_PROJECTS_VALUE",String(responseInfo.data.length))
        const localEventProjects = localStorage.getItem("@LOCAL_LENGTH_PROJECTS_VALUE")

        setListProjects(responseInfo.data)
        setTypeProject(response.data[0].type_project)
        setNameProject(response.data[0].name)

    }
    const getProjects = async () => {
        const response = await api.get(`project/all/${localStorage.getItem("@MAIL")}`)
        setProjects(response.data)

    }
    const getComponents = async () => {
        const response = await api.get(`/components/name/${nameSearch}`)
        if(response.status == 404){
          setSearchSubComponents([])
          return false
        }
        setSearchSubComponents(response.data)
    }
    const getCustomComponents = async () => {

        const response = await api.get(`custom/project/component/${idProject}`)
        if(response.status == 400){
            setCustomComponents([])
            return false
        }
        setCustomComponents(response.data)


    }
    const redirectToGeneralDefinitionPage = () => {

        navigate("/general")

    }
    const getLastId = async () => {

            const response = await api.post("project/last/",{
                mail:localStorage.getItem("@MAIL")
            })

            setIdProject(response.data.id)

            window.location.href=`functionalities/?id=${response.data.id}`
    }

    useEffect(() => {
        const test = async () => {

            const response2 = await api.post(`cart/simple/sidebar`, {

                "id": id,
                "mail": localStorage.getItem("@MAIL"),
            })
        }
        test()
        getAllCategorys()
    }, [])
    useEffect(() => {
        getProjects()
    },[])
    useEffect(() => {
        if (projectName) {
            localStorage.setItem("@PROJECT_CHANGE_ID", projectName.project_id)
            window.location.href="/functionalities"
        }
    }, [projectName])
    useEffect(() => {

        getProjects()

    }, [projectDelete])
    useEffect(() => {

        getInfoProject()

    },[])
    useEffect(() => {

        getInfoProject()

    }, [projectDelete])
    useEffect(() => {
        getItemsInCart()
    }, [updateCart])
    useEffect(() => {

        loadImage()

    }, [imageBase64])
    useEffect(() => {

        getProject()


    },[])
    useEffect(() => {

        getComponents()


    }, [nameSearch])
    useEffect(() => {

        api.post(`cart/simple/sidebar`, {

            "id": id,
            "mail": localStorage.getItem("@MAIL"),
        }).then((response) => {

            setItemsCart2(response.data)



        })


    }, [updateCart])
    useEffect(() => {

        getCustomComponents()

    },[])
    useEffect(() => {

        getCustomComponents()

    }, [updateCart])

    useEffect(() => {
        if(create_project == "true"){
            setModal(true)

        }

    },[create_project])

    useEffect(() =>{

        if(id == null || id == undefined){

            getLastId()
        }

    },[id])

    const container = {
        padding: "19px 0",
        backgroundColor: "#E5E5E520",
    }


    return (
        <div style={container}>
            <HeaderProfile />
        
            <SelectAndAdded id={id != null ? id: idProject} typeFunctionaLities={true} unique={true} addedPlatform={limitPlatform} value={projectName} setValue={setProjectName} list={listProjects} />

            <SearchFunctionaLities onChange={(e) => setNameSearch(e.target.value)} addedProject={() => setModalNewProject(true)} />

            {
                searchSubComponents.length > 0 ?
                    searchSubComponents.map(item => {
                        return (
                            <ContentSelectionSection
                                id={item.id} idUrl={id} key={item.id} name={item.name}
                                category={item.category} time={`${item.develop_time} ${item.develop_time == "1" ? "dia" : "dias"}`}
                                price={item.price != undefined ? Number(item.price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                    : <></>} icon={item.icon} picture={pictureFeat} description={item.description} />
                        )
                    })
                    :

                    
                    
                    items.map(item => {

                        return (

                            <FeatureSelectionSection picture={item.picture} id={item.id} name={item.name} />
                        )
                    })
            }
            <div style={{ marginBottom: "115px" }}></div>

            <FooterPrice page="functionalities"  priceTotal={price} onClick={() => redirectToGeneralDefinitionPage()} onCart={() => setModalFunctionaLities(true)}/>

            <ModalContainer zIndex={20} isActive={modal} closeModal={onCloseModal}>
                <ModalCreateFeature
                    listProjects={listProjects}
                    setListProjects={setListProjects}
                    closeModal={onCloseModal}
                    namePlatform={namePlatform}
                    setNamePlatform={setNamePlatform}
                    checkWeb={checkWeb}
                    setCheckWeb={setCheckWeb}
                    checkMobilie={checkMobilie}
                    setCheckMobilie={setCheckMobilie}
                />
            </ModalContainer>

            <ModalContainer zIndex={20} isActive={modalNewProject} closeModal={() => setModalNewProject(false)}>
                <ModalAddedFeature setListProjects={setListProjects} closeModal={() => setModalNewProject(false)} />
            </ModalContainer>

            <ModalContainer isActive={modalDetailsProject} closeModal={() => setModalDetailsProject(false)}>
                <ModalNewDetailsFeature closeModal={() => setModalDetailsProject(false)} />
            </ModalContainer>

            <ModalContainer isActive={modalFunctionaLities} closeModal={() => setModalFunctionaLities(false)}>
                <ModalCartFunctionalities id={idProject} list={listProjects} value={projectName} cart={true} closeModal={() => setModalFunctionaLities(false)} />
            </ModalContainer>

        </div>

    )
}

export default FunctionaLitiesMobile