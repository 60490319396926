import React,{createContext,useState} from 'react'

export const ModalContext = createContext({})

function ModalProvider({children}){

    const [modalState,setModalState] = useState(false)
    
    return(
    <ModalContext.Provider value={{modalState,setModalState}}>
        {children}
    </ModalContext.Provider>
    )

}

export default ModalProvider