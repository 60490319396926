import React, { useState } from 'react'

const Input = ({ name, type, placeholder, onChange, value}) => {

    const inputBox = {
        border: "1px solid transparent",
        borderRadius: "60px",
        backgroundColor: "#F8F8F8",
        outline: "none",
        height: "45px",
        padding: "0 20px"
    }
    return (
        <label className='textLabelInput' style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
            {name}
            <input onChange={onChange} value={value} style={inputBox} type={type} placeholder={placeholder} />
        </label>
    )

}

export default Input