import { useContext, useState } from "react";
import Input from "./Input";
import Select from "./Select";
import SelectState from "./SelectState";
import SelectCity from "./SelectCity";
import { PaymentContext } from "../../../context/payment";

const CardPaymentDetails = ({
  mail,
  name,
  userCpf,
  cep,
  userAddress,
  address,
  number,
  complement,
  userDistrict,
  dueDate,
  userCity,
  data,
}) => {

  const { formData,updateState } = useContext(PaymentContext)

  const getCep = async (cepValue) => {


    await fetch(`https://viacep.com.br/ws/${cepValue}/json/`)
      .then((res) => res.json())
      .then((result) => {
        console.log(result)

        updateState("userAddress",result.logradouro)
        updateState("userDistrict",result.bairro)
        //setUserAddress(result.logradouro);
        //setUserDistrict(result.bairro);
        //setComplement(result.complemento);
        //updateState("ufState",result.uf);
        //setUserCity(result.logradouro);
      });
  };

  return (
    <div
      style={{
        padding: "24px",
      }}
    >
      <h3 className="textDefinition">Pagamento e contrato</h3>

      <div
        style={{
          border: "1px solid #D7D7D7",
          borderRadius: "15px",
          padding: "23px 22px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: "25px",
          }}
        >
          <h3 className="textTitleFormPayment">
            Confirme seus dados de cobrança
          </h3>

          <p className="textContentFormPayment">
            Esses dados serão utilizados para o envio de cobrança e as
            respectivas notas fiscais de serviço.
          </p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Input
            name={"Emails de cobrança e NF"}
            onBlur={(e) => {
              updateState('mail', e.target.value)
            }}
            placeholder={"email@dominio.com "}
          />
          <Input
            name={"Razão Social ou Nome Completo"}
            onBlur={(e) => {
              updateState('name', e.target.value)
            }}
            placeholder={""}
          />
          <Input
            mask={true}
            model={String(formData.userCpf).length > 14 ? "99.999.999/9999-999" : "999.999.999-99"}
            name={"CNPJ ou CPF"}
            onBlur={(e) => {
              updateState('userCpf', e.target.value)
            }}
            placeholder={""}
          />
          <Input
            name={"CEP"}
            mask={true}
            model={"99999-999"}
            onChange={(e) => {
              updateState('cep', e.target.value)
            }}
            onBlur={(e) => {
              getCep(e.target.value)
            }}
           
            placeholder={"00000-000"}
          />
          <Input
            name={"Rua"}
            onBlur={(e) => {
              updateState('userAddress', e.target.value)
            }}
            onChange={(e) => {
              updateState('userAddress', e.target.value)

            }}
            placeholder={""}
            value={formData.userAddress}
          />
          <Input
            name={"Número"}
            onBlur={(e) => {
              updateState('number', e.target.value)
            }}
            placeholder={""}
          />
          <Input
            name={"Complemento"}
            onBlur={(e) => {
              updateState('complement', e.target.value)
            }}
            placeholder={""}
          />
          <Input
            name={"Bairro"}
     
            onBlur={(e) => {
              {updateState('userDistrict', e.target.value);userDistrict(e.target.value)}
            }}
            onChange={(e) => {
              updateState('userDistrict', e.target.value)

            }}
            value={formData.userDistrict}
            placeholder={""}
          />

          <SelectState
            name={"Estado"}
            onBlur={(e) => {
              updateState('ufState', e.target.value)
            }}
            setValue={updateState}
          />
          <SelectCity
            name={"Cidade"}
            onBlur={(e) => {
              updateState('cityState', e.target.value)
            }}
            setValue={updateState}
            
          />
        </div>
      </div>
    </div>
  );
};

export default CardPaymentDetails;
