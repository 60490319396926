import React from 'react'
import { BigHead } from '@bigheads/core'

const BigHeads = ({width}) => {

    return(
    <BigHead
    
    width={width != undefined ? width :45}
    
    skinTone="red"
    eyes="leftTwitch"
    eyebrows="leftLowered"
    mouth="sad"
    hair="afro"
    facialHair="none"
    clothing="dressShirt"
    accessory="tinyGlasses"
    graphic="none"
    hat="none5"
    body="breasts"
    hairColor="pink"
    clothingColor="black"
    circleColor="blue"
    lipColor="red"
    hatColor="green"
    faceMaskColor="white"
    mask="true"
    faceMask="false"
    lashes="false"
/>
    )

}

export default BigHeads