import React,{createContext,useState,useContext} from 'react'
import { ModalContext } from './modal'
import api from '../services/api'

export const TermsContext = createContext({})

function TermsProvider({children}){

    const {setModalState} = useContext(ModalContext)

    const [startpnIdeia,setStarpnIdeia] = useState(false)
    const [startpnScale,setStartpnScale] = useState(false)

    const storage = async (startpnIdeia,startpnScale) => {


        if(startpnIdeia == true && startpnScale == false){
            /*Verificar aqui se existe alguma assinatura pendente ainda */

            localStorage.setItem("@STARTPN_IDEIA_CONTRACT_STATUS",true)
            const response = await api.get(`terms/user/terms/${localStorage.getItem("@MAIL")}`)
            if(response.status == 404){
                setModalState(false)
                const state = await api.put("project/all",{
                    mail:localStorage.getItem("@MAIL")
                })
                localStorage.removeItem("@STARTPN_IDEIA_CONTRACT_STATUS")
                localStorage.removeItem("@STARTPN_SCALE_CONTRACT_STATUS")
                window.location.href="/dashboard/verify"
                return false
            }


            if(localStorage.getItem("@STARTPN_IDEIA_CONTRACT_STATUS") == "true" && localStorage.getItem("STARTPN_SCALE_CONTRACT_STATUS") == "true"){

                const state = await api.put("project/all",{
                    mail:localStorage.getItem("@MAIL")
                })
                localStorage.removeItem("@STARTPN_IDEIA_CONTRACT_STATUS")
                localStorage.removeItem("@STARTPN_SCALE_CONTRACT_STATUS")
                
                window.location.href="/dashboard/verify"
                

            }else{
                window.location.href="/payment"
                setModalState(false)
            }
            setModalState(false)
            setStarpnIdeia(true)
        }

        if(startpnIdeia == false && startpnScale == true){                    
            const response = await api.post(`terms/storage/doc/keys`, {
                startpnIdeiaKey:localStorage.getItem("@KEY_STARTPN_IDEIA"), 
                startpnScaleKey: localStorage.getItem("@KEY_STARTPN_SCALE"), 
                mail: localStorage.getItem("@MAIL"),
              })

            localStorage.setItem("@STARTPN_SCALE_CONTRACT_STATUS",true)
            setStartpnScale(true)
            if(localStorage.getItem("@STARTPN_IDEIA_CONTRACT_STATUS") == "true" && localStorage.getItem("STARTPN_SCALE_CONTRACT_STATUS") == "true"){


                const state = await api.put("project/all",{
                    mail:localStorage.getItem("@MAIL")
                })
                localStorage.removeItem("@STARTPN_IDEIA_CONTRACT_STATUS")
                localStorage.removeItem("@STARTPN_SCALE_CONTRACT_STATUS")
                window.location.href="/dashboard/verify"
                
            }else{
                const state = await api.put("project/all",{
                    mail:localStorage.getItem("@MAIL")
                })
                localStorage.removeItem("@STARTPN_IDEIA_CONTRACT_STATUS")
                localStorage.removeItem("@STARTPN_SCALE_CONTRACT_STATUS")
                window.location.href="/dashboard/verify"
                setModalState(false)
            }
        }

        

    }

    
    return(
    <TermsContext.Provider value={{storage,startpnIdeia,startpnScale}}>
        {children}
    </TermsContext.Provider>
    )

}

export default TermsProvider