import note from "../../../images/Svg/noteFeat.svg"

const NoteFuture = () => {

    return(
        <div style={{
            padding: "20px 25px"
        }}>
            <div style={{
                border: "1px solid #D7D7D7",
                borderRadius: "15px",
                padding: "16px 26px",
            }}>

                <h3 className="textTitleNoteProfile">Notas fiscais & Cobranças</h3>
                <p className="textShotlyNote">Em breve</p>

                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px"
                }}>
                    <img src={note} />
                </div>
            </div>
        </div>
    )
}

export default NoteFuture