import React,{createContext,useState} from 'react'

export const ProjectsContext = createContext({})

function ProjectsProvider({children}){

    const [idGlobal,setIdGlobal] = useState()
    const [components,setComponents] = useState([])
    const [idProject,setIdProject] = useState()

    const storage = (id) => {
        setIdGlobal(id)
    }

    const getProject = async (id) => {
        setIdProject(id)
    }


    
    return(
    <ProjectsContext.Provider value={{storage,idGlobal,getProject,components,idProject}}>
        {children}
    </ProjectsContext.Provider>
    )

}

export default ProjectsProvider