import React, { useState, useEffect, useContext, ChangeEvent } from "react";

import ThreeColumLayoutFollowUpPage from "../../layouts/ThreeColumLayoutFollowUpPage";
import api from "../../services/api";

import { CartContext } from "../../context/cart";
import { DashboardContext } from "../../context/dashboard";
import "skeleton-screen-css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import SidebarCheckbox from "../../components/SidebarCheckbox";

/* LOCAL COMPONENTS */
import CardDashboard from "../../components/CardDashboard";
import CardPrototype from "../../components/CardPrototype";
import SwitchButton from "../../components/SwitchButton"

import GlobalCard from "../../components/GlobalCard";


const FollowUpDesign = () => {
          
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id") 
  const { updateCart } = useContext(CartContext);
  const [customComponents, setCustomComponents] = useState([]);
  const [projects, setProjects] = useState([]);
  const [fileChange, setFileChange] = useState(false);
  const [files, setFiles] = useState([]);


  const getProjects = async () => {
    const response = await api.post("project/project/follow", {
      mail: localStorage.getItem("@MAIL"),
      global_id: localStorage.getItem("@GLOBAL_ID_PROJECT2"),
    });

    setProjects(response.data);
  };
  const getCustomComponents = async () => {
    const projectChange = localStorage.getItem(
      "@PROJECT_CHANGE_ID_FOLLOW_PAGE"
    );

    const response = await api.get(`custom/project/component/${id}`);

    setCustomComponents(response.data);
  };
  const getFiles = async () => {
    const files = await api.get(
      `file/files/${localStorage.getItem("@GLOBAL_ID_PROJECT2")}`
    );

    setFiles(files.data);
  };

  useEffect(() => {
    getFiles();
  }, [fileChange]);
  useEffect(() => {
    getCustomComponents();
  }, []);
  useEffect(() => {
    getCustomComponents();
  }, [updateCart]);
  useEffect(() => {
    getProjects();
  }, []);
  useEffect(() => {
    getFiles();
  }, []);

  useEffect(() => {

    if(id != undefined){
      localStorage.setItem("@UID_FOLLOW_PAGE",id)

    }

  },[id])


  return (
    <>
      <ToastContainer />

      <ThreeColumLayoutFollowUpPage
        typeHeader={"follow"}
        colum1Data={
          
          <div
            style={{
              display: "flex",
              overflowX: "hidden",
              width: "90%",
              justifyContent:"center"
            }}
          >

            <SwitchButton uid={id}/>
            <GlobalCard id={id}/>

            {/* End containerVelocityProject */}
            <div
              style={{
                height: "656px",
                width: "406px",
                border: "1px solid #D7D7D7",
                marginTop: "5.4rem",
                marginLeft: "1.875rem",
                borderRadius: "15px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <div
                style={{
                  height: "72px",
                  borderBottom: "1px solid #D7D7D7",
                  paddingTop: "1.5rem",
                  paddingLeft: "1.25rem",
                }}
              >
                <p className="dueDateFollowUpFinsh">Datas de entrega</p>
              </div>

              <CardPrototype />

              {projects.map((item) => {
                return (
                  <CardDashboard
                    id={item.id}
                    name={item.name}
                    type={item.type_project}
                    project_id={item.project_id}
                  />
                );
              })}
            </div>
          </div>
        }
        colum2Data={<SidebarCheckbox id={id} type={"design"} />}
      />
    </>
  );
};

export default FollowUpDesign;