import React, { useState, useEffect, useContext } from 'react'
import { CartContext } from '../../context/cart'
import "skeleton-screen-css";
import api from '../../services/api'
import CustomComponentGeneralPage from '../../components/CustomComponentGeneralPage'

const InfoProject = ({ id, project_id, name, type_project, onClick, desktop_icon, mobile_icon, page }) => {

  const [componentsCart, setComponentsCart] = useState([])
  const [items, setItems] = useState()
  const [daysToDevelop, setDaysToDevelop] = useState()
  const [totalProject, setTotalProject] = useState()
  const [newNameProject, setNewNameProject] = useState()
  const { removeItemCartProject,updateCart } = useContext(CartContext)
  const [customComponents, setCustomComponents] = useState([])


  const getInfo = async () => {

    if (page == "update") {

      const response = await api.post("/components/project/pay", {

        "project_id": project_id,


      })
      setComponentsCart(response.data)
      
      const responseItemsInCart = await api.post("cart/value/pay", {

        "project_id": project_id,
        "mail": localStorage.getItem("@MAIL")
  
  
      })

      setItems(responseItemsInCart.data.items)
      setDaysToDevelop(responseItemsInCart.data.developTime)
      setTotalProject(responseItemsInCart.data.price)
      return false

      
    }

    const response = await api.post("/components/project", {

      "project_id": project_id,


    })



    setComponentsCart(response.data)


    const responseItemsInCart = await api.post("cart/value", {

      "project_id": project_id,
      "mail": localStorage.getItem("@MAIL")


    })

    setItems(responseItemsInCart.data.items)
    setDaysToDevelop(responseItemsInCart.data.developTime)
    setTotalProject(responseItemsInCart.data.price)
  }
  const updateNameProject = async (project_id) => {

    if (newNameProject == undefined || newNameProject == "") {

      return false

    }

    const response = await api.put("project", {

      name: newNameProject,
      project_id: project_id

    })

    if (response.status == 200) {

      window.location.href = "/general"

    }


  }
  function deleteItemCart(idItem, project_id) {
    removeItemCartProject(idItem, project_id)
  }
  const getCustomComponents = async () => {
    const response = await api.get(`custom/project/component/${project_id}`)

    setCustomComponents(response.data)


  }


  useEffect(() => {

    getInfo()
    getCustomComponents()

  }, [])

  useEffect(() => {
    getInfo()
    getCustomComponents()

  },[updateCart])

  return (

    <div key={id}>

      {items == 0
        ?


        <></>
        :


        <div style={{ border: "1px solid #dcdcdc", width: "321px", marginTop: "110px", marginLeft: "1.875rem", height: "655px", borderRadius: "15px", cursor: "pointer" }}>

          <div style={{ display: "flex", paddingTop: "1.438rem", height: "80px", borderBottom: "1px solid #dcdcdc", paddingLeft: "1.313rem", justifyContent: "space-between" }}>

            <div style={{ display: "flex" }} onClick={onClick}>

              {type_project == "web"

                ?
                <svg width="28" height="29" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M29.9426 17.0838V7.30555C29.9405 5.50127 29.2228 3.77151 27.9469 2.49569C26.6711 1.21987 24.9414 0.502161 23.1371 0.5H9.52597C7.72169 0.502161 5.99193 1.21987 4.71611 2.49569C3.44029 3.77151 2.72258 5.50127 2.72042 7.30555V17.0838C1.81299 17.4034 1.04796 18.0338 0.56083 18.8635C0.0736966 19.6931 -0.104103 20.6684 0.0589227 21.6165C0.221948 22.5647 0.715278 23.4245 1.45154 24.0438C2.18779 24.6631 3.11946 25.0018 4.08153 25H28.5815C29.5436 25.0018 30.4753 24.6631 31.2115 24.0438C31.9478 23.4245 32.4411 22.5647 32.6041 21.6165C32.7672 20.6684 32.5894 19.6931 32.1022 18.8635C31.6151 18.0338 30.8501 17.4034 29.9426 17.0838ZM9.52597 3.22222H23.1371C24.22 3.22222 25.2587 3.65243 26.0244 4.4182C26.7902 5.18398 27.2204 6.22259 27.2204 7.30555V16.8333H21.5704C21.1746 16.8331 20.7834 16.9192 20.4242 17.0856C20.065 17.252 19.7464 17.4948 19.4907 17.797L19.1545 18.1944H13.5072L13.1724 17.797C12.9166 17.4948 12.5981 17.252 12.2389 17.0856C11.8797 16.9192 11.4885 16.8331 11.0926 16.8333H5.44264V7.30555C5.44264 6.22259 5.87285 5.18398 6.63862 4.4182C7.4044 3.65243 8.44301 3.22222 9.52597 3.22222ZM28.5815 22.2778H4.08153C3.72054 22.2778 3.37434 22.1344 3.11908 21.8791C2.86382 21.6239 2.72042 21.2776 2.72042 20.9167C2.72042 20.5557 2.86382 20.2095 3.11908 19.9542C3.37434 19.699 3.72054 19.5555 4.08153 19.5555H11.094L11.4288 19.953C11.6844 20.255 12.0028 20.4977 12.3617 20.6641C12.7207 20.8305 13.1116 20.9167 13.5072 20.9167H19.1545C19.5504 20.9169 19.9415 20.8308 20.3007 20.6644C20.6599 20.498 20.9785 20.2552 21.2342 19.953L21.5704 19.5555H28.5815C28.9425 19.5555 29.2887 19.699 29.544 19.9542C29.7992 20.2095 29.9426 20.5557 29.9426 20.9167C29.9426 21.2776 29.7992 21.6239 29.544 21.8791C29.2887 22.1344 28.9425 22.2778 28.5815 22.2778Z" fill="black" />
                </svg>

                :

                <svg width="19" height="29" viewBox="0 0 19 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.9479 28.2502H5.88542C2.6402 28.2502 0 25.61 0 22.3648V5.8856C0 2.64038 2.6402 0.000183105 5.88542 0.000183105H12.9479C16.1931 0.000183105 18.8333 2.64038 18.8333 5.8856V22.3648C18.8333 25.61 16.1931 28.2502 12.9479 28.2502ZM5.88542 2.35435C3.93852 2.35435 2.35417 3.9387 2.35417 5.8856V22.3648C2.35417 24.3117 3.93852 25.896 5.88542 25.896H12.9479C14.8948 25.896 16.4792 24.3117 16.4792 22.3648V5.8856C16.4792 3.9387 14.8948 2.35435 12.9479 2.35435H5.88542ZM11.7708 22.3648C11.7708 21.715 11.2435 21.1877 10.5937 21.1877H8.23958C7.58983 21.1877 7.0625 21.715 7.0625 22.3648C7.0625 23.0145 7.58983 23.5419 8.23958 23.5419H10.5937C11.2435 23.5419 11.7708 23.0145 11.7708 22.3648Z" fill="black" />
                </svg>

              }



              <div style={{ width: "120px", marginLeft: "1.25rem" }}>

                <div style={{ position: "relative" }}>

                  <div style={{ width: "139px" }}>

                    <input className="subTextTypeProjectGeneralDefinition" onBlur={() => updateNameProject(project_id)} onChange={(e) => setNewNameProject(e.target.value)} style={{ border: "none", outline: "none", position: "relative", width: "175px" }} maxLength={35} type="text" value={newNameProject != undefined ? newNameProject : name} />


                  </div>
                </div>


              </div>

            </div>




          </div>


          <div style={{ paddingLeft: "1.313rem", borderBottom: "1px solid #dcdcdc", paddingRight: "1.063rem", paddingLeft: "1.063rem", paddingTop: "0.9rem", height: "50px", display: "flex", justifyContent: "space-between" }}>

            <p className="textFunctionalitiesGeneralDefinition">Funcionalidades</p>
            <div style={{ width: "58px", height: "31px", marginTop: "-3px", background: "#1172EB", borderRadius: "15px", display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>{items}</div>
          </div>


          <div style={{ height: "440px", overflowY: "auto", paddingTop: "0.438rem", position: "relative" }}>


            {componentsCart.length == 0

              ?

              type_project == "web"

                ?
                <div style={{ marginLeft: "1.13rem" }}>

                  <div style={{ display: "flex", alignItems: "center", marginTop: "0.9rem" }}>
                    <div class="ssc-square" style={{ width: "84px", height: "49.63px", borderRadius: "2px" }}></div>

                    <div style={{ marginLeft: "1.25rem" }}>
                      <div class="ssc-line" style={{ width: "64px", height: "11.38px", borderRadius: "16.25px" }}></div>

                      <div style={{ display: "flex", marginTop: "1.351rem" }}>

                        <div class="ssc-line" style={{ width: "64px", height: "11.38px", borderRadius: "16.25px" }}></div>
                        <div class="ssc-line" style={{ width: "64px", height: "11.38px", borderRadius: "16.25px", marginLeft: "0.313rem" }}></div>

                      </div>
                    </div>

                  </div>
                </div>
                :

                <div style={{ display: "flex", alignItems: "center", marginLeft: "0.913rem" }}>
                  <div class="ssc-square" style={{ width: "35px", height: "76.4px", borderRadius: "2px" }}></div>

                  <div style={{ marginLeft: "1.25rem" }}>
                    <div class="ssc-line" style={{ width: "64px", height: "11.38px", borderRadius: "16.25px" }}></div>

                    <div style={{ display: "flex", marginTop: "1.351rem" }}>

                      <div class="ssc-line" style={{ width: "64px", height: "11.38px", borderRadius: "16.25px" }}></div>
                      <div class="ssc-line" style={{ width: "64px", height: "11.38px", borderRadius: "16.25px", marginLeft: "0.313rem" }}></div>

                    </div>
                  </div>

                </div>
              :

              <></>

            }



            {componentsCart.map(item => {


              return (


                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ display: "flex", alignItems: "center", position: "relative", width: "100%", borderBottom: "1px solid #dcdcdc", height: "90px", paddingBottom: "0.438rem", paddingTop: "0.438rem" }}>
                    {type_project == "web"
                      ?
                      <img src={item[0].desktop_icon} style={{ objectFit: 'cover', width: '84px', borderRadius: '5px', borderRadius: '2px', border: "1px solid #1172EB", marginLeft: "16px" }} />
                      :
                      <img src={item[0].mobile_icon} style={{ objectFit: 'contain', width: '35px', height: "76px", borderRadius: '2px', border: "1px solid #1172EB", marginLeft: "16px" }} />

                    }

                    {type_project == "web"
                      ?
                      <div style={{ width: "10px" }}></div>
                      :
                      <div style={{ width: "10px" }}></div>
                    }

                    <div>
                      <p className="textComponentInfoProject">{item[0].name}</p>
                      <p className="subTextComponentInfoProject">{item[0].develop_time == 1 ? `${item[0].develop_time} dia` : `${item[0].develop_time} dias`} {item[0].price != undefined ? Number(item[0].price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : <></>}</p>


                    </div>

                    {page == "update"

                      ?
                      <></>
                      :
                      <div style={{ position: "absolute", right: 15 }} onClick={() => deleteItemCart(item[0].id)}>
                        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_0_2487)">
                            <path d="M16.6247 3.4069H14.1705C13.9868 2.51343 13.5006 1.71063 12.794 1.1338C12.0874 0.556972 11.2035 0.241386 10.2913 0.240234L8.70801 0.240234C7.79585 0.241386 6.91197 0.556972 6.20535 1.1338C5.49874 1.71063 5.01259 2.51343 4.82884 3.4069H2.37467C2.16471 3.4069 1.96335 3.49031 1.81488 3.63877C1.66642 3.78724 1.58301 3.9886 1.58301 4.19857C1.58301 4.40853 1.66642 4.60989 1.81488 4.75836C1.96335 4.90682 2.16471 4.99023 2.37467 4.99023H3.16634V15.2819C3.1676 16.3313 3.58504 17.3374 4.3271 18.0795C5.06916 18.8215 6.07525 19.239 7.12468 19.2402H11.8747C12.9241 19.239 13.9302 18.8215 14.6723 18.0795C15.4143 17.3374 15.8318 16.3313 15.833 15.2819V4.99023H16.6247C16.8346 4.99023 17.036 4.90682 17.1845 4.75836C17.3329 4.60989 17.4163 4.40853 17.4163 4.19857C17.4163 3.9886 17.3329 3.78724 17.1845 3.63877C17.036 3.49031 16.8346 3.4069 16.6247 3.4069ZM8.70801 1.82357H10.2913C10.7824 1.82417 11.2612 1.97666 11.6622 2.26013C12.0632 2.5436 12.3666 2.94416 12.531 3.4069H6.46839C6.63272 2.94416 6.93618 2.5436 7.33715 2.26013C7.73812 1.97666 8.21696 1.82417 8.70801 1.82357ZM14.2497 15.2819C14.2497 15.9118 13.9995 16.5159 13.5541 16.9613C13.1087 17.4067 12.5046 17.6569 11.8747 17.6569H7.12468C6.49479 17.6569 5.8907 17.4067 5.4453 16.9613C4.9999 16.5159 4.74968 15.9118 4.74968 15.2819V4.99023H14.2497V15.2819Z" fill="black" />
                            <path d="M7.91666 14.4903C8.12663 14.4903 8.32799 14.4068 8.47646 14.2584C8.62492 14.1099 8.70833 13.9086 8.70833 13.6986V8.94859C8.70833 8.73863 8.62492 8.53726 8.47646 8.3888C8.32799 8.24033 8.12663 8.15692 7.91666 8.15692C7.7067 8.15692 7.50534 8.24033 7.35687 8.3888C7.20841 8.53726 7.125 8.73863 7.125 8.94859V13.6986C7.125 13.9086 7.20841 14.1099 7.35687 14.2584C7.50534 14.4068 7.7067 14.4903 7.91666 14.4903Z" fill="black" />
                            <path d="M11.0837 14.4901C11.2936 14.4901 11.495 14.4067 11.6435 14.2582C11.7919 14.1097 11.8753 13.9084 11.8753 13.6984V8.94841C11.8753 8.73844 11.7919 8.53708 11.6435 8.38861C11.495 8.24015 11.2936 8.15674 11.0837 8.15674C10.8737 8.15674 10.6723 8.24015 10.5239 8.38861C10.3754 8.53708 10.292 8.73844 10.292 8.94841V13.6984C10.292 13.9084 10.3754 14.1097 10.5239 14.2582C10.6723 14.4067 10.8737 14.4901 11.0837 14.4901Z" fill="black" />
                          </g>
                          <defs>
                            <clipPath id="clip0_0_2487">
                              <rect width="19" height="19" fill="white" transform="translate(0 0.240234)" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>


                    }

                  </div>
                </div>

              )

            })}



            {componentsCart.length > 0

              ?

              customComponents.map(item => {
                return (

                  <CustomComponentGeneralPage type_project={type_project} name={item.name} id={item.id} />
                )

              })

              :
              <></>

            }




          </div>

          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30px", marginTop: "2rem" }}>

            <div style={{ marginRight: "1.75rem" }}>
              <p className="textValueProjectGeneralDefinition">Custo total</p>
              {totalProject == undefined

                ?
                <div class="ssc-line w-100 " style={{ marginBottom: "15px" }}></div>

                :
                <p className="textInfoProject">{Number(totalProject).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>

              }

            </div>

            <svg width="2" height="42" viewBox="0 0 2 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.822266 42L0.822266 -7.15256e-07" stroke="#C1C1C1" stroke-width="0.8" />
            </svg>



            <div style={{ marginLeft: "1.75rem" }}>
              <p className="textValueProjectGeneralDefinition">Desenvolvimento</p>

              {daysToDevelop == undefined
                ?
                <div class="ssc-line w-100 " style={{ marginBottom: "15px" }}></div>

                :
                <p className="textInfoProject">{String().length > 1 ? `${daysToDevelop} dias` : `${daysToDevelop} dias`}</p>

              }
            </div>

          </div>




          <div style={{ height: "12px" }}></div>

        </div>

      }


    </div>

  )

}

export default InfoProject