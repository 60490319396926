import React, { useEffect, useState } from 'react'
import api from '../../services/api'
import mobileIconSidebar from '../../images/Svg/MobileIconSidebar.svg'
import weMobileIcon from '../../images/Svg/weMobileIcon.svg'
import PreviewFunctionalitieCheckList from '../../components/PreviewFunctionalitieCheckList'
import PreviewCustomFunctionalitie from '../../components/PreviewCustomFunctionalitie'

const SidebarCheckbox = ({ id }) => {

  const [customComponents, setCustomComponents] = useState([])

  const [projects, setProjects] = useState([])
  const [typeProjectChange, setTypeProjectChange] = useState()

  const [newNameProject, setNewNameProject] = useState()
  const [typeProject, setTypeProject] = useState()
  const [nameProjectChange, setNameProjectChange] = useState()

  const [itemsInCart, setItemsInCart] = useState()
  const [itemsCart2, setItemsCart2] = useState([])

  const redirectToUpdatePage = () => {
    
    localStorage.getItem("@TYPE_PROJECT",typeProject) 
    window.location.href = `/functionalities/update?id=${id}`

  }

  const getCustomComponents = async () => {
    const projectChange = localStorage.getItem("@PROJECT_CHANGE_ID_FOLLOW_PAGE")

    const response = await api.get(`custom/project/component/${id}`)


    setCustomComponents(response.data)


  }
  const updateNameProject = async () => {

    const response = await api.put("global/name", {

      name: newNameProject,
      project_id: id

    })
  }
  const getInfoProject = async (changeToId) => {


    if (changeToId != undefined) {

      const response = await api.post("project/project/follow/info", {

        mail: localStorage.getItem("@MAIL"),
        project_id: changeToId
      })


      setTypeProject(response.data[0].type_project)
      setNameProjectChange(response.data[0].name)



      const globalId = await api.post("/global/project/info/id", {

        mail: localStorage.getItem("@MAIL"),
        project_id: changeToId

      })



      const responseProjects = await api.post("global/platform/projects", {
        mail: localStorage.getItem("@MAIL"),
        global_id: globalId.data[0].global_id

      })


      setProjects(responseProjects.data)


      localStorage.setItem("@GLOBAL_ID_PROJECT2", globalId.data[0].global_id)
      localStorage.setItem("@PROJECT_CHANGE_ID_FOLLOW_PAGE", changeToId)


      return false

    }

    const response = await api.post("project/project/follow/info", {

      mail: localStorage.getItem("@MAIL"),
      project_id: id
    })


    const globalId = await api.post("/global/project/info/id", {

      mail: localStorage.getItem("@MAIL"),
      project_id: id

    })

    const responseProjects = await api.post("global/platform/projects", {
      mail: localStorage.getItem("@MAIL"),
      global_id: globalId.data[0].global_id

    })


    setProjects(responseProjects.data)

    localStorage.setItem("@PROJECT_CHANGE_ID_FOLLOW_PAGE", changeToId)


    setTypeProject(response.data[0].type_project)
    setNameProjectChange(response.data[0].name)




  }
  const getItemsInCart = async () => {

    const responseInfoComponent = await api.post(`checklist/count/items`, {
      mail: localStorage.getItem("@MAIL"),
      project_id: id
    })

    setItemsInCart(responseInfoComponent.data.total)


  }
  const redirectToProjectSelect = (id, type) => {

    window.location.href = `/follow/?id=${id}`
  }

  useEffect(() => {

    getInfoProject()
    getCustomComponents()
    getItemsInCart()

  }, [])
  useEffect(() => {

    api.post(`checklist/component`, {

      "project_id": id,
      "mail": localStorage.getItem("@MAIL"),
    }).then((response) => {

      if (response.status == 400) {

        setItemsCart2([])
        return false
      }

      setItemsCart2(response.data)



    })


  }, [])



  const sidebar4 = {
    position: "fixed",
    top: "0",
    width: "345px",
    right: 0,
    overflowY: "hidden",
    overflowX: "hidden",
    height: "100vh",
    paddingTop: "5rem",
    paddingBottom: "2.4rem",
    borderRight: "1px solid #D7D7D7",
    borderLeft: "1px solid #D7D7D7",

  }
  const headerColumn4 = {

    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #D7D7D7",
    paddingLeft: "1rem",
    height: "4.6rem",
    marginBottom: "1.188rem",


  }
  const typeOption = {

    border: "1px solid #D7D7D7",
    width: "18.5rem",
    display: "flex",
    aligItems: "center",
    paddingLeft: "0.53rem",
    height: "57px",
    borderRadius: "10px",
    marginBottom: "1.188rem",

  }
  const listProjects = {

    border: "1px solid #D7D7D7",
    width: "14.25rem",
    height: "auto",


  }
  const infoProject = {
    zIndex: "1",
    borderBottom: "1px solid #D7D7D7",
    width: "14.25rem",
    cursor: "pointer",
    display: "flex",
    aligItems: "center",
    paddingLeft: "0.53rem",
    marginBottom: "1.188rem",
  }
  const containerText = {

    display: "flex",
    aligItems: "center",
    justifyContent: "space-between",

    borderBottom: "1px solid #D7D7D7",
    paddingLeft: "1rem",
    height: "2.5rem",
  }
  const numberFeatures = {

    display: "flex",
    paddingTop: "0.2rem",
    justifyContent: "center",
    aligItems: "center",
    background: "#1172EB",
    width: "3.125rem",
    height: "28px",
    borderRadius: "15px",
    color: "white"
  }
  const containerFunctionalities = {

    display: "flex",
    paddingBottom:"3rem",
    overflowY:"auto",
    overflowX:"hidden",
    position: "relative",
    height:"80vh",
    width:"99%",
    position: "relative",
    flexDirection: "column",
    aligItems: "center",
  }

  const containerBtnAdd = {
    width: "100%",
    position:"absolute",
    bottom:"0",
    height:"90px",
    borderTop: "1px solid ##D7D7D7",
    display: "flex",
    paddingTop: "1rem",
    marginTop: "1rem",
    justifyContent: "center",
  }

  const btnAddNewComponent = {
    borderRadius: "60px",
    background: "#1172EB",
    width: "240px",
    height: "40px",
    border: "none",
    bottom: "0"
  }

  const bottomCotainerNewComponent = {
    display:"flex",
    background:"white",
    justifyContent:"center",
    alignItems:"center",
    height:"76px",
    borderTop:"1px solid #D7D7D7",
    width:"100%"
  }


  return (

    <div style={sidebar4} >
      <div style={headerColumn4}>

        <div class="dropdown">


          <div style={typeOption} data-toggle="dropdown" >

            {typeProjectChange == undefined

              ?
              typeProject == "mobile" ? <img style={{ width: "30px" }} src={mobileIconSidebar} /> : <img src={weMobileIcon} style={{ width: "30px" }} />
              :
              typeProjectChange == "mobile" ? <img style={{ width: "30px" }} src={mobileIconSidebar} /> : <img src={weMobileIcon} style={{ width: "30px" }} />
            }


            <div>
              {typeProjectChange == undefined

                ?
                <p className="textTypeOptionSidebar" style={{ marginLeft: "1.125rem", marginTop: "0.438rem" }}></p>

                :
                <p className="textTypeOptionSidebar" style={{ marginLeft: "1.125rem", marginTop: "0.438rem" }}></p>

              }

              <div style={{ display: "flex", alignItems: "center", height: "30px", marginTop: "-0.563rem" }}>

                <div style={{ position: "relative", top: "6px", left: "15px" }}>



                  <input className="textTypeOptionProject" onBlur={() => { updateNameProject() }} onChange={(e) => setNewNameProject(e.target.value)} style={{ border: "none", outline: "none", position: "relative", width: "205px" }} maxLength={35} type="text" value={newNameProject != undefined ? newNameProject : nameProjectChange} />




                </div>

              </div>

            </div>
            <div style={{ marginTop: "0.663rem", position: "absolute", right: "15px" }}>

              <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.56565 7.50066L11.9814 1.6605C12.1067 1.52542 12.1758 1.3451 12.1758 1.15284C12.1758 0.960576 12.1067 0.780261 11.9814 0.645185L11.5826 0.215097C11.3228 -0.0647638 10.9005 -0.0647638 10.6411 0.215097L6.0933 5.11923L1.54047 0.209655C1.41512 0.0745794 1.248 -5.01267e-07 1.06981 -5.09441e-07C0.891412 -5.17624e-07 0.724298 0.0745794 0.59884 0.209655L0.200202 0.639743C0.0748424 0.774926 0.00578113 0.955134 0.00578113 1.1474C0.00578112 1.33966 0.0748424 1.51998 0.200202 1.65505L5.62085 7.50066C5.74661 7.63606 5.91451 7.71042 6.09301 7.71C6.27219 7.71042 6.44 7.63606 6.56565 7.50066Z" fill="#23262F" />
              </svg>
            </div>


          </div>


          <div style={listProjects} class="dropdown-menu" aria-labelledby="dropdownMenuButton">

            {projects.map(item => {



              if (item.name !== nameProjectChange) {

                return (

                  <div style={infoProject} onClick={() => redirectToProjectSelect(item.project_id, item.type_project)}>
                    {item.type_project == "mobile" ? <img style={{ width: "30px" }} src={mobileIconSidebar} /> : <img src={weMobileIcon} style={{ width: "30px" }} />}

                    <div>

                      <div style={{ display: "flex", alignItems: "center", height: "60px" }}>
                        <p className="textTypeOptionProject" style={{ marginLeft: "1rem", position: "relative", top: "7px" }}>{item.name != undefined ? item.name : <></>}</p>
                      </div>
                    </div>


                  </div>

                )
              }




            })}


          </div>

        </div>


      </div>

      <div style={containerText}>

        <p className="textFunctionalities">Funcionalidades</p>

        <div style={{ marginRight: "0.875rem", marginLeft: "0.938rem" }}>

          <div style={numberFeatures}>
            <p className="textNumberFeatures">{itemsInCart}</p>
          </div>

        </div>

      </div>


      <div style={containerFunctionalities} >

        {itemsCart2.map(item => {
          return (
            <div key={item.id}>
              <PreviewFunctionalitieCheckList project_id={item.project_id} checkFollowUp={item.checkFollowUp} custom={item.custom} checked={item.checkFollowUp} globalId={item.global_id} id={item.componentSelect} />
            </div>
          )

        })}

        {customComponents.map(item => {
          return (

            <PreviewCustomFunctionalitie name={item.name} id={item.id} />
          )

        })}

      </div>
      <div style={containerBtnAdd}>

        <div style={bottomCotainerNewComponent}>

        <button className='textAddNewFeature' onClick={() => redirectToUpdatePage()} style={btnAddNewComponent}>Nova funcionalidade</button>
        </div>

        </div>
    </div>

  )

}

export default SidebarCheckbox