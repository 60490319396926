import { useContext, useEffect, useState } from "react"
import eyeIcon from "../../../images/Svg/eyeIcon.svg"
import trashIcon from "../../../images/Svg/trashIcon.svg"
import addIconContent from "../../../images/Svg/addIconContent.svg"
import checkOn from '../../../images/Svg/checkOn.svg'
import ModalContainer from "./ModalContainer"
import ModalViewDetailsFeature from "./ModalViewDetailsFeature"
import { ComponentContext } from "../../../context/component"
import { CartContext } from "../../../context/cart"
import api from "../../../services/api"
var lz = require('lz-string');

const ContentSelectionSection = ({ name, time, price, icon, picture, description, id, category }) => {
    const [select, setSelect] = useState(false)
    const [modal, setModal] = useState(false)
    const [imageDecompress, setImageDecompress] = useState()
    const { addItemToCart, removeItemCart} = useContext(CartContext)
    const { loadImage} = useContext(ComponentContext)

          
    const urlParams = new URLSearchParams(window.location.search);
    const idProject = urlParams.get("id")


    useEffect(()=>{

        
    const getItemsInCart = async () => {
                
        
        const response = await api.post("cart/simple/user", {
            
            "mail": localStorage.getItem("@MAIL"),
            "id": idProject,
        })

        if (response.status != 200) {
            
            return false
            
        }
        
        if (response.status == 200) {

            response.data.map(item => {
                if(item.componentSelect == id){
                    setSelect(true)
                }
            })

            localStorage.setItem("@TOTAL_FEATURES", response.data.length)


        }
        
        
    }

    getItemsInCart()

    },[])
    
    const onAddedFeature = (idItem, category) => {
        setSelect(!select)
        if (!select) {
          return  addItemToCart(idItem, category, name, description,idProject)
        } else {
            return removeItemCart(idItem, category)
        }

    }

    function showImage(idImage) {

        loadImage(idImage, description, price)

        setModal(true)
    }

    useEffect(() => {

        const image = lz.decompressFromUTF16(icon)

        setImageDecompress(image)

    }, [picture])
    

    const boxContent = {
        paddingRight:"20px",
        borderBottom: "1px solid #E5E5E5",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#F9F9FA"
    }

    const content = {
        display: "flex",
        gap: "24px",
    }

    const topContent = {
        display: "flex",
        flexDirection: "column",
        gap: "10px"
    }

    const boxDetails = {
        display: "flex",
        alignItems: "center",
        gap: "7px"
    }

    return (
        <>
            <div style={boxContent}>

            <div style={{width:"98%",padding: "17px 24px 17px 21px"}} 
             onClick={() => { showImage(id, description) }}
            >

            <div style={content}>
                    {imageDecompress == undefined 
                    
                    ?
                    <div class="ssc-line" style={{width:"21px",height:"21px",borderRadius:"2px"}}></div>

                    :
                    <img style={{ width: "21px", height: "21px" }} src={select ? checkOn : "data:image/png;base64," + imageDecompress} />

                    }
                    <div style={topContent}>
                        <p className="textSelectFeatSection">{name}</p>
                        <div style={boxDetails}>
                            <p className="textSelectFeatSection">{time}</p>
                            <p className="textSelectFeatSection">{price}</p>
                        </div>
                    </div>
                </div>


                    
                </div>
                
                <div style={{ display: "flex", gap: "9px" }}>
                
                    <img src={select ? trashIcon : addIconContent} onClick={() => onAddedFeature(id, category,idProject)} />
                </div>
                </div>



            <ModalContainer isActive={modal} closeModal={() => setModal(false)}>
                <ModalViewDetailsFeature closeModal={() => setModal(false)} />
            </ModalContainer>
        </>
    )
}

export default ContentSelectionSection