import mobileIcon from "../../../images/Svg/mobile.svg"
import webIcon from "../../../images/Svg/web.svg"
import checkOff from '../../../images/Svg/checkOff.svg'
import checkOn from '../../../images/Svg/checkOn.svg'
import CardFunctionaLities from "./CardFunctionaLities"
import { useContext, useEffect, useState } from "react"
import api from "../../../services/api"
import CustomComponentGeneralPage from './CustomComponentGeneralPage'


const InfoProjectMobile = ({ id, project_id, name, type_project, onClick, desktop_icon, mobile_icon, setTotalLength }) => {

    const [check, setCheck] = useState(true)
    const [componentsCart, setComponentsCart] = useState([])
    const [items, setItems] = useState()
    const [daysToDevelop, setDaysToDevelop] = useState()
    const [totalProject, setTotalProject] = useState()
    const [customComponents, setCustomComponents] = useState([])


    const getInfo = async () => {

        const response = await api.post("/components/project", {

            "project_id": project_id,


        })


        setComponentsCart(response.data)


        const responseItemsInCart = await api.post("cart/value", {

            "project_id": project_id,
            "mail": localStorage.getItem("@MAIL")


        })

        setItems(responseItemsInCart.data.items)
        setDaysToDevelop(responseItemsInCart.data.developTime)
        setTotalProject(responseItemsInCart.data.price)
    }
    const getCustomComponents = async () => {
        const response = await api.get(`custom/project/component/${project_id}`)

        setCustomComponents(response.data)


    }

    useEffect(() => {

        getInfo()
        getCustomComponents()

    }, [])

    if (items == 0 && componentsCart.length == 0) {
        return setTotalLength(true)
    }

    if (items == 0) {
        return <></>
    }

    return (
        <div style={{
            border: "1px solid #D7D7D7",
            borderRadius: "15px",
            minWidth: "86.5vw",
            height: "601px"
        }}>

            <div style={{
                borderBottom: "1px solid #D7D7D7",
                padding: "24px 19px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "17px"
                }}>
                    <img src={type_project == "mobile" ? mobileIcon : webIcon} />
                    <p className="textTitleCardTotalProject">{name}</p>
                </div>

                <img onClick={() => setCheck(!check)} src={check ? checkOn : checkOff} />

            </div>

            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "8px 19px",
                borderBottom: "1px solid #D7D7D7",
            }}>
                <h3 className="textValueTitleFuncionalidades">Funcionalidades</h3>
                <p className="textValueFunctionaLities">{componentsCart.length}</p>
            </div>


            <div style={{
                height: "402px",
                overflowY: "scroll"
            }}>
                {
                    componentsCart.map(item => {
                        return (
                            <>
                                <CardFunctionaLities general={true} picture={type_project == "mobile" ? item[0].mobile_icon : item[0].desktop_icon} name={item[0].name} type_project={type_project} time={item[0].develop_time} priceData={item[0].price} />
                            </>
                        )
                    })
                }

                
      {componentsCart.length > 0 
      
      ?

      customComponents.map(item => {
        return(

          <CustomComponentGeneralPage type_project={type_project}  name={item.name} id={item.id}/>
        )

      })
      
      :
      <></>

      }
            </div>

            <div style={{
                display: "flex",
                padding: "17px 24px",
                alignItems: "center",
                justifyContent: "center",
                borderTop: "1px solid #D7D7D7",
            }}>


                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                    paddingRight: "29px",
                    borderRight: "1px solid #D7D7D7",
                }}>
                    <p className="textTitleTotal">Custo total</p>
                    <p className="textValueTotal">  { totalProject != undefined ?  Number(totalProject).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                    })
                
                    :
                    <div class="ssc-line" style={{width:"81px",height:"21px",borderRadius:"2px"}}></div>

                }</p>
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                    paddingLeft: "29px"
                }}>
                    <p className="textTitleTotal">Desenvolvimento</p>
                    {daysToDevelop != undefined 
                    
                    ?
                        
                    <p className="textValueTotal">{daysToDevelop == "1" ? `${daysToDevelop} dia` : `${daysToDevelop} dias`}</p>
                    :
                    <div class="ssc-line" style={{width:"81px",height:"21px",borderRadius:"2px"}}></div>

                    }
                </div>
            </div>

        </div>
    )
}

export default InfoProjectMobile