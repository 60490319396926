import React, { useState } from 'react'
import InputMask from 'react-input-mask';

const Input = ({ name, type, placeholder, onChange, margin, onBlur, value, setvalue, error, mask, model }) => {
    const [stateEye, setStateEye] = useState(false)

    const inputBox = {
        border: "1px solid #D7D7D7",
        borderRadius: "7px",
        outline: "none",
        height: "45px",
        padding: "0 20px",
        margin: margin
    }

    const image = {
        position: "absolute",
        top: "50px",
        right: "10px",
        cursor: "pointer"
    }


    return type === "password" ? (
        <>
            <label className='textLabelInput' style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
                {name}
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #D7D7D7",
                    borderRadius: "7px",
                    height: "45px",
                    padding: "0 20px",
                    justifyContent: "space-between"
                }}>

                    <input onBlur={onBlur} style={{ outline: "none", border: "none" }} onChange={onChange} className='textLabelInput' type={stateEye ? "text" : "password"} placeholder={placeholder} />

                    {stateEye == false

                        ?
                        <svg onClick={() => setStateEye(!stateEye)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.2704 9.18005C20.9804 8.72005 20.6704 8.29005 20.3504 7.89005C19.9804 7.42005 19.2804 7.38005 18.8604 7.80005L15.8604 10.8001C16.0804 11.4601 16.1204 12.2201 15.9204 13.0101C15.5704 14.4201 14.4304 15.5601 13.0204 15.9101C12.2304 16.1101 11.4704 16.0701 10.8104 15.8501C10.8104 15.8501 9.38044 17.2801 8.35044 18.3101C7.85044 18.8101 8.01044 19.6901 8.68044 19.9501C9.75044 20.3601 10.8604 20.5701 12.0004 20.5701C13.7804 20.5701 15.5104 20.0501 17.0904 19.0801C18.7004 18.0801 20.1504 16.61 21.3204 14.74C22.2704 13.23 22.2204 10.6901 21.2704 9.18005Z" fill="#A4A4A4" />
                            <path d="M14.0196 9.98001L9.97965 14.02C9.46965 13.5 9.13965 12.78 9.13965 12C9.13965 10.43 10.4196 9.14001 11.9996 9.14001C12.7796 9.14001 13.4996 9.47001 14.0196 9.98001Z" fill="#A4A4A4" />
                            <path d="M18.25 5.75005L14.86 9.14005C14.13 8.40005 13.12 7.96005 12 7.96005C9.76 7.96005 7.96 9.77005 7.96 12.0001C7.96 13.1201 8.41 14.1301 9.14 14.8601L5.76 18.2501H5.75C4.64 17.3501 3.62 16.2001 2.75 14.8401C1.75 13.2701 1.75 10.7201 2.75 9.15005C3.91 7.33005 5.33 5.90005 6.91 4.92005C8.49 3.96005 10.22 3.43005 12 3.43005C14.23 3.43005 16.39 4.25005 18.25 5.75005Z" fill="#A4A4A4" />
                            <path d="M14.8601 12C14.8601 13.57 13.5801 14.86 12.0001 14.86C11.9401 14.86 11.8901 14.86 11.8301 14.84L14.8401 11.83C14.8601 11.89 14.8601 11.94 14.8601 12Z" fill="#A4A4A4" />
                            <path d="M21.7699 2.23C21.4699 1.93 20.9799 1.93 20.6799 2.23L2.22988 20.69C1.92988 20.99 1.92988 21.48 2.22988 21.78C2.37988 21.92 2.56988 22 2.76988 22C2.96988 22 3.15988 21.92 3.30988 21.77L21.7699 3.31C22.0799 3.01 22.0799 2.53 21.7699 2.23Z" fill="#A4A4A4" />
                        </svg>

                        :
                        <svg onClick={() => setStateEye(!stateEye)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.2704 9.18005C20.9804 8.72005 20.6704 8.29005 20.3504 7.89005C19.9804 7.42005 19.2804 7.38005 18.8604 7.80005L15.8604 10.8001C16.0804 11.4601 16.1204 12.2201 15.9204 13.0101C15.5704 14.4201 14.4304 15.5601 13.0204 15.9101C12.2304 16.1101 11.4704 16.0701 10.8104 15.8501C10.8104 15.8501 9.38044 17.2801 8.35044 18.3101C7.85044 18.8101 8.01044 19.6901 8.68044 19.9501C9.75044 20.3601 10.8604 20.5701 12.0004 20.5701C13.7804 20.5701 15.5104 20.0501 17.0904 19.0801C18.7004 18.0801 20.1504 16.6101 21.3204 14.74C22.2704 13.2301 22.2204 10.6901 21.2704 9.18005Z" fill="#A4A4A4" />
                            <path d="M14.0196 9.98001L9.97965 14.02C9.46965 13.5 9.13965 12.78 9.13965 12C9.13965 10.43 10.4196 9.14001 11.9996 9.14001C12.7796 9.14001 13.4996 9.47001 14.0196 9.98001Z" fill="#A4A4A4" />
                            <path d="M18.25 5.75005L14.86 9.14005C14.13 8.40005 13.12 7.96005 12 7.96005C9.76 7.96005 7.96 9.77005 7.96 12.0001C7.96 13.1201 8.41 14.1301 9.14 14.8601L5.76 18.2501H5.75C4.64 17.3501 3.62 16.2001 2.75 14.8401C1.75 13.2701 1.75 10.7201 2.75 9.15005C3.91 7.33005 5.33 5.90005 6.91 4.92005C8.49 3.96005 10.22 3.43005 12 3.43005C14.23 3.43005 16.39 4.25005 18.25 5.75005Z" fill="#A4A4A4" />
                            <path d="M14.8601 12C14.8601 13.57 13.5801 14.86 12.0001 14.86C11.9401 14.86 11.8901 14.86 11.8301 14.84L14.8401 11.83C14.8601 11.89 14.8601 11.94 14.8601 12Z" fill="#A4A4A4" />
                        </svg>

                    }
                </div>
            </label>
        </>
    ) :
        (
            mask ?
                <>
                    <label className='textLabelInput' style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
                        {name}
                        <InputMask mask={model}  maskChar={false} value={value} onChange={onChange} onBlur={onBlur} className='textLabelInput' style={{ ...inputBox, border: `1px solid ${error ? error : "#D7D7D7"}` }} type={type} placeholder={placeholder} />
                    </label>
                </>
                :
                <>
                    <label className='textLabelInput' style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
                        {name}
                        <input value={value} onChange={onChange} onBlur={onBlur} className='textLabelInput' style={{ ...inputBox, border: `1px solid ${error ? error : "#D7D7D7"}` }} type={type} placeholder={placeholder} />
                    </label>
                </>
        )

}

export default Input