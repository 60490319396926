import checkOff from '../../../images/Svg/checkOff.svg'
import checkOn from '../../../images/Svg/checkOn.svg'

const TermsFooter = ({ checkTerms, setCheckTerms, href }) => {
    const containerTerms = {
        display: "flex",
        gap: "21px",
        flexDirection: "column",
        marginTop: "36px"
    }

    const boxTerms = {
        display: "flex",
        gap: "20px",
        alignItems: "center"
    }

    const boxDescriptionTerms = {
        display: "flex",
        gap: "10px",
        flexDirection: "column"
    }

    return (
        <div style={containerTerms}>
            <div style={boxTerms}>
                <div style={{ cursor: "pointer" }} onClick={() => setCheckTerms(!checkTerms)}>
                    <img src={checkTerms ? checkOn : checkOff} />
                </div>

                <div style={boxDescriptionTerms}>
                    <p className='textDescriptionTermsRegister'>Ao clicar neste botão, eu concordo com os termos de uso da Startpn tech LTDA, <span onClick={() => window.location.href = href} className='textTermsDescription'>Termos de uso</span></p>
                </div>
            </div>
        </div>
    )
}

export default TermsFooter