import { useEffect, useRef, useState } from "react";
import Select from "./Select";
import checkOff from "../../../images/Svg/checkOff.svg";
import checkOn from "../../../images/Svg/checkOn.svg";
import "skeleton-screen-css";
import "react-toastify/dist/ReactToastify.css";
import { ModalTermsMobile } from "./ModalTermsMobile";
import moment from "moment";
import momentBussines from "moment-business-days";
const ResumeTotal = ({
  date,
  instalment,
  onClick,
  setModalState,
  mail,
  document_key,
  state,
  city,
  district,
  status,
  dueDate,
  cep,
  monthToDevelop,
  name,
  address,
  cpf,
  complement,
  number,
  requestSignatureKey
}) => {
  const valueProject = useRef(
    localStorage.getItem("@TOTAL_VALUE_PROJECT")
  ).current;

  const [scalePrice, setScalePrice] = useState(500);
  const [dueDateMobile,setDueDateMobile] = useState()

  const [installmentList, setInstallmentList] = useState([]);

  const [selectValue, setSelectValue] = useState();
  const [check, setCheck] = useState();

  const onCheckTerms = () => {
    setCheck(!check)
  };

 
  const getInfoProject = async () => {
    if (localStorage.getItem("@TOTAL_VALUE_PROJECT") == 0) {
      window.location.href = "/functionalities";
      return false;
    }

    const days = localStorage.getItem("@DEVELOPT_TIME_PROJECT");

    const months = days / 30.417;

    const dueDay = moment().add(days, "days").format("DD/MM/YYYY");

    momentBussines.updateLocale("us", {
      workingWeekdays: [1, 2, 3, 4, 5],
    });

    // Defines days from 1 (Monday) to 6 (Saturday) as business days. Note that Sunday is day 0.
    // When omitting this configuration parameter, business days are based on locale default

    const businessDueDay = moment()
      .businessAdd(localStorage.getItem("@DEVELOPT_TIME_PROJECT"))
      .format("DD/MM/YYYY");
      

      setDueDateMobile(businessDueDay);

    localStorage.setItem("@DUE_DAY_MOBILE",dueDate)

  };

  useEffect(() => {
    if (status) {
      setCheck(true)
    } else if (!status) {
      setCheck(false)
    }
  }, [status])

  useEffect(() => {
    if (check) {
      onClick();
    }
  }, [check]);

  useEffect(() => {
    const totalInstallment = (value) => {
      const list = [];

      for (let index = 1; index <= 8; index++) {
        const sum = value / index;

        if (index == 1) {
          list.push(
            `${index}x de ${Number(sum).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })} sem juros`
          );
        } else if (index !== 1) {
          list.push(
            `${index}x de ${Number(sum).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })} sem juros`
          );
        }
      } 

      setInstallmentList(list);
      setSelectValue(list[0]);
    };

    totalInstallment(localStorage.getItem("@TOTAL_VALUE_PROJECT"));
  }, []);

  useEffect(() =>{

    getInfoProject()

  },[])

  return (
    <div
      style={{
        padding: "24px",
        marginBottom: "100px",
      }}
    >
      <div
        style={{
          border: "1px solid #D7D7D7",
          borderRadius: "15px",
          padding: "23px 22px",
        }}
      >
        <h3 className="textTitleFormPayment">Resumo</h3>

        <div
          style={{
            marginTop: "15px",
          }}
        >
          <Select
            page="payment"
            top={"60px"}
            unique={true}
            type={"payment"}
            value={selectValue}
            setValue={setSelectValue}
            list={installmentList}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "28px",
              marginTop: "24px",
              paddingBottom: "28px",
              borderBottom: "1px solid #D7D7D7",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="textTitleCardResumeTotal">Valor</p>
              <p className="textContentCardResumeTotal">
                {Number(valueProject).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="textTitleCardResumeTotal">Startpn Scale</p>
              <p className="textContentCardResumeTotal">
                {localStorage.getItem("@SCALE_SERVICE_ENABLE") == "false"
                  ? "R$ 0,00"
                  : `R$ ${scalePrice},00`}
              </p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="textTitleCardResumeTotal">Forma de pagamento</p>
              <p className="textContentCardResumeTotal">{"Boleto"}</p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="textTitleCardResumeTotal">
                Tempo de desenvolvimento
              </p>
              <p className="textContentCardResumeTotal">
                {Number(localStorage.getItem("@DEVELOPT_TIME_PROJECT")) == 1
                  ? "1 dia útil"
                  : `${localStorage.getItem(
                    "@DEVELOPT_TIME_PROJECT"
                  )} dias úteis`}
              </p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="textTitleCardResumeTotal">Data de entrega final</p>
              <p className="textContentCardResumeTotal">{dueDateMobile}</p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "22px",
            }}
          >
            <p className="textTitleCardResumeTotalFooter">Custo total</p>
            <p className="textContentCardResumeTotalFooter">
              {Number(valueProject).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "16px",
              marginTop: "28px",
            }}
          >
            <img
              onClick={() => onCheckTerms()}
              src={check ? checkOn : checkOff}
            />
            <p className="textContractTextFooter">
              Marque aqui para assinar nosso contrato digitalmente.
            </p>
          </div>

          <ModalTermsMobile
            payment={selectValue}
            instalment={instalment}
            onClick={() => setModalState(false)}
            mail={mail}
            document_key={document_key}
            state={state}
            city={city}
            district={district}
            request_signature_key={requestSignatureKey}
            status={status}
            dueDate={dueDateMobile}
            cep={cep}
            monthToDevelop={monthToDevelop}
            name={name}
            address={address}
            cpf={cpf}
            complement={complement}
            number={number}
          />
        </div>
      </div>
    </div>
  );
};

export default ResumeTotal;
