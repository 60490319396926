import React from 'react'
import Sidebar from "../../components/Sidebar";
import HeaderGeneralDefinition from '../../components/HeaderGeneralDefinition';
const ThreeColumLayoutGeneralPage = ({ colum2Data, colum3Data, colum4Data, page, typeHeader }) => {

    const container_three_column = {
        'display': 'grid',
        'gridTemplateColumns': '1fr 1fr 1fr',
    }

    const column1 = {

        gridColumnStart: '1',
        gridColumnEnd: '2',
        gridRowStart: '1',
        gridRowEnd: '1'
    }

    const column2 = {
        gridColumnStart: '1',
        gridColumnEnd: '4',
        paddingLeft: "9rem",
    }
    const column3 = {
        gridColumnStart: '1',
        gridColumnEnd: '4',
    }

    return (
        <div style={container_three_column}>

            <HeaderGeneralDefinition type={typeHeader} />

            <div style={column1}>
                <Sidebar page={page} />
            </div>{/* End column1*/}


            <div style={column2}>

                {colum2Data}

            </div>{/* End column2 */}


            <div style={column3}>

                {colum3Data}

            </div>{/* End column2 */}



        </div>
    )

}

export default ThreeColumLayoutGeneralPage