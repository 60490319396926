import React, { useState } from 'react'
import closeEyeIcon from '../../images/Svg/closeEyeIcon.svg'
import openEyeIcon from '../../images/Svg/openEyeIcon.svg'

const InputLogin = ({ name, placeholder, type, onChange, error, marginBottom }) => {
    const [stateEye, setStateEye] = useState(false)

    const input = {

        background: "#ebebeb",
        border: "none",
        width: "432px",
        height: "48px",
        borderRadius: "60px",
        outline: "none",
        paddingLeft: "30px",
        marginBottom:  marginBottom != undefined && error == undefined ? marginBottom :"30px"

    }

    const column1 = {

        position: "relative",
        display: "flex",
        flexDirection: "column"

    }


    return (

        <div style={column1}>
            <label style={{ margin: marginBottom }} className="labelInputLogin">{name}</label>
            {
                type == "password" ?
                    <div style={{
                        background: "#ebebeb",
                        border: "none",
                        width: "432px",
                        height: "48px",
                        borderRadius: "60px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0 30px",
                        gap: "10px",
                        marginBottom: marginBottom
                    }}>
                        <input onChange={onChange} type={stateEye ? "text" : "password"} style={{ outline: "none", border: "none", backgroundColor: "#ebebeb", width: "100%" }} className="inputLogin" />
                        {stateEye == false

                            ?
                            <img src={closeEyeIcon} onClick={() => setStateEye(!stateEye)} style={{ cursor: "pointer" }} />
                            :
                            <img src={openEyeIcon} onClick={() => setStateEye(!stateEye)} style={{ cursor: "pointer" }} />

                        }

                    </div>
                    :
                    <input className="inputLogin" onChange={onChange} type={type} placeholder={placeholder} style={input} />

            }
            {error && type !== "password" && <p className="errorMessageLogin">{error}</p>}
            {error && type === "password" && <p style={{transform: "translateY(30px)"}} className="errorMessageLogin">{error}</p>}
        </div>
    )

}

export default InputLogin