import React,{useContext,useEffect, useState} from 'react'

import {CitysContext} from '../../context/citys'


const SelectPaymentCitys = ({name,data,error}) => {

    const {citys,getCity} = useContext(CitysContext)
    const [dataFromApi,setDataFromApi] = useState(true)

    const input = {

        width:"291px",
        height:"45px",
        borderRadius:"7px",
        border:"1px solid #D7D7D7",
        outline:"none",
        paddingLeft:"0.813rem",
        marginRight:"20px"
  
      }

      const getTheCity = (e) => {

        getCity(e)

      }


    return(
        
        
        <div style={{display:"flex",flexDirection:"column",marginBottom:"1.688rem"}}>
        <label>{name}</label>


        {data != undefined 
        
        ?
    
        <div style={{position:"relative"}}>

        <select style={input} onChange={(e) => getTheCity(e.target.value) }>
        <option value={data}>{data}</option>

        {citys.map(item => {

            return(

                <option value={item.Nome}>{item.Nome}</option>

            )

        })}

        </select>
        {error != undefined 
        
        ?
        <p style={{position:"absolute",color:"red",top:"46px"}}>{error}</p>
        :
        <></>
        }
        </div>
        :

        <div style={{position:"relative"}}>

        <select style={input}>
        <option>Selecione</option>

        {citys.map(item => {

            return(

                <option value={item.Nome}>{item.Nome}</option>

            )

        })}

        </select>

        {error != undefined 
        
        ?
        <p style={{position:"absolute",color:"red",top:"46px"}}>{error}</p>
        :
        <></>
        }
        </div>

        }

    
        


     
        


        </div>
    )

}
export default SelectPaymentCitys