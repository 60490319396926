import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals'
import "react-toastify/dist/ReactToastify.css";
import AppStack from './routes/AppStack'
import CartProvider from "./context/cart"
import ComponentProvider from "./context/component"
import VelocityProvider from "./context/velocity"
import CitysProvider from "./context/citys"
import DashboardProvider from "./context/dashboard"

import PlatFormProvider from "./context/platform"
import { ToastContainer } from 'react-toastify';
import PaymentProvider from './context/payment';
import TermsProvider from './context/terms';
import ModalProvider from './context/modal';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ModalProvider>
    <TermsProvider>
      <PaymentProvider>
        <PlatFormProvider>
          <DashboardProvider>
            <CitysProvider>
              <VelocityProvider>
                <ComponentProvider>
                  <CartProvider>
                    <AppStack />

                  </CartProvider>
                </ComponentProvider>
              </VelocityProvider>
            </CitysProvider>
          </DashboardProvider>
          <ToastContainer />
        </PlatFormProvider>
      </ PaymentProvider>
    </TermsProvider>
    </ModalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
