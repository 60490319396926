import { useContext, useEffect, useState } from "react"
import closeIcon from "../../../images/Svg/closeIcon.svg"
import CardFunctionaLities from "./CardFunctionaLities"
import api from "../../../services/api"
import { CartContext } from "../../../context/cart"
import CardCustomFunctionaLities from "./CardCustomFunctionaLities"

import SelectModalCartFunctionalities from './SelectModalCartFunctionalities'

const ModalCartFunctionalities = ({ closeModal, cart, list, value, id }) => {
    const [setProjectName] = useState(value?.name || localStorage.getItem("@NAME_PROJECT"))
    const { itemsCart2 } = useContext(CartContext)
    const [listProjects, setListProjects] = useState([])

    const [nameProject, setNameProject] = useState()
    const [typeProject, setTypeProject] = useState(localStorage.getItem("@TYPE_PROJECT"))
    const [customComponents, setCustomComponents] = useState([])

    const { developTime, price } = useContext(CartContext)


    const urlParams = new URLSearchParams(window.location.search);
    const idProject = urlParams.get("id")


    const getCustomComponents = async () => {
        const projectChange = localStorage.getItem("@PROJECT_CHANGE_ID")

        const response = await api.get(`custom/project/component/${projectChange}`)


        setCustomComponents(response.data)
    }

    const getInfoProject = async (changeToId) => {

        if (changeToId != undefined) {

            const response = await api.post("project/info", {

                mail: localStorage.getItem("@MAIL"),
                project_id: changeToId
            })

            setTypeProject(response.data[0].type_project)
            setNameProject(response.data[0].name)

            localStorage.setItem("@PROJECT_CHANGE_ID2", changeToId)
            window.location.href = "/functionalities"

            return false

        }

        const response = await api.post("project/info", {

            mail: localStorage.getItem("@MAIL"),
            project_id: idProject
        })

        setTypeProject(response.data[0].type_project)
        setNameProject(response.data[0].name)
    }

    useEffect(() => {
        getInfoProject()
        getCustomComponents()

    }, [listProjects])


    return (
        <>
            <div style={{
                border: "1px solid #D7D7D7",
                borderRadius: !cart && "15px",
                width: "100%",
                height: "100%",
                backgroundColor: "#fff"
            }}>

                <div style={{
                    padding: "22px 23px 15px",
                    borderBottom: "1px solid #E5E5E5",
                    display: "flex",
                    alignContent: "center",
                    gap: "17px"
                }}>
                    <img src={closeIcon} onClick={closeModal} />

                    <h3 className="textTitleModalViewDetailsFeature">Funcionalidades</h3>
                </div>
                <div style={{
                    padding: "15px 23px",
                    borderBottom: "1px solid #E5E5E5",
                }}>
                    <SelectModalCartFunctionalities icon={true} top={"48px"} unique={true} name={""} value={nameProject} typeProject={typeProject} setValue={setProjectName} list={listProjects} />

                </div>

                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    height: "73%",
                }}>

                    <div style={{
                        overflowY: "scroll"
                    }}>

                        {
                            itemsCart2.map(item => {
                                return (

                                    <CardFunctionaLities cart={true} category={item.category} type={item.type} project_id={item.project_id} id={item.componentSelect} idToRemove={item.id} name={item.name} />
                                )
                            })
                        }

                        {customComponents.map(item => {

                            return (
                                <CardCustomFunctionaLities name={item.name} id={item.id} description={item.description} />

                            )

                        })}
                    </div>

                    {!cart && (
                        <div style={{
                            display: "flex",
                            padding: "17px 24px",
                            alignItems: "center",
                            justifyContent: "center",
                            borderTop: "1px solid #D7D7D7",
                        }}>

                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "6px",
                                paddingRight: "29px",
                                borderRight: "1px solid #D7D7D7",
                            }}>
                                <p className="textTitleTotal">Custo total</p>
                                <p className="textValueTotal">R$ {price}</p>
                            </div>

                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "6px",
                                paddingLeft: "29px"
                            }}>
                                <p className="textTitleTotal">Desenvolvimento</p>
                                <p className="textValueTotal">{developTime == "1" ? `${developTime} dia útil` : `${developTime} dias úteis`}</p>
                            </div>
                        </div>
                    )}


                </div>

            </div>
        </>
    )


}

export default ModalCartFunctionalities