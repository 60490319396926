import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom'

import ThowColumnLayout from '../../layouts/ThowColumnLayout'
import api from '../../services/api'
import iconVerify from '../../images/Svg/verificationData.svg'

const VerificationData = () => {

    const navigate = useNavigate()
    


    return(
      <ThowColumnLayout
      typeHeader={"verification"}
      colum2Data={
        
        <div>


        
        <div style={{display:"flex",justifyContent:"center",marginTop:"0.27rem",width:"100%",height:"520px"}}>
            
            <img src={iconVerify} style={{width:"520px"}}/>
        </div>
        
        </div>

      }

      
      
      />

    )

}

export default VerificationData