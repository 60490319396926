import { useContext, useEffect, useState } from "react";
import CardPaymentDetails from "../components/CardPaymentDetails";
import HeaderProfile from "../components/HeaderProfile";
import ResumeTotal from "../components/ResumeTotal";
import { toast } from "react-toastify";
import { PaymentContext } from "../../../context/payment";
import api from "../../../services/api";
import Modal from "react-bootstrap/Modal";

const PaymentAndContractMobile = () => {
  const { formData } = useContext(PaymentContext)

  /* INFO PROJECT */
  const [monthToDevelop, setMonthToDevelop] = useState();
  const [modalState, setModalState] = useState(false);

  /* ANIMATION */
  const [animation, setAnimation] = useState(false)

  /* request_signature_key */
  const [requestSignatureKey, setRequestSignatureKey] = useState()

  /*  document_key */
  const [document_key,setDocumentKey] = useState()

  const [instalment, setInstalment] = useState(
    `1x ${Number(localStorage.getItem("@TOTAL_VALUE_PROJECT")).toLocaleString(
      "pt-BR",
      { style: "currency", currency: "BRL" }
    )}`
  );

  const onSubmitForm = async () => {


    if (!formData.mail || !formData.name || !formData.userCpf
      || !formData.cep || !formData.userAddress || !formData.number
      || !formData.ufState || !formData.cityState
    ) {

      return toast.error("Verifique se todos os campos foram preenchidos", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,

        progress: undefined,
        theme: "light",
      });

    } else {

      setAnimation(true)



      const response = await api.post("terms/storage", {
        "name": formData.name,
        "cpf": formData.userCpf,
        "number": formData.number,
        "address": formData.userAddress,
        "mail": localStorage.getItem("@MAIL"),
        "mailToSenContract": formData.mail,
        "instalment": 1
      })


      if (response.status == 200) {
        setRequestSignatureKey(response.data.request_signature_key)
        setDocumentKey(response.data.document_key)
        setAnimation(false)
        setModalState(true)
        setModalState(true)

      }

    }


  }


  const container = {
    padding: "19px 0",
    backgroundColor: "#E5E5E520",
  };

  return (
    <div style={container}>
      <HeaderProfile />

      <Modal centered size="lg" show={animation} contentClassName="modal_ping">
        <Modal.Header style={{ border: "none", padding: 0, background: "none" }}>

          <div className="ping"></div>
        </Modal.Header>

      </Modal>

      <CardPaymentDetails />

      <ResumeTotal
        date={formData.dueDate}
        instalment={instalment}
        requestSignatureKey={requestSignatureKey}
        onClick={() => onSubmitForm()}
        mail={formData.mail}
        state={modalState}
        city={formData.cityState?.Nome}
        district={formData.userDistrict}
        document_key={document_key}
        status={modalState}
        setModalState={setModalState}
        dueDate={formData.dueDate}
        cep={formData.cep}
        monthToDevelop={monthToDevelop}
        name={formData.name}
        address={formData.userAddress}
        cpf={formData.userCpf}
        complement={formData.complement}
        number={formData.number}
      />
    </div>
  );
};

export default PaymentAndContractMobile;
