import React, { createContext, useState } from 'react'

import api from '../services/api'

export const ComponentContext = createContext({})

function ComponentProvider({ children }) {

    const [imageBase64, setImageBase64] = useState()
    const [name, setName] = useState()
    const [description, setDescription] = useState(false)

    const [animation, setAnimation] = useState(false)
    const [developTime, setDevelopTime] = useState()
    const [price, setPrice] = useState()


    const loadImage = async (imageBase64) => {

        setAnimation(true)

        const typeComponent = localStorage.getItem("@TYPE_PROJECT")

        if (typeComponent == "web") {

            const response = await api.get(`components/${imageBase64}`)

            const imageDesktop = response.data[0].desktop_icon


            setAnimation(false)
            setImageBase64(imageDesktop)
            setName(response.data[0].name)

            setDevelopTime(response.data[0].develop_time)
            setDescription(response.data[0].description)
            setPrice(response.data[0].price)


            return false
        }

        const response = await api.get(`components/${imageBase64}`)

        const imageMobile = response.data[0].mobile_icon

        setAnimation(false)
        setImageBase64(imageMobile)
        setName(response.data[0].name)

        setDevelopTime(response.data[0].develop_time)
        setDescription(response.data[0].description)
        setPrice(response.data[0].price)

    }
    const loadingImage = async (imageBase64, description) => {

        setAnimation(true)



        const response = await api.get(`component/${imageBase64}`)

        const typeComponent = localStorage.getItem("@TYPE_PROJECT")


        if (typeComponent == "web") {


            const response = await api.get(`component/${imageBase64}`)



            const imageDesktop = response.data[0].desktop_icon


            setAnimation(false)
            setImageBase64(imageDesktop)
            setName(response.data[0].name)

            setDevelopTime(response.data[0].develop_time)
            setDescription(response.data[0].description)
            setPrice(response.data[0].price)


            return false
        }


        const imageMobile = response.data[0].mobile_icon

        setAnimation(false)
        setImageBase64(imageMobile)
        setName(response.data[0].name)

        setDevelopTime(response.data[0].develop_time)
        setDescription(response.data[0].description)
        setPrice(response.data[0].price)







    }


    return (
        <ComponentContext.Provider value={{ loadImage, loadingImage, imageBase64, name, animation, description, developTime, price }}>
            {children}
        </ComponentContext.Provider>
    )

}

export default ComponentProvider