import trashIconFunctionalitis from "../../../images/Svg/trashIconFunctionalitis.svg"
import checkOn from "../../../images/Svg/checkOn.svg"
import api from "../../../services/api"
import { useContext, useEffect, useState } from "react"
import { CartContext } from "../../../context/cart"
import customComponent from '../../../images/Svg/customComponent.svg'
import customComponentWeb from '../../../images/Svg/customComponentWeb.svg'

const CustomComponentGeneralPage = ({ id, type, name,category, cart, time, priceData, picture, general, type_project}) => {
    const { removeItemCart } = useContext(CartContext)
    const [image, setImage] = useState()
    const [price, setPrice] = useState()

    const [daysToDevelop, setDaysToDevelop] = useState()
    const [nameComponent, setNameComponent] = useState()

    const getInfo = async () => {

        const response = await api.post(`components/simple/sidebar/`, {

            "type": localStorage.getItem("@TYPE_PROJECT"),
            "id": id

        })

        if (localStorage.getItem("@TYPE_PROJECT") == "web") {

            const imageDesktop = (response.data[0][0].desktop_icon)

            setImage(imageDesktop)


        } else {
            const imageMobile = (response.data[0][0].mobile_icon)
            setImage(imageMobile)

        }

        setNameComponent(response.data[0][0].name)
        setPrice(response.data[0][0].price)
        setDaysToDevelop(response.data[0][0].develop_time)
    }

    function deleteItemCart(idItem, category) {
        removeItemCart(idItem, category)
    }

    useEffect(() => {
        getInfo()
    }, [])



    return (
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "12px 23px",
            borderBottom: "1px solid #E5E5E5",
        }}>

            <div style={{
                display: "flex",
                gap: "20px",
                alignItems: "center"
            }}>


                {
                    type_project == "mobile" 
                    
                    ?
                    <img src={customComponent}/>
                    :
                    <img src={customComponentWeb}/>
                }

                <div style={{
                    display: "flex",
                    flexDirection: "column",    
                    gap: "14px"
                }}>
                    <p className="textSelectFeatSection" >{cart ? nameComponent : name}</p>

                    <div style={{
                        display: "flex",
                        gap: "5px"
                    }}>
                    </div>
                </div>
            </div>
            <img onClick={() => cart && deleteItemCart(id, category)} src={!cart ? checkOn : trashIconFunctionalitis} />
        </div>
    )
}

export default CustomComponentGeneralPage