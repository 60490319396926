import { useNavigate } from "react-router-dom";
import searchIcon from "../../../images/Svg/searchIcon.svg";

const HeaderSearch = () => {
  const navigate = useNavigate();

  const containerSearch = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 22px",
    marginTop: "26px",
  };

  const boxSearch = {
    padding: "10px",
    border: "1px solid #E8E8E8",
    borderRadius: "12px",
    backgroundColor: "#fff",
  };

  return (
    <div style={containerSearch}>
      <h3 className="textTitleFormSend">Projetos</h3>

      <div style={boxSearch} onClick={() => navigate("/search")}>
        <img src={searchIcon} />
      </div>
    </div>
  );
};

export default HeaderSearch;
