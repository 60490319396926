import BigHeads from "../../../components/BigHeads"
import api from "../../../services/api"
import { toast } from "react-toastify"

const UploadPictureProfile = ({ picture, id }) => {

    const getImages = (file) => {



        const types = ["image/png", "image/jpg"]

        if (file.type == types[0] || file.type == types[1]) {

            console.log("correct")

        } else {

            alert("Formato de arquivo inválido")
            return false

        }

        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async function () {

            localStorage.setItem("@USER_PICTURE", reader.result)


            await api.put("client/user/picture", {
                picture: reader.result,
                id: id
            })

            toast.success('Foto alterada com sucesso!', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,

                progress: undefined,
                theme: "light",
            });


            setTimeout(() => {
                window.location.reload()
            }, 1000);

        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };

    }

    return (
        <div style={{
            padding: "24px 25px"
        }}>
            <h3 className="textTitleProfilePage">Perfil</h3>

            <div style={{
                border: "1px solid #D7D7D7",
                borderRadius: "15px",
                padding: "16px 26px",
                marginTop: "24px"
            }}>
                <h4 className="textTitleCardProfile">Foto de perfil</h4>

                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "24px"
                }}>
                    {
                        picture ?
                            <img src={picture} style={{ width: "100px", height: "100px", borderRadius: "60px" }} />
                            :
                            <BigHeads width={"100px"} />
                    }

                    <label className="textPrimaryButton" htmlFor="file" style={{
                        marginTop: "30px",
                        cursor: "pointer",
                        backgroundColor: "var(--color-primary)",
                        width: "193px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid transparent",
                        borderRadius: "60px",
                        height: "43px"
                    }}>
                        Upload foto
                    </label>

                </div>
            </div>
            <input onChange={(e) => getImages(e.target.files[0])} accept='image/png' name="file" id="file" type="file" style={{ display: "none" }} />
        </div>
    )
}

export default UploadPictureProfile