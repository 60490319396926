import React, { useState, useEffect, useContext } from 'react'

import ThreeColumLayout from '../../layouts/ThreeColumLayout'
import mobileIconSidebar from '../../images/Svg/MobileIconSidebar.svg'
import weMobileIcon from '../../images/Svg/weMobileIcon.svg'
import Functionalitie from '../../components/Functionalitie'
import PreviewFunctionalitie from '../../components/PreviewFunctionalitie'
import PreviewCustomFunctionalitie from '../../components/PreviewCustomFunctionalitie'
import api from '../../services/api'
import ModalCreateProject from '../../components/ModalCreateProject'
import ModalCreateNewProject from '../../components/ModalCreateNewProject'
import ModalCreateCustomComponent from '../../components/ModalCreateCustomComponent'
import { CartContext } from '../../context/cart'
import { ComponentContext } from '../../context/component'
import "skeleton-screen-css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import SearchSubComponent from '../../components/SearchSubComponent'

const UpdateFeatures = () => {

  const [items, setItems] = useState([])

  const [image, setImage] = useState()
  const [nameComponent, setNameComponent] = useState()
  const [priceComponent, setPriceComponent] = useState()

  const [daysToDevelop, setDaysToDevelop] = useState()
  const [itemsInCart, setItemsInCart] = useState()
  const [nameProject, setNameProject] = useState()

  const [projects, setProjects] = useState([])
  const [projectDelete, setProjectDelete] = useState(false)
  const [typeProject, setTypeProject] = useState()
  const [nameProjectChange, setNameProjectChange] = useState()

  const [typeProjectChange, setTypeProjectChange] = useState()
  const [nameSearch, setNameSearch] = useState()
  const [searchSubComponents, setSearchSubComponents] = useState([])

  const [customComponents, setCustomComponents] = useState([])
  const { updateCart } = useContext(CartContext)
  const { imageBase64, name, animation, description, developTime, price } = useContext(ComponentContext)

  const [newNameProject, setNewNameProject] = useState()
  const [itemsCart2, setItemsCart2] = useState([])
  const [showCustomModal, setShowCustomModal] = useState(false)
  const [idProject, setIdProject] = useState()
  const [statusModalCreateNewProject, setStatusModalCreateNewProject] = useState(false)
  const [statusModalCreate, setStatusModalCreate] = useState(false)

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id")

  const sidebar = {
    position: "fixed",
    zIndex: "3",
    background: "white",
    top: "0",
    width: "300px",
    overflowY: "auto",
    overflowX: "hidden",

    height: "100vh",
    paddingTop: "5rem",
    borderRight: "1px solid #D7D7D7",
    borderLeft: "1px solid #D7D7D7",

  }

  const sidebar4 = {
    position: "fixed",
    top: "0",

    right: 0,
    overflowY: "scroll",
    height: "100vh",
    paddingTop: "5rem",
    paddingBottom: "2.4rem",
    borderRight: "1px solid #D7D7D7",
    borderLeft: "1px solid #D7D7D7",

  }

  const containerFunctionalities = {

    display: "flex",
    position: "relative",
    flexDirection: "column",
    aligItems: "center",


  }

  const header = {

    display: "flex",
    borderBottom: "1px solid #D7D7D7",
    paddingLeft: "1rem",
    height: "3.6rem",


  }

  const containerText = {

    display: "flex",
    aligItems: "center",
    justifyContent: "space-between",

    borderBottom: "1px solid #D7D7D7",
    paddingLeft: "1rem",
    height: "2.5rem",
  }

  const headerColumn4 = {

    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #D7D7D7",
    paddingLeft: "1rem",
    height: "4.6rem",
    marginBottom: "1.188rem",


  }

  const typeOption = {

    border: "1px solid #D7D7D7",
    width: "14.25rem",
    display: "flex",
    aligItems: "center",
    paddingLeft: "0.53rem",
    height: "57px",
    borderRadius: "10px",
    marginBottom: "1.188rem",

  }

  const infoProject = {
    zIndex: "1",
    borderBottom: "1px solid #D7D7D7",
    width: "14.25rem",
    cursor: "pointer",
    display: "flex",
    aligItems: "center",
    paddingLeft: "0.53rem",
    marginBottom: "1.188rem",
  }

  const listProjects = {

    border: "1px solid #D7D7D7",
    width: "14.25rem",
    height: "auto",


  }

  const numberFeatures = {

    display: "flex",
    paddingTop: "0.2rem",
    justifyContent: "center",
    aligItems: "center",
    background: "#1172EB",
    width: "3.125rem",
    height: "28px",
    borderRadius: "15px",
    color: "white"
  }

  const footer = {

    position: "fixed",
    bottom: 0,
    width: "100%",
    height: '89px',
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "28rem",
    paddingRight: "2rem",
    alignItems: "center",
    borderTop: "1px solid #e6e6e6",
    zIndex: "1",
    background: "white"
  }

  const btnFinish = {

    width: "170px",
    display: "flex",
    paddingTop: "1rem",
    alignItems: "center",
    justifyContent: "center",
    height: "40px",
    background: "#1172EB",
    borderRadius: "60px",
    border: "none",
    outline: "none"

  }

  const inputSearch = {

    background: "white",
    border: "none",
    width: "220px",
    height: '40px',
    borderRadius: "60px",
    outline: "none",
    paddingLeft: "50px",
    marginBottom: "30px",
    border: "1.9px solid #d7d7d7"

  }

  const columnInputSearch = {

    display: "flex",
    position: "relative",
  }

  const redirectToGeneralDefinitionUpdatePage =  async () =>{

    const project = await api.post("project/project/info/pay",{
      project_id: id,
      mail: localStorage.getItem("@MAIL"),
    })

    localStorage.setItem("@GLOBAL_PROJECT_ID",project.data[0].global_id)

    localStorage.setItem("@PROJECT_ID_UPDATE",id)
    window.location.href="/general/update"

  }
  
  const getItemsInCart = async () => {

    const responseInfoComponent = await api.post(`cart/value/pay`, {
      mail: localStorage.getItem("@MAIL"),
      project_id: id

    })
    setPriceComponent(responseInfoComponent.data.price)
      setDaysToDevelop(responseInfoComponent.data.developTime)
      setItemsInCart(responseInfoComponent.data.items)


  }
  const getAllCategorys = async () => {

    const response = await api.get("categorys/get/all")

    setItems(response.data)

  }

  const loadImage = async () => {

    setImage(imageBase64)
    setNameComponent(name)

  }

  const getProject = async () => {

    if (id) {

      const response = await api.post("project/project/info/payment", {

        mail: localStorage.getItem("@MAIL"),
        project_id: id
      })

      localStorage.setItem("@TYPE_PROJECT", response.data[0].type_project)

      setTypeProjectChange(response.data[0].type_project)
      setNameProjectChange(response.data[0].name)

    }

  }

  const getInfoProject = async (changeToId) => {

    if (changeToId != undefined) {

      const response = await api.post("project/info", {

        mail: localStorage.getItem("@MAIL"),
        project_id: changeToId
      })

      setTypeProject(response.data[0].type_project)
      setNameProject(response.data[0].name)

      localStorage.setItem("@PROJECT_CHANGE_ID2", changeToId)
      window.location.href = `/functionalities/?id=${changeToId}`

      return false

    }

    const responseInfo = await api.get(`project/all/${localStorage.getItem("@MAIL")}`)

    const response = await api.post("project/info", {

      mail: localStorage.getItem("@MAIL"),
      project_id: id
    })


    setTypeProject(response.data[0].type_project)
    setNameProject(response.data[0].name)

  }

  const getProjects = async () => {
    const response = await api.get(`project/all/${localStorage.getItem("@MAIL")}`)    
    if(response.status == 404){
      setProjects([])
      return false
    }
    
    setProjects(response.data)

  }

  const createNewProject = async () => {
    setStatusModalCreateNewProject(true)
  }

  const removeProject = async (id) => {

    const responseInfo = await api.get(`project/all/${localStorage.getItem("@MAIL")}`)

    if (responseInfo.data.length == 1) {

      toast.success('Você precisa ter pelo menos 1 projeto', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,

        progress: undefined,
        theme: "light",
      });

      return false
    }


    const response = await api.delete(`/project/${id}`)

    if (response.status == 200) {

      notify("Projeto deletado com sucesso!")
      const responseInfo = await api.get(`project/all/${localStorage.getItem("@MAIL")}`)
      localStorage.setItem("@PROJECT_CHANGE_ID2", responseInfo.data[0].project_id)
      setProjectDelete(!projectDelete)
      window.location.href = "/functionalities"
    }




  }

  const getComponents = async () => {
    const response = await api.get(`/components/name/${nameSearch}`)
    if(response.status == 404){
      setSearchSubComponents([])
      return false
    }
    setSearchSubComponents(response.data)
  }

  const getCustomComponents = async () => {
    const projectChange = localStorage.getItem("@PROJECT_CHANGE_ID")
    const response = await api.get(`custom/project/component/${projectChange}`)
    setCustomComponents(response.data)
  }

  const updateNameProject = async () => {

    if (newNameProject == undefined || newNameProject == "") {

      return false
    }
    const response = await api.put("project/project", {
      name: newNameProject,
      project_id: id

    })


  }

  const changeStateModal = async () => {

    const response = await api.post("project/info", {
      mail: localStorage.getItem("@MAIL"),
      project_id: id

    })

    if (response.data[0].state_modal == null) {
      setStatusModalCreate(true)

    } else {
      setStatusModalCreate(false)
    }

  }

  const getLastId = async () => {


    const response = await api.post("project/project/last/", {
      mail: localStorage.getItem("@MAIL")
    })
    setIdProject(response.data.id)

    window.location.href = `functionalities/?id=${response.data.id}`
  }

  const notify = (message) => toast(message);

  useEffect(() => {
    getAllCategorys()
  }, [])
  useEffect(() => {
    getProjects()

  }, [])
  useEffect(() => {

    getProjects()

  }, [projectDelete])
  useEffect(() => {

    getInfoProject()

  }, [])
  useEffect(() => {

    getInfoProject()

  }, [projectDelete])
  useEffect(() => {
    getItemsInCart()
  }, [updateCart])
  useEffect(() => {

    loadImage()

  }, [imageBase64])
  useEffect(() => {

    getProject()


  }, [])
  useEffect(() => {

    getComponents()


  }, [nameSearch])
  useEffect(() => {

    api.post(`cart/update/simple/component`, {
      "id": id,
      "mail": localStorage.getItem("@MAIL"),
    }).then((response) => {

      setItemsCart2(response.data)
    })


  }, [])

  useEffect(() => {

    api.post(`cart/update/simple/component`, {
      "id": id,
      "mail": localStorage.getItem("@MAIL"),
    }).then((response) => {

      setItemsCart2(response.data)
    })


  }, [updateCart])
  useEffect(() => {

    getCustomComponents()

  }, [])
  useEffect(() => {

    getCustomComponents()

  }, [updateCart])

  useEffect(() => {

    changeStateModal()

  }, [])
  useEffect(() => {

    if (id == null) {
      getLastId()
    }

  }, [id])



  document.tidioIdentify = { email: localStorage.getItem("@MAIL"), name: localStorage.getItem("@CLIENT_NAME") }
  return (


    <>
      <ToastContainer />


      <ThreeColumLayout

        typeHeader={'functionalities'}

        colum2Data={

          <div style={sidebar}>

            <div style={header} >


              <div style={columnInputSearch}>

                <div style={{ position: 'absolute', left: "15px", top: "3px" }}>
                  <svg width="19" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.16667 16.4798C12.8486 16.4798 15.8333 13.4951 15.8333 9.81315C15.8333 6.13125 12.8486 3.14648 9.16667 3.14648C5.48477 3.14648 2.5 6.13125 2.5 9.81315C2.5 13.4951 5.48477 16.4798 9.16667 16.4798Z" stroke="#1172EB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M17.5 18.1465L13.875 14.5215" stroke="#1172EB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>



                <input className="inputLogin" onChange={(e) => setNameSearch(e.target.value)} style={inputSearch} placeholder="Pesquisar" />
              </div>


              <div style={{ marginLeft: "0.9rem", marginTop: "0.15rem" }}>

                <div onClick={() => setShowCustomModal(true)} style={{ cursor: "pointer" }}>
                  <svg widt="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="34" height="34" rx="17" fill="white" stroke="#D7D7D7" />
                    <path d="M23.515 11.4703C23.1985 11.1684 22.7781 11 22.3409 11C21.9037 11 21.4833 11.1684 21.1667 11.4703L15.0887 17.5516C14.8996 17.5152 14.7075 17.497 14.5149 17.4973C14.1234 17.4962 13.7357 17.5728 13.374 17.7228C13.0123 17.8728 12.684 18.0931 12.4079 18.371C11.3666 19.4156 11.0879 22.7251 11.0412 23.3777L11 24L11.621 23.9566C12.2718 23.912 15.5801 23.6346 16.6224 22.5904C16.9672 22.2442 17.2218 21.8185 17.3636 21.3507C17.5055 20.8828 17.5303 20.3873 17.4359 19.9076L23.515 13.8214C23.8256 13.5091 24 13.0865 24 12.6458C24 12.2052 23.8256 11.7825 23.515 11.4703ZM15.8556 21.8227C15.3512 22.3271 13.5414 22.6697 12.1818 22.8163C12.3352 21.4046 12.6932 19.6214 13.1748 19.1387C13.5361 18.8129 14.0086 18.6384 14.4947 18.6511C14.9808 18.6638 15.4435 18.8628 15.7873 19.207C16.1312 19.5513 16.33 20.0145 16.3427 20.5012C16.3554 20.9879 16.181 21.4609 15.8556 21.8227ZM22.7493 13.0536L16.9891 18.8211C16.775 18.4967 16.4985 18.2183 16.1756 18.0023L21.9336 12.237C22.0431 12.1322 22.1888 12.0737 22.3403 12.0737C22.4918 12.0737 22.6375 12.1322 22.7471 12.237C22.8009 12.2904 22.8436 12.3539 22.8729 12.4239C22.9021 12.4939 22.9172 12.569 22.9174 12.6448C22.9176 12.7207 22.9029 12.7958 22.874 12.866C22.8452 12.9361 22.8028 12.9999 22.7493 13.0536Z" fill="#1172EB" />
                  </svg>
                </div>

              </div>
            </div>

            <row></row>



            <div style={containerFunctionalities}>

              {searchSubComponents.length > 0

                ?

                searchSubComponents.map(item => {

                  return (


                    <SearchSubComponent id={item.id} price={item.price} develop_time={item.develop_time} category={item.category} description={item.description} icon={item.icon} name={item.name} />

                  )


                })

                :

                items.map(item => {

                  return (
                    <div key={item.id}>
                      <Functionalitie  idUrl={id} picture={item.picture} page={"update"} id={item.id} price={item.price} type={item.name} name={item.name} />
                    </div>
                  )


                })


              }





            </div>


          </div>

        }


        colum3Data={


          <div style={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center", position: "relative", height: "100vh" }}>


            <div style={{ width: "100%", height: "70vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", overflowY: "auto" }}>
              <div>

                {animation == true

                  ?

                  localStorage.getItem("@TYPE_PROJECT") == "web"

                    ?
                    <div style={{ width: "524px" }}>
                      <div>
                        <div class="ssc-square mb" style={{ height: "504.37px", width: "100%" }}></div>
                      </div>

                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                        <div>
                          <div class="ssc-line" style={{ marginBottom: "10px", width: "64px", height: "13px" }}></div>
                          <div class="ssc-line" style={{ width: "64px", height: "13px" }}></div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "40px" }}>

                          <div style={{ position: "relative", top: "10px" }}>
                            <svg width="2" height="49" viewBox="0 0 2 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0.639648 59L0.639648 7.74861e-07" stroke="url(#paint0_linear_3201_577)" stroke-width="0.8" />
                              <defs>
                                <linearGradient id="paint0_linear_3201_577" x1="1.14842" y1="-7.5322e-07" x2="1.14847" y2="59" gradientUnits="userSpaceOnUse">
                                  <stop stop-color="#DBDBDB" stop-opacity="0.05" />
                                  <stop offset="0.5" stop-color="#DBDBDB" />
                                </linearGradient>
                              </defs>
                            </svg>
                          </div>

                          <div class="ssc-line" style={{ width: "64px", height: "21px", position: "relative", top: "20px", marginBottom: "19px" }}></div>

                        </div>



                        <div>
                          <div class="ssc-line" style={{ marginBottom: "10px", width: "64px", height: "13px" }}></div>
                          <div class="ssc-line" style={{ width: "64px", height: "13px" }}></div>
                        </div>


                      </div>

                      <div class="ssc-wrapper">
                        <div class="ssc-line w-100 " style={{ marginBottom: "15px" }}></div>
                        <div class="ssc-line w-100 " style={{ marginBottom: "15px" }}></div>
                        <div class="ssc-line w-100 " style={{ marginBottom: "15px" }}></div>
                        <div class="ssc-line w-100 " style={{ marginBottom: "15px" }}></div>

                      </div>

                    </div>

                    :


                    <div style={{ width: "524px" }}>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div class="ssc-square mb" style={{ height: "504.37px", width: "166.26px" }}></div>
                      </div>

                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                        <div>
                          <div class="ssc-line" style={{ marginBottom: "10px", width: "64px", height: "13px" }}></div>
                          <div class="ssc-line" style={{ width: "64px", height: "13px" }}></div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "40px" }}>

                          <div style={{ position: "relative", top: "10px" }}>
                            <svg width="2" height="49" viewBox="0 0 2 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0.639648 59L0.639648 7.74861e-07" stroke="url(#paint0_linear_3201_577)" stroke-width="0.8" />
                              <defs>
                                <linearGradient id="paint0_linear_3201_577" x1="1.14842" y1="-7.5322e-07" x2="1.14847" y2="59" gradientUnits="userSpaceOnUse">
                                  <stop stop-color="#DBDBDB" stop-opacity="0.05" />
                                  <stop offset="0.5" stop-color="#DBDBDB" />
                                </linearGradient>
                              </defs>
                            </svg>
                          </div>

                          <div class="ssc-line" style={{ width: "114px", height: "21.88px", position: "relative", top: "30px", marginBottom: "29px" }}></div>

                        </div>



                        <div>
                          <div class="ssc-line" style={{ marginBottom: "10px", width: "64px", height: "13px" }}></div>
                          <div class="ssc-line" style={{ width: "64px", height: "13px" }}></div>
                        </div>


                      </div>

                      <div class="ssc-wrapper">
                        <div class="ssc-line w-100 " style={{ marginBottom: "15px" }}></div>
                        <div class="ssc-line w-100 " style={{ marginBottom: "15px" }}></div>
                        <div class="ssc-line w-100 " style={{ marginBottom: "15px" }}></div>

                      </div>

                    </div>


                  :


                  image == undefined ? <></> : localStorage.getItem("@TYPE_PROJECT") == "web" ? <img style={{ objectFit: 'contain', width: '503px', height: "300px", borderRadius: '6px', border: "2px solid #1172EB", marginTop: "9.5rem" }} src={image} />


                    :
                    <img className='image_component_mobile' style={{ objectFit: 'contain', width: '166px', height: "360px", border: "2px solid #1172EB", borderRadius: "6px", marginTop: "13.30rem" }} src={image} />


                }
              </div>

              {animation == true

                ?
                <></>
                :

                image != undefined

                  ?


                  <>
                    <p className="textNameImageComponent">{nameComponent}</p>

                    <div style={{ width: "520px", marginBottom: "20px" }}>
                      <p className="textPreviewComponent" style={{ textAlign: "center" }}>{description}</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-around", width: "220px", marginBottom: "2.5rem" }}>

                      <div>
                        <p className="textInfoProjectTimePrimary">Tempo</p>
                        <p className="textInfoProjectTimeSecondary">{developTime} Dias</p>

                      </div>

                      <svg width="2" height="59" viewBox="0 0 2 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.20898 59L1.20898 7.74861e-07" stroke="#C1C1C1" stroke-width="0.8" />
                      </svg>


                      <div>
                        <p className="textInfoProjectTimePrimary">Valor</p>
                        <p className="textInfoProjectTimeSecondary">{price != undefined ? Number(price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : <></>}</p>
                      </div>

                    </div>
                  </>
                  :
                  <></>
              }


            </div>



            <footer style={footer}>

              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "580px", paddingTop: "0.438rem" }}>

                <div>
                  <p className="textValueProject">Valor</p>
                  <p className="textInfoProject">{priceComponent != undefined ? priceComponent.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : <></>}</p>
                </div>

                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.125 6.125H7.875V0.875C7.875 0.391754 7.48325 0 7 0C6.51675 0 6.125 0.391754 6.125 0.875V6.125H0.875C0.391754 6.125 0 6.51675 0 7C0 7.48325 0.391754 7.875 0.875 7.875H6.125V13.125C6.125 13.6082 6.51675 14 7 14C7.48325 14 7.875 13.6082 7.875 13.125V7.875H13.125C13.6082 7.875 14 7.48325 14 7C14 6.51675 13.6082 6.125 13.125 6.125Z" fill="black" />
                </svg>


                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", height: "30px" }}>
                  <p className="textValueProject">Startpn Scale</p>
                  <p>-</p>
                </div>


                <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.0625 2.84131H0.9375C0.419736 2.84131 0 2.39359 0 1.84131C0 1.28903 0.419736 0.841309 0.9375 0.841309H14.0625C14.5803 0.841309 15 1.28903 15 1.84131C15 2.39359 14.5803 2.84131 14.0625 2.84131Z" fill="#6B6B6B" />
                  <path d="M14.0625 7.15869H0.9375C0.419736 7.15869 0 6.71097 0 6.15869C0 5.60641 0.419736 5.15869 0.9375 5.15869H14.0625C14.5803 5.15869 15 5.60641 15 6.15869C15 6.71097 14.5803 7.15869 14.0625 7.15869Z" fill="#6B6B6B" />
                </svg>


                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", height: "30px" }}>
                  <p className="textValueProject">Custo total</p>
                  <p className="textInfoProject">{priceComponent != undefined ? priceComponent.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : <></>}</p>
                </div>


                <svg width="2" height="59" viewBox="0 0 2 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.20898 59L1.20898 7.74861e-07" stroke="#C1C1C1" stroke-width="0.8" />
                </svg>


                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", height: "30px" }}>
                  <p className="textValueProject">Entrega</p>
                  <p className="textInfoProject">{daysToDevelop != undefined ? daysToDevelop : 0} dias úteis</p>
                </div>


              </div>

              <button style={btnFinish} onClick={(() =>  redirectToGeneralDefinitionUpdatePage())}>
                <p className="textBtnFinish">Prosseguir</p>

              </button>

            </footer>
          </div>
        }


        colum4Data={

          <div style={sidebar4} >
            <div style={headerColumn4}>

              <div class="dropdown">


                <div style={typeOption} data-toggle="dropdown" >

                  {typeProjectChange == undefined

                    ?
                    typeProject == "mobile" ? <img style={{ width: "30px" }} src={mobileIconSidebar} /> : <img src={weMobileIcon} style={{ width: "30px" }} />
                    :
                    typeProjectChange == "mobile" ? <img style={{ width: "30px" }} src={mobileIconSidebar} /> : <img src={weMobileIcon} style={{ width: "30px" }} />
                  }


                  <div>
                    {typeProjectChange == undefined

                      ?
                      <p className="textTypeOptionSidebar" style={{ marginLeft: "1.125rem", marginTop: "0.438rem" }}></p>

                      :
                      <p className="textTypeOptionSidebar" style={{ marginLeft: "1.125rem", marginTop: "0.438rem" }}></p>

                    }

                    <div style={{ display: "flex", alignItems: "center", height: "30px", marginTop: "-0.563rem" }}>

                      <div style={{ position: "relative", top: "6px", left: "15px" }}>



                        <input className="textTypeOptionProject" onBlur={() => { updateNameProject() }} onChange={(e) => setNewNameProject(e.target.value)} style={{ border: "none", outline: "none", position: "relative", width: "135px" }} maxLength={35} type="text" value={newNameProject != undefined ? newNameProject : nameProjectChange} />





                      </div>

                    </div>

                  </div>
                  <div style={{ marginTop: "0.663rem", position: "absolute", right: "15px" }}>

                    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.56565 7.50066L11.9814 1.6605C12.1067 1.52542 12.1758 1.3451 12.1758 1.15284C12.1758 0.960576 12.1067 0.780261 11.9814 0.645185L11.5826 0.215097C11.3228 -0.0647638 10.9005 -0.0647638 10.6411 0.215097L6.0933 5.11923L1.54047 0.209655C1.41512 0.0745794 1.248 -5.01267e-07 1.06981 -5.09441e-07C0.891412 -5.17624e-07 0.724298 0.0745794 0.59884 0.209655L0.200202 0.639743C0.0748424 0.774926 0.00578113 0.955134 0.00578113 1.1474C0.00578112 1.33966 0.0748424 1.51998 0.200202 1.65505L5.62085 7.50066C5.74661 7.63606 5.91451 7.71042 6.09301 7.71C6.27219 7.71042 6.44 7.63606 6.56565 7.50066Z" fill="#23262F" />
                    </svg>
                  </div>


                </div>


                <div style={listProjects} class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                </div>

              </div>

              <div style={{ marginRight: "0.875rem", marginLeft: "0.938rem", cursor: "pointer" }} onClick={() => createNewProject()}>
                <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="54" height="54" rx="7.5" fill="white" stroke="#D7D7D7" />
                  <path d="M36.1875 26.0208H29.3125V19.1458C29.3125 18.513 28.7995 18 28.1667 18C27.5338 18 27.0208 18.513 27.0208 19.1458V26.0208H20.1458C19.513 26.0208 19 26.5338 19 27.1667C19 27.7995 19.513 28.3125 20.1458 28.3125H27.0208V35.1875C27.0208 35.8203 27.5338 36.3333 28.1667 36.3333C28.7995 36.3333 29.3125 35.8203 29.3125 35.1875V28.3125H36.1875C36.8203 28.3125 37.3333 27.7995 37.3333 27.1667C37.3333 26.5338 36.8203 26.0208 36.1875 26.0208Z" fill="#23262F" />
                </svg>
              </div>





            </div>

            <div style={containerText}>

              <p className="textFunctionalities">Funcionalidades</p>

              <div style={{ marginRight: "0.875rem", marginLeft: "0.938rem" }}>

                <div style={numberFeatures}>
                  <p className="textNumberFeatures">{itemsInCart}</p>
                </div>

              </div>

            </div>


            <div style={containerFunctionalities} >

              {itemsCart2.map(item => {
                return (
                  <div key={item.id}>
                    <PreviewFunctionalitie page={"update"} category={item.category} type={item.type} project_id={item.project_id} id={item.componentSelect} idToRemove={item.id} name={item.name} />
                  </div>
                )

              })}

              {customComponents.map(item => {
                return (

                  <PreviewCustomFunctionalitie name={item.name} id={item.id} />
                )

              })}





              <div style={{ height: "50px" }}></div>

            </div>


          </div>

        }

      />
      <ModalCreateNewProject modalShow={statusModalCreateNewProject} closeModal={() => setStatusModalCreateNewProject(false)} />
      <ModalCreateProject modalShow={statusModalCreate} closeModal={() => setStatusModalCreate(false)} />
      <ModalCreateCustomComponent onClick={() => setShowCustomModal(false)} show={showCustomModal} />

    </>

  )

}

export default UpdateFeatures