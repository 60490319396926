import trashIconFunctionalitis from "../../../images/Svg/trashIconFunctionalitis.svg"
import checkOn from "../../../images/Svg/checkOn.svg"
import api from "../../../services/api"
import { useContext, useEffect, useState } from "react"
import { CartContext } from "../../../context/cart"
var lz = require('lz-string');

const CardFunctionaLities = ({ id, type, name,category, cart, time, priceData, picture, general, type_project}) => {
    const { removeItemCart } = useContext(CartContext)
    const [image, setImage] = useState()
    const [price, setPrice] = useState()

    const [daysToDevelop, setDaysToDevelop] = useState()
    const [nameComponent, setNameComponent] = useState()

    const getInfo = async () => {

        const response = await api.post(`components/simple/sidebar/`, {

            "type": localStorage.getItem("@TYPE_PROJECT"),
            "id": id

        })

        if (localStorage.getItem("@TYPE_PROJECT") == "web") {

            const imageDesktop = (response.data[0][0].desktop_icon)

            setImage(imageDesktop)


        } else {
            const imageMobile = (response.data[0][0].mobile_icon)
            setImage(imageMobile)

        }

        setNameComponent(response.data[0][0].name)
        setPrice(response.data[0][0].price)
        setDaysToDevelop(response.data[0][0].develop_time)
    }

    function deleteItemCart(idItem, category) {
        removeItemCart(idItem, category)
    }

    useEffect(() => {
        getInfo()
    }, [])


    return (

        
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "12px 23px",
            borderBottom: "1px solid #E5E5E5",
        }}>

            <div style={{
                display: "flex",
                gap: "20px",
                alignItems: "center"
            }}>

            {

                general ?
                <img src={cart ? image : picture} style={
                   type_project == "mobile" && { width: "35px", height: "77px", objectFit: "cover", border: "1px solid var(--color-primary)", borderRadius: "4px" } ||
                   type_project == "web" && { width: "84px", height: "53px", objectFit: "contain", border: "1px solid var(--color-primary)", borderRadius: "4px" } ||
                    { width: "135px", height: "77px", objectFit: "cover" }
                } />
                : 
                <img src={cart ? image : picture} style={
                    localStorage.getItem("@TYPE_PROJECT") == "mobile" && { width: "35px", height: "77px", objectFit: "cover", border: "1px solid var(--color-primary)", borderRadius: "4px" } ||
                    localStorage.getItem("@TYPE_PROJECT") == "web" && { width: "84px", height: "53px", objectFit: "contain", border: "1px solid var(--color-primary)", borderRadius: "4px" } ||
                    { width: "35px", height: "77px", objectFit: "cover" }
                } />

            }   
                <div style={{
                    display: "flex",
                    flexDirection: "column",    
                    gap: "14px"
                }}>
              
                    {nameComponent == undefined && name == undefined
                    
                    ?
                    <div class="ssc-line" style={{width:"50px",height:"21px",borderRadius:"2px"}}></div>

                    :
                    <p className="textSelectFeatSection" >{cart ? nameComponent : name}</p>

                    }

                    
                    <div style={{
                        display: "flex",
                        gap: "5px"
                    }}>
                        
                        
                        
                        <>
                        {daysToDevelop == undefined && time == undefined
                        
                        ?
                        <div class="ssc-line" style={{width:"50px",height:"21px",borderRadius:"2px"}}></div>

                        :
                        <p className="textSelectFeatSection">{cart ? daysToDevelop == "1" ? `${daysToDevelop} dia` : `${daysToDevelop} dias` : time == "1" ? `${time} dia` : `${time} dias`}</p>

                        }
                       
                        {price == undefined && priceData == undefined 
                        ? 
                        <div class="ssc-line" style={{width:"50px",height:"21px",borderRadius:"2px"}}></div>
                        
                        :
                        <p className="textSelectFeatSection">{cart ? Number(price).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : ` ${Number(priceData).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`}</p>

                        }
                        </>
                        
               
                    </div>
                </div>
            </div>
            <img onClick={() => cart && deleteItemCart(id, category)} src={!cart ? checkOn : trashIconFunctionalitis} />
        </div>
    )
}

export default CardFunctionaLities