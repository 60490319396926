import scaleIcon from "../../../images/Svg/scaleIcon.svg";
import checkOff from "../../../images/Svg/checkOff.svg";
import checkOn from "../../../images/Svg/checkOn.svg";
import { useEffect, useState } from "react";
import Button from "./Button";
import FooterPrice from "./FooterPrice";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";

const CardScale = ({ priceTotal, totalDays, scale,totalCartUser }) => {
  const [check, setCheck] = useState(false);


  const navigate = useNavigate();

  const startpnScaleService = async () => {
    setCheck(!check);

    const response = await api.put("project/scale/service", {
      scale_service: check,
      mail: localStorage.getItem("@MAIL"),
    });

    localStorage.setItem("@SCALE_SERVICE_ENABLE", check);

  };

  return (
    <>
      <div
        style={{
          padding: "0 25px 22px",
          marginBottom: "56px",
        }}
      >
        <div
          style={{
            padding: "23px 21px",
            border: "1px solid #D7D7D7",
            borderRadius: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img src={scaleIcon} />
            <img
              onClick={() => {
                startpnScaleService();
              }}
              src={check == true ? checkOn : checkOff}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              marginTop: "30px",
            }}
          >
            <p className="textContentScaleDescription">
              30% de desconto na adição de novas funcionalidades;
            </p>
            <p className="textContentScaleDescription">
              Patches de segurança e Gerenciamento de Backup;
            </p>
            <p className="textContentScaleDescription">
              Correções de bugs do seu aplicativo & software;
            </p>
            <p className="textContentScaleDescription">
              Equipe multidisciplinar para atender você ou sua equipe em
              qualquer tipo de demanda.
            </p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "26px",
            }}
          >
            <p className="textTitleScaleValue">{Number(scale).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })} /Mês</p>

            <Button
              width="109px"
              height="40px"
              background="var(--color-primary)"
              borderRadius="60px"
              marginTop="0"
              name="Saiba mais"
              border="none"
              onClick={() =>
                (window.location.href = "https://www.startpn.com/")
              }
            />
          </div>
        </div>
      </div>
      <FooterPrice
        page="general"
        priceTotal={priceTotal}
        totalDays={totalDays}
        onClick={() => navigate("/payment")}
      />
    </>
  );
};

export default CardScale;
