import { useContext, useEffect, useState } from "react";
import BoxCardsCardsForPurchase from "../components/BoxCardsCardsForPurchase";
import CardScale from "../components/CardScale";
import CardVelocityProject from "../components/CardVelocityProject";
import HeaderProfile from "../components/HeaderProfile";
import { ComponentContext } from "../../../context/component";
import api from "../../../services/api";

const GeneralDefinitionMobile = () => {
  const [image, setImage] = useState();
  const [nameComponent, setNameComponent] = useState();

  const [nameProject, setNameProject] = useState();
  const [projects, setProjects] = useState([]);
  const [typeProject, setTypeProject] = useState();

  const [nameProjectChange, setNameProjectChange] = useState();
  const [typeProjectChange, setTypeProjectChange] = useState();

  const { imageBase64, name } = useContext(ComponentContext);

  /* */

  const [velocity, setVelocity] = useState(2);
  const [scaleService, setScaleService] = useState(false);

  const [priceTotal, setPriceTotal] = useState(0);
  const [totalDays, setTotalDays] = useState(0);
  const [valueProjet, setValueProject] = useState(0);

  const [loading, setLoading] = useState(false);
  const [valueScaleService, setValueScaleService] = useState(0);
  const [valueToScaleService, setValueToScaleService] = useState();

  const [localScaleService, setLocalScaleService] = useState();

  /* LOCAL EVENTS */
  const [localArray, setLocalArray] = useState([])

  const getProject = async () => {
    const project = localStorage.getItem("@PROJECT_CHANGE_ID");

    if (project) {
      const response = await api.post("project/info", {
        mail: localStorage.getItem("@MAIL"),
        project_id: project,
      });

      localStorage.setItem("@TYPE_PROJECT", response.data[0]?.type_project);

      setTypeProjectChange(response.data[0]?.type_project);
      setNameProjectChange(response.data[0]?.name);
    }
  };
  const getInfoProject = async (changeToId) => {
    if (changeToId != undefined) {
      const response = await api.post("project/info", {
        mail: localStorage.getItem("@MAIL"),
        project_id: changeToId,
      });

      setTypeProject(response.data[0].type_project);
      setNameProject(response.data[0].name);

      localStorage.setItem("@PROJECT_CHANGE_ID", changeToId);
      window.location.href = "/functionalities";

      return false;
    }

    const responseInfo = await api.get(
      `project/all/${localStorage.getItem("@MAIL")}`
    );

    const response = await api.post("project/info", {
      mail: localStorage.getItem("@MAIL"),
      project_id: responseInfo.data[0].project_id,
    });

    setTypeProject(response.data[0].type_project);
    setNameProject(response.data[0].name);
  };
  const getProjects = async () => {

      const response = await api.get(
        `project/all/${localStorage.getItem("@MAIL")}`
      );

      setProjects(response.data)

  };
  const loadImage = async () => {
    setImage(imageBase64);
    setNameComponent(name);
  };

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    getInfoProject();
  }, []);

  useEffect(() => {
    loadImage();
  }, [imageBase64]);

  useEffect(() => {
    getProject();
  }, []);

  const updateVelocity = async () => {
    if (velocity == 3) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, [2000]);

      const response = await api.post("cart/all/value", {
        mail: localStorage.getItem("@MAIL"),
      });

      setPriceTotal(response.data.price * 2);
      setTotalDays(response.data.developTime / 2);

      localStorage.setItem("@TOTAL_VALUE_PROJECT", response.data.price * 2);
      localStorage.setItem(
        "@DEVELOPT_TIME_PROJECT",
        response.data.developTime / 2
      );

      const updateVelocity = await api.put("project/velocity", {
        mail: localStorage.getItem("@MAIL"),
        velocity: "3",
      });
    }

    if (velocity == 2) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, [2000]);

      const response = await api.post("cart/all/value", {
        mail: localStorage.getItem("@MAIL"),
      });

      setValueProject(response.data.price);
      setPriceTotal(response.data.price);
      setTotalDays(response.data.developTime);

      localStorage.setItem("@TOTAL_VALUE_PROJECT", response.data.price);
      localStorage.setItem("@DEVELOPT_TIME_PROJECT", response.data.developTime);

      const updateVelocity = await api.put("project/velocity", {
        mail: localStorage.getItem("@MAIL"),
        velocity: "2",
      });
    }

    if (velocity == 1) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, [2000]);

      const response = await api.post("cart/all/value", {
        mail: localStorage.getItem("@MAIL"),
      });

      setPriceTotal(response.data.price / 2);
      setTotalDays(response.data.developTime * 2);

      localStorage.setItem("@TOTAL_VALUE_PROJECT", response.data.price / 2);
      localStorage.setItem(
        "@DEVELOPT_TIME_PROJECT",
        response.data.developTime * 2
      );

      const updateVelocity = await api.put("project/velocity", {
        mail: localStorage.getItem("@MAIL"),
        velocity: "1",
      });
    }
  };

  const startpnScale = async () => {
    if (velocity == 3) {
      const response = await api.post("cart/all/value", {
        mail: localStorage.getItem("@MAIL"),
      });

      const calc = Number(response.data.price) * 2;
      const total = (calc * 1) / 100;

      setValueToScaleService(calc + total);
      setValueScaleService(total.toFixed(2));
      localStorage.setItem("@SCALE_SERVICE", total.toFixed(2));
    }

    if (velocity == 2) {
      const response = await api.post("cart/all/value", {
        mail: localStorage.getItem("@MAIL"),
      });

      const calc = Number(response.data.price);
      const total = (calc * 1) / 100;

      setValueToScaleService(calc + total);
      setValueScaleService(total.toFixed(2));
      localStorage.setItem("@SCALE_SERVICE", total.toFixed(2));
    }

    if (velocity == 1) {
      const response = await api.post("cart/all/value", {
        mail: localStorage.getItem("@MAIL"),
      });

      const calc = Number(response.data.price) / 2;
      const total = (calc * 1) / 100;

      setValueToScaleService(calc + total);
      setValueScaleService(total.toFixed(2));
      localStorage.setItem("@SCALE_SERVICE", total.toFixed(2));
    }
  };



  useEffect(() => {

  }, [localScaleService]);

  useEffect(() => {
    updateVelocity();
  }, [velocity]);

  useEffect(() => {
    startpnScale();
  }, [scaleService, velocity]);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "//code.tidio.co/nstq0ldng4h7bbhdiwcexf7r0jlhp2ns.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const container = {
    padding: "19px 0",
    backgroundColor: "#E5E5E520",
  };
  return (
    <div style={container}>
      <HeaderProfile />

      <CardVelocityProject velocity={velocity} setVelocity={setVelocity} />

      <BoxCardsCardsForPurchase projects={projects} />

      <CardScale
        priceTotal={priceTotal}
        totalDays={totalDays}
        scale={valueScaleService}
      />
    </div>
  );
};

export default GeneralDefinitionMobile;
