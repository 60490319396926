import { useEffect, useState } from "react"
import closeIcon from "../../../images/Svg/closeIcon.svg"
import iconImage1 from "../../../images/Svg/iconImage1.svg"

import FooterOverview from "./FooterOverview"
import CardFunctionaLitiesCart from "./CardFunctionaLitiesCart"
import Select from "./Select"
import api from "../../../services/api"
import { json, useNavigate } from "react-router-dom"
import { object } from "underscore"


const ModalListFunctionaLities = ({ closeModal, id }) => {

    const navigate = useNavigate()

    const urlParams = new URLSearchParams(window.location.search);

    const idProject = urlParams.get("id")

    const [projectName, setProjectName] = useState()
    const [customComponents, setCustomComponents] = useState([])
    const [projects, setProjects] = useState([])

    const [newNameProject, setNewNameProject] = useState()
    const [typeProject, setTypeProject] = useState()
    const [nameProjectChange, setNameProjectChange] = useState()

    const [itemsInCart, setItemsInCart] = useState()
    const [itemsCart2, setItemsCart2] = useState([])

    const getCustomComponents = async () => {
        const projectChange = localStorage.getItem("@PROJECT_CHANGE_ID_FOLLOW_PAGE")

        const response = await api.get(`custom/project/component/${projectChange}`)


        setCustomComponents(response.data)


    }
    const getInfoProject = async (changeToId) => {


        if (changeToId != undefined) {

            const response = await api.post("project/project/follow/info", {

                mail: localStorage.getItem("@MAIL"),
                project_id: changeToId
            })



            const globalId = await api.post("/global/project/info/id", {

                mail: localStorage.getItem("@MAIL"),
                project_id: changeToId

            })



            const responseProjects = await api.post("global/platform/projects", {
                mail: localStorage.getItem("@MAIL"),
                global_id: globalId.data[0].global_id

            })


            setProjects(responseProjects.data)

            setTypeProject(response.data[0].type_project)
            setNameProjectChange(response.data[0].name)
            

            localStorage.setItem("@GLOBAL_ID_PROJECT2", globalId.data[0].global_id)
            localStorage.setItem("@PROJECT_CHANGE_ID_FOLLOW_PAGE", changeToId)

            return false

        }

        const response = await api.post("project/project/follow/info", {

            mail: localStorage.getItem("@MAIL"),
            project_id: idProject
        })

        const globalId = await api.post("global/project/info/id", {

            mail: localStorage.getItem("@MAIL"),
            project_id: idProject

        })


        const responseProjects = await api.post("global/platform/projects", {
            mail: localStorage.getItem("@MAIL"),
            global_id: globalId.data[0].global_id

        })


        setProjects(responseProjects.data)

        localStorage.setItem("@PROJECT_CHANGE_ID_FOLLOW_PAGE", changeToId)
        localStorage.setItem("@TYPE_PROJECT_2", typeProject)

        setTypeProject(response.data[0].type_project)
        setNameProjectChange(response.data[0].name)

    }
    const getItemsInCart = async () => {

        const responseInfoComponent = await api.post(`checklist/count/items`, {
            mail: localStorage.getItem("@MAIL"),
            project_id: id

        })

        
        const localEvent = localStorage.getItem("@ITEMS_IN_CART_LOCAL_VALUE")
        
        if(localEvent != null){
            setItemsInCart(localEvent)
            return false
        }

    
        if(responseInfoComponent.status == 200){
            localStorage.setItem("@ITEMS_IN_CART_LOCAL_VALUE",responseInfoComponent.data.total)
            setItemsInCart(responseInfoComponent.data.total)
    
        }



    }
    const getCheckedComponents = async () => {

        const response = await api.post("checklist/component",{
            mail:localStorage.getItem("@MAIL"),
            project_id:idProject
        })

        if(response.status == 200){
            setItemsCart2(response.data)
        }
                
    }
    useEffect(() => {

        getInfoProject()
        getCustomComponents()
        getItemsInCart()

    }, [])
    

    useEffect(() => {

        getInfoProject()
        getCustomComponents()
        getItemsInCart()
    },[idProject])

    useEffect(() => {

        getCheckedComponents()

    },[])

    useEffect(() => {

        getCheckedComponents()

    },[idProject])


    return (
        <div style={{
            height: "100%",
            width: "100%",
            backgroundColor: "#fff"
        }}>

            <div style={{
                padding: "22px 23px 17px",
                borderBottom: "1px solid #E5E5E5",
                display: "flex",
                alignContent: "center",
                gap: "17px"
            }}>
                <img src={closeIcon} onClick={closeModal} />

                <h3 className="textTitleModalViewDetailsFeature">Funcionalidades</h3>
            </div>
            <div style={{
                padding: "22px 23px",
                borderBottom: "1px solid #E5E5E5",
            }}>
                <Select id={id} unique={true}  top={"50px"} typeProject={typeProject} value={nameProjectChange} setValue={setProjectName} onChange={(e) => setNewNameProject(e.target.value)} list={projects} />

            </div>

            <div style={{
                overflowY: "auto",
                height: "70%",
            }}>
                {
                    itemsCart2.map(item => {

                        if (item.project_id !== 'undefined') {

                            return (
                                <>
                                    <CardFunctionaLitiesCart  project_id={item.project_id} checkFollowUp={item.checkFollowUp} custom={item.custom} checked={item.checkFollowUp} globalId={item.global_id} id={item.componentSelect} picture={iconImage1} name={"Cadastro"} />
                                </>
                            )
                        }
                    })
                }

            </div>
            <FooterOverview id={idProject} />

        </div>
    )
}

export default ModalListFunctionaLities