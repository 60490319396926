import { useState,useEffect } from "react"
import logoPlatform from "../../../images/Svg/logoPlatform.svg"
import Input from "../components/Input"
import Button from "../components/Button"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import api from "../../../services/api"
const ResetPasswordMobile = () => {
    const [btnState, setBtnState] = useState(false)
    const [passwordInput, setPasswordInput] = useState("")
    const [confirmPasswordInput, setConfirmPasswordInput] = useState("")
    const [token,setToken] = useState()

    const navigate = useNavigate();

    const resetPassword = async () => {


        if(passwordInput == undefined || confirmPasswordInput == undefined){

            return toast.error('Informe todos os campos!', {
                position: "top-right",
                autoClose: 1300,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        if (passwordInput !== confirmPasswordInput) {
            return toast.error('Senhas estão diferentes', {
                position: "top-right",
                autoClose: 1300,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

    
        const response = await api.post("recover/password",{
            "token":token,
            "mail":localStorage.getItem("@RECOVER_MAIL"),
            "password_hash":confirmPasswordInput
        })
        if(response.status == 200){

            toast.success('Senha alterada com sucesso', {
                position: "top-right",
                autoClose: 1300,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            setTimeout(() => {

                window.location.href="/"

            },[3000])

        }else{
            toast.error("Token inválido!")
        }

        
    }



    const container = {
        padding: "0 39px 9vh",
        backgroundColor: "#E5E5E520"
    }

    const containerLogo = {
        marginBottom: "69px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        paddingTop: "87px"
    }

    const boxInputs = {
        display: "flex",
        flexDirection: "column",
        marginTop: "41px",
        gap: "26px"
    }

    const boxFooter = {
        marginTop: "105px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "31px"
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token") 
        setToken(token)
    },[])

    return (
        <div style={container}>
            <div style={containerLogo}>
                <img src={logoPlatform} />
            </div>

            <h3 className="textTitleFormSend">Crie uma nova senha</h3>

            <div style={{ marginTop: "25px" }}>
                <p className="textTitleFormDescription">Preencha os campos
                    abaixo com a nova senha que deseja cadastrar
                </p>
            </div>

            <div style={boxInputs}>
                <Input onChange={(e) => setPasswordInput(e.target.value)}
                    value={passwordInput} name="Nova senha" type={"password"}
                    placeholder="Insira a nova senha"
                />
                
                <Input onChange={(e) => setConfirmPasswordInput(e.target.value)}
                    value={confirmPasswordInput} name="Confirmação da nova senha"
                    type={"password"} placeholder="Insira a confirmação da sua senha"
                />
            </div>

            <div style={boxFooter}>
                <Button width="100%" height="45px" background="var(--color-primary)"
                    borderRadius="60px" marginTop="0" name="Criar nova senha"
                    border="none" disabled={btnState} onClick={() => resetPassword()} />

                <p className="textRememberedThePassword" onClick={() => navigate("/")}>Lembrou da senha?</p>
            </div>

        </div>
    )
}

export default ResetPasswordMobile