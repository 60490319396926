import { useEffect, useState } from "react"
import api from "../../../services/api"
import FormProfileUser from "../components/FormProfileUser"
import HeaderProfile from "../components/HeaderProfile"
import NoteFuture from "../components/NoteFuture"
import UploadPictureProfile from "../components/UploadPictureProfile"
import { toast } from "react-toastify"
import Button from "../components/Button"
import { useNavigate } from "react-router-dom"


const ProfileMobile = () => {
    const [name, setName] = useState()
    const [surName, setSurName] = useState()
    const [password, setPassword] = useState()

    const [picture, setPicture] = useState()
    const [mail, setMail] = useState()
    const [id, setId] = useState()
    
    const navigate = useNavigate("")

    const getData = async () => {

        const mail = localStorage.getItem("@MAIL")
        const response = await api.get(`client/user/${mail}`)


        setName(response.data[0].name)
        setMail(response.data[0].mail)
        setId(response.data[0].id)
        setSurName(response.data[0].surname)
        setPicture(response.data[0].picture)

        localStorage.setItem("@USER_PICTURE", response.data[0].picture)

    }

    const updateUser = async (user) => {

        if (user?.name) {
            if (user.name) {
                const response = await api.put("user/name", {
                    name: name,
                    surname: surName,
                    id: id
                })
            }
        }

        if (user?.mail) {
            if (user.mail !== "") {

                var re = /\S+@\S+\.\S+/;
                const validateMail = re.test(user.mail);

                if (validateMail == false) {
                    toast.error('Email em formato inválido!', {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else {
                    await api.put("user", {

                        mail: localStorage.getItem("@MAIL"),
                        newMail: mail,
                        id: id
                    })
                }
            }
        }
        if (user?.password) {
            if (String(user.password).length < 8) {
                toast.error('A senha deve ter 8 ou mais caracteres', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                await api.put("user/password", {

                    password_hash: password,
                    id: id
                })
            }
        }
    }
    const exit = () => {

        localStorage.clear()
        window.location.href="/"
    }

    useEffect(() => {
        getData()
    }, [])

    const container = {
        padding: "19px 0",
        backgroundColor: "#E5E5E520",
    }

    return (
        <div style={container}>
            <HeaderProfile />

            <UploadPictureProfile id={id} picture={picture} />

            <FormProfileUser
                nameData={name}
                surNameData={surName}
                passwordData={password}
                pictureData={picture}
                mailData={mail}
                setNameData={setName}
                setSurNameData={setSurName}
                setPasswordData={setPassword}
                setPictureData={setPicture}
                setMailData={setMail}
                setIdData={setId}
                updateUser={updateUser}
            />

            <NoteFuture />

            <div style={{padding: "0 25px"}}>
                <Button width="100%" height="45px" background="#FFE1E1" fontSize="18px"
                    borderRadius="60px" marginTop="0" name="Sair" color="#EA0000" fontWeight={600}
                    border="none" onClick={()=> exit()}/>
            </div>
        </div>
    )
}

export default ProfileMobile