import { useContext, useEffect, useState } from "react"
import showSelectIcon from "../../../images/Svg/showSelect.svg"
import mobileIcon from "../../../images/Svg/mobile.svg"
import webIcon from "../../../images/Svg/web.svg"
import { CitysContext } from "../../../context/citys"
import { PaymentContext } from "../../../context/payment";

const SelectCity = ({ value, cityState, setValue, name, type, unique }) => {
    const { citys, update } = useContext(CitysContext)
    const [showSelect, setShowSelect] = useState(false)
    const [valueCity, setValueCity] = useState("")
    const [nameChange,setNameChange] = useState()
    const [width, setWidth] = useState("100%")
    const { formData,updateState } = useContext(PaymentContext)

    useEffect(()=> {
        setValueCity("")
    },[update])


    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #E5E5E5"
        }}>
            <div id="selectAdd" style={{ position: "relative", width: "100%" }} >

                <p className="textLabelInput" style={{ marginBottom: "14px" }}>{name}</p>
                <button className="textSelectProject" onClick={() => setShowSelect(!showSelect)} style={{
                    backgroundColor: "#fff",
                    color: "#000000",
                    border: "1px solid #D7D7D7",
                    width: "100%",
                    height: "45px",
                    borderRadius: type == "payment" ? "60px" : "10px",
                    position: "relative",
                    padding: "0 35px 0 10px",
                    overflow: "hidden",
                    display: "flex",
                    gap: "20px",
                    alignItems: "center"

                }}>
                    {valueCity}
                    {nameChange == undefined ? "Selecione" : <></>}
                    <img style={{ transform: showSelect ? "rotate(0deg)" : "rotate(180deg)", transition: "0.3s", position: "absolute", right: "16px", top: "18px" }} src={showSelectIcon} />
                </button>
                {
                    showSelect && (
                        <ul style={{
                            position: "absolute",
                            top: "75px",
                            zIndex: "1",
                            backgroundColor: "#fff",
                            border: "1px solid #D7D7D7",
                            borderRadius: "0 0 10px 10px",
                            width: unique ? "100%" : width,
                            padding: "20px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            maxHeight: "200px",
                            overflowY:"scroll"
                        }}>
                            {
                                citys.map(item => {
                                    return (
                                        <li className="textSelectProject" onClick={() => {
                                            updateState('cityState', item.Nome)

                                            setValue("userCity", item.Nome)
                                            setShowSelect(false)
                                            setValueCity(item.Nome)
                                            setNameChange(item.Nome)
                                        }}>{item?.Nome}</li>
                                    )
                                })
                            }
                        </ul>
                    )
                }
            </div>
        </div>
    )
}

export default SelectCity