import { create } from 'apisauce'

const api = create({

    baseURL: 'http://localhost:3333/'
})

/*

 Send Token to API
 
*/

api.addAsyncRequestTransform(response => async () => {

    const token = localStorage.getItem('@TOKEN')

    if (token) {

        response.headers['Authorization'] = `Bearer ${token}`

    }

})




export default api 