import { useEffect, useState } from "react"
import EndDateProject from "../components/EndDateProject"
import FooterOverview from "../components/FooterOverview"
import HeaderProfile from "../components/HeaderProfile"
import SpinerPercentag from "../components/SpinerPercentag"
import UploadImageOrFile from "../components/UploadImageOrFile"
import ModalContainer from "../components/ModalContainer"
import ModalListFunctionaLities from "../components/ModalListFunctionaLities"
import api from "../../../services/api"


const FollowUpMobile = () => {
    const [modal, setModal] = useState()
    const [globalDashboard, setGlobalDashboard] = useState()

    const getTotalDashboardMain = async () => {

        const response = await api.post("dashboard/total", {

            mail: localStorage.getItem("@MAIL"),
            global_id: localStorage.getItem("@GLOBAL_ID_PROJECT2")
        })

        const localEvent = localStorage.getItem("@GLOBAL_DASHBOARD_LOCAL_VALUE")    

        if(localEvent != null){
            setGlobalDashboard(localEvent)
            return false
        }

        if(response.status == 200){
            localStorage.setItem("@GLOBAL_DASHBOARD_LOCAL_VALUE" ,response.data.total)
            setGlobalDashboard(response.data?.total)

        }
        
    }

    useEffect(()=>{
        getTotalDashboardMain()
    },[])

    const container = {
        padding: "19px 0",
        backgroundColor: "#E5E5E520",
    }

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id") 


    return (
        <div style={container}>

            <HeaderProfile/>

            <SpinerPercentag percent={ globalDashboard != undefined ?  Number(globalDashboard).toFixed(0) : 0}/>

            <EndDateProject />

            <UploadImageOrFile />

            <FooterOverview id={id} card={() => setModal(true)} />

            <ModalContainer isActive={modal} closeModal={() => setModal(false)}>
                <ModalListFunctionaLities id={id} closeModal={() => setModal(false)} />
            </ModalContainer>

        </div>
    )
}

export default FollowUpMobile