import React, { useState, useEffect } from 'react'

import ThowColumLayoutLogin from '../../../layouts/ThowColumLayoutLogin'
import forgotPasswordBackgrond from '../../../images/Svg/forgotPasswordBackgrond.svg'
import companyIcon from '../../../images/Svg/companyIcon.svg'
import Button from '../components/Button'
import api from '../../../services/api'
import { useNavigate } from 'react-router-dom'
import InputLogin from '../../../components/InputLogin'
import { toast } from 'react-toastify'


const ResetPassword = () => {

    const [paswordConfirm, setPaswordConfirm] = useState()

    const [password, setPassword] = useState()
    const [token,setToken] = useState()
    const navigate = useNavigate();

    const container = {
        width: "418px",
        margin: "0 auto"
    }

    const boxFooterButton = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "36px"
    }

    /* MESSAGE ERROS */

    const [paswordConfirmError, setPaswordConfirmError] = useState()
    const [paswordError, setPasswordError] = useState()

    /* BTN STATE */

    const [btnState, setBtnState] = useState()


    const storage = async () => {

        const response = await api.post("/recover/password",{
            "token":token,
            "mail":localStorage.getItem("@RECOVER_MAIL"),
            "password_hash":paswordConfirm
        })
        if(response.status == 200){

            toast.success("Senha alterada com sucesso!",{
                autoClose:2000,
            })

            setTimeout(() => {

                window.location.href="/"

            },[3000])

        }else{
            toast.error("Token inválido!")
        }

    }

    const boxIconMail = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "3.25rem",
    }

    const containerLogo = {
        width: "418px",
        display: "flex",
    }

    const containerFigure = {
        height: "100vh",
        width: "45vw",
        backgroundColor: "#F8F9FF"
    }

    const image = {
        height: "100%",
        width: "100%"
    },


        containerColum2 = {
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
        }

    useEffect(() => {
        
        if (password != undefined) {

            if (String(password).length <= 7) {

                setPasswordError("senha deve conter no mínimo 8 caracteres")
                setBtnState(true)

            } else {

                setBtnState(false)
                setPasswordError()

            }

        }

        if(paswordConfirm != undefined){
            if(paswordConfirm !== password){
                setPaswordConfirmError("Senhas diferentes")
                setBtnState(true)
            }else if(paswordConfirm === password){
                setPaswordConfirmError()
            }
            else if(String(password).length >= 8){
                setPaswordConfirmError()
                setBtnState(false)
            }
        }


    }, [password, paswordConfirm])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token") 
        setToken(token)
    },[])

    return (

        <ThowColumLayoutLogin
            page={"ResetPassword"}
            colum1={
                <div style={containerFigure}>
                    <img src={forgotPasswordBackgrond} style={image} />
                </div>
            }

            colum2={

                <div style={containerColum2}>


                    <div style={container}>

                        <div style={containerLogo}>
                            <img src={companyIcon} />
                        </div>
                        <h2 className="textPrimaryResetPassword">Crie uma nova senha</h2>

                        <p className="textDescriptionResetPassword">Preencha os campos abaixo com a nova senha que deseja cadastrar</p>

                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "30px"
                        }}>

                            <InputLogin marginBottom={"12px"} placeholder={"Insira a nova senha"} name={"Nova senha"} error={paswordError} type={"password"} onChange={(e) => setPassword(e.target.value)} />

                            <InputLogin marginBottom={"12px"} placeholder={"Insira a confirmação da sua senha"} name={"Confirmação de nova senha"} type={"password"} error={paswordConfirmError} onChange={(e) => setPaswordConfirm(e.target.value)} />

                        </div>
                        {/*<Input name="Confirmação de nova senha" error={paswordError} onChange={(e) => setPassword(e.target.value)} placeholder="Insira a confirmação da sua senha" type={"password"} />
                        */}
                        <div style={boxFooterButton}>
                            <Button width="202px" height="48px" background="var(--color-primary)"
                                borderRadius="60px" marginTop="0" name="Criar senha"
                                border="none" disabled={btnState} onClick={() => storage()} />

                            <p onClick={() => navigate("/")} style={{
                                cursor: "pointer"
                            }} className='textForgotPassword'>Lembrou da senha?</p>
                        </div>
                    </div>

                </div>

            }


        >

        </ThowColumLayoutLogin>

    )

}

export default ResetPassword