import { useEffect, useState } from "react"
import iconListProject from "../../../images/Svg/iconListProject.svg"
import Button from "./Button"
import api from "../../../services/api"

const FooterOverview = ({card,id}) => {

    const [itemsCart2, setItemsCart2] = useState([])
    

    useEffect(() => {

        api.post(`checklist/component`, {

            "project_id": id,
            "mail": localStorage.getItem("@MAIL"),
        }).then((response) => {

            if (response.status == 400) {

                setItemsCart2([])
                return false
            }

            setItemsCart2(response.data)



        })


    }, [])

    
    useEffect(() => {

        api.post(`checklist/component`, {

            "project_id": id,
            "mail": localStorage.getItem("@MAIL"),
        }).then((response) => {

            if (response.status == 400) {

                setItemsCart2([])
                return false
            }

            console.log(response.data)

            setItemsCart2(response.data)



        })


    }, [id])

    return (
        <div style={{
            position: "fixed",
            bottom: "0",
            right: "0",
            left: "0",
            backgroundColor: "#fff",
            padding: "18px 19px",
            borderTop: "1px solid #E5E5E5",
            boxShadow: "1px 30px 33px 17px #000",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        }}>
            <div style={{
                display: "flex",
                gap: "24px",
                alignItems: "center"
            }}>
                <div style={{
                    height: "45px",
                    width: "45px",
                    border: "1px solid #D7D7D7",
                    borderRadius: "8px",
                    backgroundColor: "#F9F9FA",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "3px"
                }}>
                    <p className="textPriceCard">{itemsCart2.length}</p>
                    <img src={iconListProject} onClick={card} />
                </div>
            </div>

            <Button width="263px" height="40px" background="var(--color-primary)"
                borderRadius="60px" marginTop="0" name="Nova funcionalidade"
                border="none" />
        </div>
    )
}

export default FooterOverview