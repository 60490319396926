import closeIcon from "../../../images/Svg/closeIcon.svg";
import webModalIconOff from "../../../images/Svg/webModalIconOff.svg";
import webModalIconCheck from "../../../images/Svg/webModalIconCheck.svg";
import mobileModalIconOff from "../../../images/Svg/mobileModalIconOff.svg";
import mobileModalIconCheck from "../../../images/Svg/mobileModalIconCheck.svg";
import Input from "./Input";
import Button from "./Button";
import api from "../../../services/api";
import { toast } from "react-toastify";

const ModalCreateFeature = ({
  setListProjects,
  closeModal,
  namePlatform,
  setNamePlatform,
  checkWeb,
  setCheckWeb,
  checkMobilie,
  setCheckMobilie,
  listProjects
}) => {
  const onCheckMobile = () => {
    setCheckMobilie(true);
    setCheckWeb(false);
  };

  const onCheckWeb = () => {
    setCheckWeb(true);
    setCheckMobilie(false);
  };

  const onSubmitProject = async () => {
    if (!checkMobilie && !checkWeb) {
      return toast.error('Escolha o tipo da plataforma', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    const response = await api.post("project/project", {
      name: namePlatform,
      mail: localStorage.getItem("@MAIL"),
      description: "",
      velocity: "0",
      modalState: "",
      scale_service: "false",
      type_project: (checkMobilie && "mobile") || (checkWeb && "web"),
    });
    
    localStorage.setItem("@PROJECT_CHANGE_ID2", response.data.projectId);
    localStorage.setItem("@PROJECT_CHANGE_ID", response.data.projectId);
    window.location.href = `/functionalities?id=${response.data.projectId}`

    setListProjects([
      {
        name: namePlatform,
        type_project: (checkMobilie && "mobile") || (checkWeb && "web"),
      },
      ...listProjects
    ]);

    closeModal("Save");
    //window.location.href = `/functionalities?id=${response.data.projectId}`

  };

  const container = {
    backgroundColor: "#fff",
    width: "100%",
    height: "70%",
    border: "1px solid transparent",
    borderRadius: "20px 20px 0",
  };

  const boxHeader = {
    display: "flex",
    alignItems: "center",
    gap: "21px",
    padding: "22px 25px 17px",
    borderBottom: "1px solid #D7D7D7",
  };

  return (
    <div className="modalCreate" style={container}>
      <div style={boxHeader}>
        <img src={closeIcon} onClick={closeModal} />
        <h3 className="textTitleCreateModal">Nova plataforma</h3>
      </div>

      <div style={{ padding: "26px 24px", height: "100%", overflowY: "hidden" }}>
        <h3 className="textTitleCreateSelectModal">Obervação</h3>

        <p className="textDescriptionModalFeature">
          A plataforma mobile já ta incluso a versão android & IOS.
        </p>

        <div
          style={{
            marginTop: "19px",
            marginBottom: "40px"
          }}
        >
          <Input
            onChange={(e) => setNamePlatform(e.target.value)}
            name={"Nome"}
            placeholder={"Insira um nome ex: Painel do cliente"}
          />

          <div
            style={{
              margin: "20px 0 30px",
            }}
          >
            <h4 className="textTitleModalSelect">
              Selecione o tipo de plataforma
            </h4>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "14px",
              }}
            >
              <img
                onClick={onCheckWeb}
                src={checkWeb ? webModalIconCheck : webModalIconOff}
              />

              <img
                onClick={onCheckMobile}
                src={checkMobilie ? mobileModalIconCheck : mobileModalIconOff}
              />
            </div>
          </div>

          <Button
            width="100%"
            height="40px"
            background="var(--color-primary)"
            borderRadius="60px"
            marginTop="0"
            name="Salvar"
            border="none"
            onClick={onSubmitProject}
          />
        </div>
      </div>
    </div>
  );
};

export default ModalCreateFeature;
