import React, { useState } from 'react'

const Input = ({ name, type, placeholder, value, onChange, error }) => {

    const inputBox = {
        border: "1px solid transparent",
        borderRadius: "60px",
        backgroundColor: "#F8F8F8",
        outline: "none",
        height: "45px",
        padding: "0 20px"
    }
    return (
        <label className='textLabelInput' style={{ display: "flex", flexDirection: "column", gap: "14px", position: "relative",}}>
            {name}
            <input value={value} onChange={onChange} style={inputBox} type={type} placeholder={placeholder} />
            <p className="errorMessageLoginMobile">{error}</p>
        </label>
    )

}

export default Input