import closeIcon from "../../../images/Svg/closeIcon.svg";
import starIconMobile from "../../../images/Svg/starIconMobile.svg";
import createProjectIcon from "../../../images/Svg/createProjectIcon.svg";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";

const ModalCreateProject = ({ closeModal }) => {
  const navigate = useNavigate();

  const container = {
    backgroundColor: "#fff",
    width: "100%",
    height: "70%",
    border: "1px solid transparent",
    borderRadius: "20px 20px 0",
  };

  const boxHeader = {
    display: "flex",
    alignItems: "center",
    gap: "21px",
    padding: "22px 25px 17px",
    borderBottom: "1px solid #D7D7D7",
  };

  const boxContent = {
    border: "1px solid #D7D7D7",
    borderRadius: "10px",
    marginTop: "25px",
    padding: "19px 21px",
  };

  const cardContainer = {
    display: "flex",
    gap: "17px",
    alignItems: "center",
    justifyContent: "flex-start",
    borderBottom: "1px solid #D7D7D7",
    paddingBottom: "19px",
    marginBottom: "14px",
    position: "relative",
  };

  const cardContainer2 = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #D7D7D7",
    paddingBottom: "19px",
    marginBottom: "14px",
    position: "relative",
  };

  const boxHeaderCard = {
    display: "flex",
    gap: "17px",
    alignItems: "center",
    justifyContent: "flex-start",
  };

  const getLastProjectId = async () => {

    const response = await api.post("project/last/", {
      mail: localStorage.getItem("@MAIL")
    })

    window.location.href = `/functionalities/?id=${response.data.id}`


  }

  return (
    <div className="modalCreate" style={container}>
      <div style={boxHeader}>
        <img src={closeIcon} onClick={closeModal} />
        <h3 className="textTitleCreateModal">Criar novo projeto</h3>
      </div>

      <div style={{ padding: "26px 24px", height: "75%", overflowY: "auto" }}>
        <h3 className="textTitleCreateSelectModal">
          Selecione como deseja fazer seu projeto
        </h3>

        <div style={boxContent} onClick={() => getLastProjectId()}>
          <div style={cardContainer}>
            <img src={starIconMobile} />
            <h4 className="textTitleCardOptionModalCreate">Fazemos por você</h4>
          </div>

          <p className="textDescriptionCardOptionModalCreate">
            Vamos criar e personaliza as funcionalidades e você acompanhara o
            andamento do seu projeto em tempo real através do nosso painel.
          </p>
        </div>

        <div style={boxContent}>
          <div style={cardContainer2}>
            <div style={boxHeaderCard}>
              <img src={createProjectIcon} />
              <h4 className="textTitleCardOptionModalCreate">
                Faça você mesmo
              </h4>
            </div>
            <p className="textShortly">Em breve</p>
          </div>

          <p className="textDescriptionCardOptionModalCreate">
            Vamos criar e personaliza as funcionalidades e você acompanhara o
            andamento do seu projeto em tempo real através do nosso painel.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ModalCreateProject;
