import { useNavigate } from "react-router-dom";
import btnBackIcon from "../../../images/png/back.png"
import CardProject from "../components/CardProject"
import InputSearch from "../components/InputSearch"
import { useEffect, useState } from "react";
import api from "../../../services/api";

const Search = () => {
    const [projects, setProjects] = useState()
    const navigate = useNavigate();
    const [name, setName] = useState()

    const getProjects = async () => {

        const response = await api.post("project/pay", {

            mail: localStorage.getItem("@MAIL")

        })
        setProjects(response.data)


    }

    const getProject = async (e) => {
        setName(e);
        const response = await api.post("project/by/name/", {
            name: e,
            mail: localStorage.getItem("@MAIL"),
        });
        if (response.status == 400) {
            setProjects([]);
            return false;
        }

        setProjects(response.data);
    };

    useEffect(() => {
        getProjects()
    }, [])

    useEffect(() => {
        if (name == "") {
            getProjects();
        }
    }, [name]);

    const container = {
        padding: "19px 0",
        backgroundColor: "#E5E5E520"
    }

    const boxHeader = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 23px",
    }



    return (
        <div style={container}>
            <div style={boxHeader} >
                <img src={btnBackIcon} onClick={() => navigate("/dashboard")} />

                <InputSearch onChange={(e) => getProject(e.target.value)} />
            </div>

            {
                projects?.length > 0 &&
                projects.map((item) => {
                    return (
                        <>
                            <CardProject
                                id={item.id}
                                projectLenght={projects.length}
                                name={item.name}
                                global_id={item.global_id}
                                project_id={item.project_id}
                                type_project={item.type_project}
                            />
                        </>
                    );
                })}


        </div>
    )
}

export default Search