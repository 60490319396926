import React,{useState,useEffect} from 'react'
import api from '../../services/api'
import { useNavigate } from 'react-router-dom'
const Card = ({id,project_id,type_project,projectLenght,global_id}) => {

    const [total,setTotal] = useState(0)
    const [dueDay,setDueDay] = useState(0)
    const [globalName,setGlobalName] = useState()

    const [checkComponents,setCheckComponents] = useState()
    const [notCheck,setNotCheck] = useState()
    const [projectIsDesktopAndMobile,setProjectIsDesktopAndMobile] = useState()

    const [projectIsWeb,setProjectIsWeb] = useState()
    const [projectIsMobile,setProjectIsMobile] = useState()

    const navigate = useNavigate()

      const card = {

          width:"360px",
          height:"470px",
          border:"1px solid #d7d7d7",
          borderRadius:"15px",
          marginBottom:"4.9rem"

      }
      const cardThow = {

        width:"360px",
        height:"470px",
        border:"1px solid #d7d7d7",
        borderRadius:"15px",
        marginBottom:"4.9rem",
        marginRight:"90px"
      }
      const headerCard = {
      }
      const infoHeader = {

          paddingTop:"1.375rem",
          paddingLeft:"1.313rem",
          borderBottom:"1px solid #d7d7d7",
      }
      const features = {

          display:"flex",
          alignItems:"center",
          marginTop:"1.678rem",


      }
      const infoFeature = {
          border:"1px solid #d7d7d7",
          width:"145.71px",
          paddingTop:"0.875rem",
          paddingLeft:"0.997rem",
          paddingBottom:"0.688rem",

          height:"70px",
          borderRadius:"10px",
      }
      const infoDateFeature = {

          border:"1px solid #d7d7d7",
          width:"145.71px",
          paddingTop:"0.875rem",
          paddingLeft:"0.997rem",
          paddingBottom:"0.688rem",
          height:"70px",
          borderRadius:"10px",
          marginLeft:"1.662rem"
      }
      const btn = {

          width:"319.09px",
          height:"40px",
          background:"#1172EB",
          color:"white",
          border:"none",
          outline:"none",
          borderRadius:"60px",
          marginTop:"1.938rem"
      }
    
      const getPercentage = async () => {

        const response = await api.post("dashboard/total",{
  
          mail:localStorage.getItem("@MAIL"),
          global_id:global_id
        })
    
        setTotal(response.data.total)
    
      }
      const getDueDay = async () => {

        const response = await api.get(`duedates/${project_id}`)

        setDueDay(response.data.finalDate)
      }
      const getProject = async () => {
  
        const project = localStorage.getItem("@PROJECT_CHANGE_ID_FOLLOW_PAGE")
  
          const response = await api.post("global/project/info",{
    
            mail:localStorage.getItem("@MAIL"),
            project_id:project_id
          })
          setGlobalName(response.data[0].name_project)
  
        
  
      }
      const typesProjects = async () => {

        const response = await api.post(`global/types/project`,{
  
          global_id:global_id
          
  
        })
  
  
        if(response.data.totalWeb != 0 && response.data.totalMobile != 0){
  
          setProjectIsDesktopAndMobile(true)
  
        }else{
  
          setProjectIsDesktopAndMobile(false)
        }
  
        if(response.data.totalWeb != 0){
          setProjectIsWeb(response.data.totalWeb)
  
        }else{
  
          setProjectIsWeb(0)
        }
  
  
        if(response.data.totalMobile != 0){
          setProjectIsMobile(response.data.totalMobile)
        }else{
  
          setProjectIsMobile(0)
        }
        
      }
      const redirectToFollowPage = async (project_id,type_project,global_id) => {  
        localStorage.setItem("@PROJECT_CHANGE_ID_FOLLOW_PAGE",project_id)
        localStorage.setItem("@PROJECT_CHANGE_ID",project_id)
        localStorage.setItem("@GLOBAL_ID_PROJECT2",global_id)        
        localStorage.setItem("@TYPE_PROJECT_FOLLOW_PAGE",type_project)
        localStorage.setItem("@PROJECT_DUE_DAY",dueDay)

        window.location.href=`/follow/?id=${project_id}`
         
      }
      const getComponentsCheck = async () => {

        const response = await api.post("checklist/count/card",{
          "project_id":project_id
        })

        setCheckComponents(response.data.total)
        setNotCheck(response.data.all)
        
      }

      useEffect(() => {
        getDueDay()
        getPercentage()
        getProject()
        getComponentsCheck()
        typesProjects()  
      },[id,project_id])

      useEffect(() => {
        getComponentsCheck()
      },[])
         
    return(

        
        <div style={projectLenght == 2 ? cardThow : card}>

        <div style={headerCard}>
        
        <div style={infoHeader}>
        <p className="nameProjectInfoHeaderCard">Nome do projeto</p>
        
        <div style={{display:"flex",alignItems:"center"}}>
        <p className="nameSecondaryProjectInfoHeaderCard">{globalName != undefined ? globalName :"Novo Projeto"}</p>
      
        </div>

        </div>
        
        <div style={{paddingLeft:"1.313rem"}}>

        <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",width:"310px",marginTop:"1.875rem"}}>
    


        <p  className="textInDevelopementCard" >Em desenvolvimento</p>
        <p className="percentageDevelopementCard">{Number(total).toFixed(0)}%</p>
        </div>
        <div class="progress" style={{width:"310px",height:'8px'}}>
            
        <div class="progress-bar" role="progressbar" style={{width: `${Number(total).toFixed(0)}%`,height:'8px'}} aria-valuenow={Number(total).toFixed(0)} aria-valuemin="0" ar6ia-valuemax="100"></div>
        </div>
        
        <div style={{marginTop:"2.438rem"}}>
     
     

        {projectIsWeb != 0 && projectIsMobile != 0 
        
        ?
        <div style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
        <svg width="129" height="50" viewBox="0 0 129 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="55.81" height="48.15" rx="9.5" fill="#FDFDFD"/>
        <path d="M41.9426 29.0838V19.3056C41.9405 17.5013 41.2228 15.7715 39.9469 14.4957C38.6711 13.2199 36.9414 12.5022 35.1371 12.5H21.526C19.7217 12.5022 17.9919 13.2199 16.7161 14.4957C15.4403 15.7715 14.7226 17.5013 14.7204 19.3056V29.0838C13.813 29.4034 13.048 30.0338 12.5608 30.8635C12.0737 31.6931 11.8959 32.6684 12.0589 33.6165C12.2219 34.5647 12.7153 35.4245 13.4515 36.0438C14.1878 36.6631 15.1195 37.0018 16.0815 37H40.5815C41.5436 37.0018 42.4753 36.6631 43.2115 36.0438C43.9478 35.4245 44.4411 34.5647 44.6041 33.6165C44.7672 32.6684 44.5894 31.6931 44.1022 30.8635C43.6151 30.0338 42.8501 29.4034 41.9426 29.0838ZM21.526 15.2222H35.1371C36.22 15.2222 37.2587 15.6524 38.0244 16.4182C38.7902 17.184 39.2204 18.2226 39.2204 19.3056V28.8333H33.5704C33.1746 28.8331 32.7834 28.9192 32.4242 29.0856C32.065 29.252 31.7464 29.4948 31.4907 29.797L31.1545 30.1944H25.5072L25.1724 29.797C24.9166 29.4948 24.5981 29.252 24.2389 29.0856C23.8797 28.9192 23.4885 28.8331 23.0926 28.8333H17.4426V19.3056C17.4426 18.2226 17.8728 17.184 18.6386 16.4182C19.4044 15.6524 20.443 15.2222 21.526 15.2222ZM40.5815 34.2778H16.0815C15.7205 34.2778 15.3743 34.1344 15.1191 33.8791C14.8638 33.6239 14.7204 33.2776 14.7204 32.9167C14.7204 32.5557 14.8638 32.2095 15.1191 31.9542C15.3743 31.699 15.7205 31.5555 16.0815 31.5555H23.094L23.4288 31.953C23.6844 32.255 24.0028 32.4977 24.3617 32.6641C24.7207 32.8305 25.1116 32.9167 25.5072 32.9167H31.1545C31.5504 32.9169 31.9415 32.8308 32.3007 32.6644C32.6599 32.498 32.9785 32.2552 33.2342 31.953L33.5704 31.5555H40.5815C40.9425 31.5555 41.2887 31.699 41.544 31.9542C41.7992 32.2095 41.9426 32.5557 41.9426 32.9167C41.9426 33.2776 41.7992 33.6239 41.544 33.8791C41.2887 34.1344 40.9425 34.2778 40.5815 34.2778Z" fill="black"/>
        <rect x="0.5" y="0.5" width="55.81" height="48.15" rx="9.5" stroke="#D7D7D7"/>
        <rect x="72.3105" y="0.5" width="55.81" height="48.15" rx="9.5" fill="#FDFDFD"/>
        <rect x="72.3105" y="0.5" width="55.81" height="48.15" rx="9.5" stroke="#D7D7D7"/>
        <path d="M103.677 37.25H96.8646C93.7342 37.25 91.1875 34.7033 91.1875 31.5729V15.6771C91.1875 12.5467 93.7342 10 96.8646 10H103.677C106.807 10 109.354 12.5467 109.354 15.6771V31.5729C109.354 34.7033 106.807 37.25 103.677 37.25ZM96.8646 12.2708C94.9866 12.2708 93.4583 13.7991 93.4583 15.6771V31.5729C93.4583 33.4509 94.9866 34.9792 96.8646 34.9792H103.677C105.555 34.9792 107.083 33.4509 107.083 31.5729V15.6771C107.083 13.7991 105.555 12.2708 103.677 12.2708H96.8646ZM102.542 31.5729C102.542 30.9462 102.033 30.4375 101.406 30.4375H99.1354C98.5087 30.4375 98 30.9462 98 31.5729C98 32.1997 98.5087 32.7083 99.1354 32.7083H101.406C102.033 32.7083 102.542 32.1997 102.542 31.5729Z" fill="black"/>
        </svg>
        </div>
        :
        <></>
        }

        {projectIsWeb != 0 && projectIsMobile == 0 && projectIsDesktopAndMobile == false
        
        ?
        <div style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
        <svg width="129" height="50" viewBox="0 0 129 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="55.81" height="48.15" rx="9.5" fill="#FDFDFD"/>
        <path d="M41.9426 29.0838V19.3056C41.9405 17.5013 41.2228 15.7715 39.9469 14.4957C38.6711 13.2199 36.9414 12.5022 35.1371 12.5H21.526C19.7217 12.5022 17.9919 13.2199 16.7161 14.4957C15.4403 15.7715 14.7226 17.5013 14.7204 19.3056V29.0838C13.813 29.4034 13.048 30.0338 12.5608 30.8635C12.0737 31.6931 11.8959 32.6684 12.0589 33.6165C12.2219 34.5647 12.7153 35.4245 13.4515 36.0438C14.1878 36.6631 15.1195 37.0018 16.0815 37H40.5815C41.5436 37.0018 42.4753 36.6631 43.2115 36.0438C43.9478 35.4245 44.4411 34.5647 44.6041 33.6165C44.7672 32.6684 44.5894 31.6931 44.1022 30.8635C43.6151 30.0338 42.8501 29.4034 41.9426 29.0838ZM21.526 15.2222H35.1371C36.22 15.2222 37.2587 15.6524 38.0244 16.4182C38.7902 17.184 39.2204 18.2226 39.2204 19.3056V28.8333H33.5704C33.1746 28.8331 32.7834 28.9192 32.4242 29.0856C32.065 29.252 31.7464 29.4948 31.4907 29.797L31.1545 30.1944H25.5072L25.1724 29.797C24.9166 29.4948 24.5981 29.252 24.2389 29.0856C23.8797 28.9192 23.4885 28.8331 23.0926 28.8333H17.4426V19.3056C17.4426 18.2226 17.8728 17.184 18.6386 16.4182C19.4044 15.6524 20.443 15.2222 21.526 15.2222ZM40.5815 34.2778H16.0815C15.7205 34.2778 15.3743 34.1344 15.1191 33.8791C14.8638 33.6239 14.7204 33.2776 14.7204 32.9167C14.7204 32.5557 14.8638 32.2095 15.1191 31.9542C15.3743 31.699 15.7205 31.5555 16.0815 31.5555H23.094L23.4288 31.953C23.6844 32.255 24.0028 32.4977 24.3617 32.6641C24.7207 32.8305 25.1116 32.9167 25.5072 32.9167H31.1545C31.5504 32.9169 31.9415 32.8308 32.3007 32.6644C32.6599 32.498 32.9785 32.2552 33.2342 31.953L33.5704 31.5555H40.5815C40.9425 31.5555 41.2887 31.699 41.544 31.9542C41.7992 32.2095 41.9426 32.5557 41.9426 32.9167C41.9426 33.2776 41.7992 33.6239 41.544 33.8791C41.2887 34.1344 40.9425 34.2778 40.5815 34.2778Z" fill="black"/>
        <rect x="0.5" y="0.5" width="55.81" height="48.15" rx="9.5" stroke="#D7D7D7"/>
        <rect x="72.3105" y="0.5" width="55.81" height="48.15" rx="9.5" fill="#FDFDFD"/>
     
        </svg>
        </div>
        :
        projectIsWeb == 0 && projectIsMobile != 0 && projectIsDesktopAndMobile == false
        
        ?

        <div style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>

        <svg width="129" height="50" viewBox="0 0 129 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="55.81" height="48.15" rx="9.5" fill="#FDFDFD"/>
        <rect x="0.5" y="0.5" width="55.81" height="48.15" rx="9.5" stroke="#D7D7D7"/>
        <path d="M31.8685 37.25H25.056C21.9256 37.25 19.3789 34.7033 19.3789 31.5729V15.6771C19.3789 12.5467 21.9256 10 25.056 10H31.8685C34.9988 10 37.5456 12.5467 37.5456 15.6771V31.5729C37.5456 34.7033 34.9988 37.25 31.8685 37.25ZM25.056 12.2708C23.178 12.2708 21.6497 13.7991 21.6497 15.6771V31.5729C21.6497 33.4509 23.178 34.9792 25.056 34.9792H31.8685C33.7465 34.9792 35.2747 33.4509 35.2747 31.5729V15.6771C35.2747 13.7991 33.7465 12.2708 31.8685 12.2708H25.056ZM30.7331 31.5729C30.7331 30.9462 30.2244 30.4375 29.5977 30.4375H27.3268C26.7001 30.4375 26.1914 30.9462 26.1914 31.5729C26.1914 32.1997 26.7001 32.7083 27.3268 32.7083H29.5977C30.2244 32.7083 30.7331 32.1997 30.7331 31.5729Z" fill="black"/>
   
        </svg>
        </div>

        :
        <></>
        

        }
    

        </div>

        

        <div style={features}>

        <div style={infoFeature}>
        <p className="textNumberFeaturesCard">N° Features</p>
        <p className="textCountNumberFeaturesCard">

        {notCheck}/{checkComponents}
      
        </p>
        </div>

        <div style={infoDateFeature}>
        <p className="textDateCard">Entrega</p>
        <p className="textNumberDateCard">{dueDay}</p>
        </div>

        </div>

        <button className="textBtnCard" onClick={() => redirectToFollowPage(project_id,type_project,global_id)} style={btn}>Acompanhar projeto</button>

        </div>

        </div>

        </div>

    )

}

export default Card