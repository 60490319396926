import React, { useState, useEffect, useContext } from "react";

import ThreeColumLayoutGeneralPage from "../../layouts/ThreeColumLayoutGeneralPage";
import iconStartpnScale from "../../images/Svg/iconStartpnScale.svg";
import api from "../../services/api";

import ModalCreateCustomComponent from "../../components/ModalCreateCustomComponent";
import InfoProject from "../../components/InfoProject";

import { ComponentContext } from "../../context/component";

import "skeleton-screen-css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import "skeleton-screen-css";

const GeneralDefinitionUpdate = () => {
  const [items, setItems] = useState([]);
  const [image, setImage] = useState();
  const [nameComponent, setNameComponent] = useState();

  const [nameProject, setNameProject] = useState();
  const [projects, setProjects] = useState([]);
  const [typeProject, setTypeProject] = useState();

  const [nameProjectChange, setNameProjectChange] = useState();
  const [typeProjectChange, setTypeProjectChange] = useState();

  const { imageBase64, name, animation, description, developTime, price } =
    useContext(ComponentContext);

  /* */

  const [showCustomModal, setShowCustomModal] = useState(false);
  const [velocity, setVelocity] = useState(2);
  const [scaleService, setScaleService] = useState(false);

  const [priceTotal, setPriceTotal] = useState(0);
  const [totalDays, setTotalDays] = useState(0);
  const [valueProjet, setValueProject] = useState(0);

  const [loading, setLoading] = useState(false);
  const [valueScaleService, setValueScaleService] = useState(0);
  const [valueToScaleService, setValueToScaleService] = useState();

  const [localScaleService, setLocalScaleService] = useState();

  const containerVelocityProject = {
    background: "white",

    height: "100vh",

    paddingTop: "110px",
  };

  const footer = {
    position: "fixed",
    bottom: 0,
    width: "100%",
    height: "89px",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "10rem",
    paddingRight: "4.3rem",
    alignItems: "center",
    borderTop: "1px solid #e6e6e6",
    zIndex: "1",
    background: "white",
  };

  const btnFinish = {
    width: "170px",
    display: "flex",
    paddingTop: "0.9rem",
    alignItems: "center",
    justifyContent: "center",
    height: "40px",
    background: "#1172EB",
    borderRadius: "60px",
    border: "none",
    outline: "none",
  };

  const getAllCategorys = async () => {
    const response = await api.get("categorys/get/all")

    setItems(response.data);
  };

  const loadImage = async () => {
    setImage(imageBase64);
    setNameComponent(name);
  };

  const getProject = async () => {
    const project = localStorage.getItem("@PROJECT_CHANGE_ID");

    if (project) {
      const response = await api.post("project/info", {
        mail: localStorage.getItem("@MAIL"),
        project_id: project,
      });

      localStorage.setItem("@TYPE_PROJECT", response.data[0].type_project);

      setTypeProjectChange(response.data[0].type_project);
      setNameProjectChange(response.data[0].name);
    }
  };

  const getInfoProject = async (changeToId) => {
    if (changeToId != undefined) {
      const response = await api.post("project/info", {
        mail: localStorage.getItem("@MAIL"),
        project_id: changeToId,
      });

      setTypeProject(response.data[0].type_project);
      setNameProject(response.data[0].name);

      localStorage.setItem("@PROJECT_CHANGE_ID", changeToId);
      window.location.href = "/functionalities";

      return false;
    }

    const responseInfo = await api.get(
      `project/all/${localStorage.getItem("@MAIL")}`
    );

    const response = await api.post("project/info", {
      mail: localStorage.getItem("@MAIL"),
      project_id: responseInfo.data[0].project_id,
    });

    setTypeProject(response.data[0].type_project);
    setNameProject(response.data[0].name);
  };

  const getProjects = async () => {
    const response = await api.post(`project/pay/by/id`,{
        project_id:localStorage.getItem("@PROJECT_ID_UPDATE")
    });

    setProjects(response.data);
  };

  const redirectToPaymentPage = () => {
    localStorage.setItem("@TYPE_PAYMENT","update")
    window.location.href="/payment"
  }

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    getAllCategorys();
  }, []);

  useEffect(() => {
    getInfoProject();
  }, []);

  useEffect(() => {
    loadImage();
  }, [imageBase64]);

  useEffect(() => {
    getProject();
  }, []);

  const updateVelocity = async () => {
    if (velocity == 3) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, [2000]);

      const response = await api.post("cart/all/value/pay", {
        mail: localStorage.getItem("@MAIL"),
      });

      console.log(response.data);

      setPriceTotal(response.data.price * 2);
      setTotalDays(response.data.developTime / 2);

      localStorage.setItem("@TOTAL_VALUE_PROJECT", response.data.price * 2);
      localStorage.setItem(
        "@DEVELOPT_TIME_PROJECT",
        response.data.developTime / 2
      );

      const updateVelocity = await api.put("project/velocity", {
        mail: localStorage.getItem("@MAIL"),
        velocity: "3",
      });
    }

    if (velocity == 2) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, [2000]);

      const response = await api.post("cart/all/value/pay", {
        mail: localStorage.getItem("@MAIL"),
      });



      setValueProject(response.data.price);
      setPriceTotal(response.data.price);
      setTotalDays(response.data.developTime);

      localStorage.setItem("@TOTAL_VALUE_PROJECT", response.data.price);
      localStorage.setItem("@DEVELOPT_TIME_PROJECT", response.data.developTime);

      const updateVelocity = await api.put("project/velocity", {
        mail: localStorage.getItem("@MAIL"),
        velocity: "2",
      });
    }

    if (velocity == 1) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, [2000]);

      

      const response = await api.post("cart/all/value/pay", {
        mail: localStorage.getItem("@MAIL"),
      });

      setPriceTotal(response.data.price / 2);
      setTotalDays(response.data.developTime * 2);

      localStorage.setItem("@TOTAL_VALUE_PROJECT", response.data.price / 2);
      localStorage.setItem(
        "@DEVELOPT_TIME_PROJECT",
        response.data.developTime * 2
      );

      const updateVelocity = await api.put("project/velocity", {
        mail: localStorage.getItem("@MAIL"),
        velocity: "1",
      });
    }
  };

  const startpnScale = async () => {
    if (velocity == 3) {
      const response = await api.post("cart/all/value/pay", {
        mail: localStorage.getItem("@MAIL"),
      });

      const calc = Number(response.data.price) * 2;
      const total = (calc * 1) / 100;

      setValueToScaleService(calc + total);
      setValueScaleService(total.toFixed(2));
      localStorage.setItem("@SCALE_SERVICE", total.toFixed(2));
    }

    if (velocity == 2) {
      const response = await api.post("cart/all/value/pay", {
        mail: localStorage.getItem("@MAIL"),
      });

      const calc = Number(response.data.price);
      const total = (calc * 1) / 100;

      setValueToScaleService(calc + total);
      setValueScaleService(total.toFixed(2));
      localStorage.setItem("@SCALE_SERVICE", total.toFixed(2));
    }

    if (velocity == 1) {
      const response = await api.post("cart/all/value/pay", {
        mail: localStorage.getItem("@MAIL"),
      });

      const calc = Number(response.data.price) / 2;
      const total = (calc * 1) / 100;

      setValueToScaleService(calc + total);
      setValueScaleService(total.toFixed(2));
      localStorage.setItem("@SCALE_SERVICE", total.toFixed(2));
    }
  };

  const startpnScaleService = async () => {
    const response = await api.put("project/scale/service", {
      scale_service: !scaleService,
      mail: localStorage.getItem("@MAIL"),
    });

    localStorage.setItem("@SCALE_SERVICE_ENABLE", !scaleService);
  };

  useEffect(() => {
    setLocalScaleService(localStorage.getItem("@SCALE_SERVICE_ENABLE"));
  }, []);

  useEffect(() => {
  }, [localScaleService]);

  useEffect(() => {
    updateVelocity();
  }, [velocity]);

  useEffect(() => {
    startpnScale();
  }, [scaleService, velocity]);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "//code.tidio.co/nstq0ldng4h7bbhdiwcexf7r0jlhp2ns.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <ToastContainer />

      <ThreeColumLayoutGeneralPage
        typeHeader={"definicao"}
        colum2Data={
          <div
            style={{
              display: "flex",
              height: "90vh",
              overflowX: "hidden",
              width: "100%",
            }}
          >
            <div style={containerVelocityProject}>
              <div
                style={{
                  border: "1px solid #dcdcdc",
                  width: "390px",
                  height: "159px",
                  borderRadius: "15px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <p className="textVelocityProject">Velocidade de entrega</p>
                <input
                  type="range"
                  min="1"
                  step="1"
                  defaultValue="2"
                  onChange={(e) => setVelocity(e.target.value)}
                  max="3"
                  className="input_range"
                  id="myRange"
                />

                {velocity == 1 ? (
                  <p className="textTypeVelocity">Lento</p>
                ) : (
                  <></>
                )}

                {velocity == 2 ? (
                  <p className="textTypeVelocity">Normal</p>
                ) : (
                  <></>
                )}

                {velocity == 3 ? (
                  <p className="textTypeVelocity">Rápido</p>
                ) : (
                  <></>
                )}
              </div>

              <div
                style={{
                  border: "1px solid #dcdcdc",
                  width: "390px",
                  marginTop: "1.9rem",
                  height: "461px",
                  borderRadius: "15px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setScaleService(!scaleService);
                  setLocalScaleService(!localScaleService);
                  startpnScaleService();
                }}
              >
                <div
                  style={{
                    display: "flex",
                    paddingTop: "1.438rem",
                    paddingLeft: "1.313rem",
                    justifyContent: "space-between",
                    marginRight: "1.25rem",
                  }}
                >
                  <img src={iconStartpnScale} />

                  {scaleService == true ? (
                    <svg
                      width="25"
                      height="26"
                      viewBox="0 0 25 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse
                        cx="12.4886"
                        cy="12.59"
                        rx="12.4886"
                        ry="12.59"
                        fill="#1172EB"
                      />
                      <path
                        d="M18.0403 8.39294L10.4084 16.0868L6.93933 12.5896"
                        stroke="white"
                        stroke-width="1.6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="25"
                      height="26"
                      viewBox="0 0 25 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24.4772 12.59C24.4772 19.2709 19.1059 24.68 12.4886 24.68C5.87129 24.68 0.5 19.2709 0.5 12.59C0.5 5.90906 5.87129 0.5 12.4886 0.5C19.1059 0.5 24.4772 5.90906 24.4772 12.59Z"
                        fill="white"
                        stroke="#1172EB"
                      />
                      <rect
                        x="4"
                        y="4"
                        width="16.6515"
                        height="16.7866"
                        rx="8.32576"
                        fill="white"
                      />
                      <path
                        d="M17.8764 8.19665L10.2445 15.8905L6.77539 12.3933"
                        stroke="white"
                        stroke-width="1.6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                </div>

                <div style={{ paddingLeft: "1.313rem", marginTop: "2.125rem" }}>
                  <p className="textDescriptionStartpnScale">
                    30% de desconto na adição de novas funcionalidades;
                  </p>
                  <p className="textDescriptionStartpnScale">
                    Patches de segurança e Gerenciamento de Backup;
                  </p>
                  <p className="textDescriptionStartpnScale">
                    Correções de bugs do seu aplicativo & software;
                  </p>
                  <p className="textDescriptionStartpnScale">
                    Equipe multidisciplinar para atender você ou sua equipe em
                    qualquer tipo de demanda.
                  </p>
                </div>

                <div
                  style={{
                    paddingLeft: "1.313rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "2.75rem",
                  }}
                >
                  <p className="textValueStartPnScale">
                    R$ {String(valueScaleService).replace(".", ",")} /Mês
                  </p>
                  <a
                    href="https://www.startpn.com/solucao/startpn-scale"
                    target="_blank"
                  >
                    <button
                      className="textBtnStartPnScale"
                      style={{
                        marginTop: "-17px",
                        width: "140px",
                        height: "40px",
                        background: "#1172EB",
                        border: "none",
                        borderRadius: "60px",
                        marginRight: "20px",
                      }}
                    >
                      Saiba mais
                    </button>
                  </a>
                </div>
                <div style={{ height: "12px" }}></div>
              </div>

              <div style={{ height: "120px" }}></div>
            </div>

            <div
              style={{ background: "white", height: "770px", width: "30px" }}
            >
              asdsad
            </div>

            <div
              style={{
                width: "100%",
                overflowX: "auto",
                display: "flex",
                height: "770px",
                paddingRight: "3rem",
              }}
            >
              {projects.map((item) => {
                return (
                  <InfoProject
                    id={item.id}
                    page="update"
                    desktop_icon={item.desktop_icon}
                    mobile_icon={item.mobile_icon}
                    project_id={item.project_id}
                    name={item.name}
                    type_project={item.type_project}
                  />
                );
              })}
            </div>
            <div></div>
          </div>
        }
        colum3Data={
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                overflowY: "auto",
              }}
            >
              {animation == true ? (
                <div>
                  <div
                    class="ssc ssc-card"
                    style={{ width: "480px", height: "520px" }}
                  >
                    <div class="ssc-wrapper">
                      <div class="ssc-square mb"></div>
                      <div class="flex align-center justify-between">
                        <div class="w-40">
                          <div class="ssc-line w-70 mbs"></div>
                          <div class="ssc-line w-100 mbs"></div>
                        </div>
                        <div class="ssc-head-line w-50"></div>
                      </div>
                    </div>
                    <div class="ssc-hr"></div>
                    <div class="ssc-wrapper">
                      <div class="ssc-line w-90 mbs"></div>
                      <div class="ssc-line w-30 mbs"></div>
                      <div class="ssc-line w-70 mbs"></div>
                      <div class="ssc-line w-50 mbs"></div>
                    </div>
                    <div class="ssc-hr"></div>
                    <div class="ssc-wrapper">
                      <div class="ssc-head-line"></div>
                    </div>
                  </div>
                </div>
              ) : image == undefined ? (
                <></>
              ) : localStorage.getItem("@TYPE_PROJECT") == "web" ? (
                <img
                  style={{
                    objectFit: "contain",
                    marginTop: "10rem",
                    width: "503px",
                    height: "300px",
                    borderRadius: "6px",
                    border: "2px solid #1172EB",
                  }}
                  src={"data:image/png;base64," + image}
                />
              ) : (
                <img
                  style={{
                    objectFit: "contain",
                    width: "166px",
                    height: "360px",
                    border: "2px solid #1172EB",
                    borderRadius: "6px",
                    marginTop: "10rem",
                  }}
                  src={"data:image/png;base64," + image}
                />
              )}

              {animation == true ? (
                <></>
              ) : image != undefined ? (
                <>
                  <p className="textNameImageComponent">{nameComponent}</p>

                  <div style={{ width: "520px" }}>
                    <p
                      className="textPreviewComponent"
                      style={{ textAlign: "center" }}
                    >
                      {description}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "220px",
                      marginBottom: "2.5rem",
                    }}
                  >
                    <div>
                      <p className="textInfoProjectTimePrimary">Tempo</p>
                      {loading == true ? (
                        <div
                          class="ssc-line w-100 "
                          style={{ marginBottom: "15px" }}
                        ></div>
                      ) : (
                        <p className="textInfoProjectTimeSecondary">
                          {totalDays == 0 ? developTime : totalDays} Dias
                        </p>
                      )}
                    </div>

                    <svg
                      width="2"
                      height="59"
                      viewBox="0 0 2 59"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.20898 59L1.20898 7.74861e-07"
                        stroke="#C1C1C1"
                        stroke-width="0.8"
                      />
                    </svg>

                    <div>
                      <p className="textInfoProjectTimePrimary">Custo fixo</p>
                      <p className="textInfoProjectTimeSecondary">
                        {loading == true ? (
                          <div
                            class="ssc-line w-100 "
                            style={{ marginBottom: "15px" }}
                          ></div>
                        ) : price != undefined ? (
                          String(price).replace(".", ",")
                        ) : (
                          <></>
                        )}
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>

            <footer style={footer}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "580px",
                  paddingTop: "0.438rem",
                }}
              >
                <div>
                  <p className="textValueProject">Valor</p>
                  <p className="textInfoProject">
                    {loading == true ? (
                      <div
                        class="ssc-line w-100 "
                        style={{ marginBottom: "15px" }}
                      ></div>
                    ) : (
                      valueProjet.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })
                    )}
                  </p>
                </div>

                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.125 6.125H7.875V0.875C7.875 0.391754 7.48325 0 7 0C6.51675 0 6.125 0.391754 6.125 0.875V6.125H0.875C0.391754 6.125 0 6.51675 0 7C0 7.48325 0.391754 7.875 0.875 7.875H6.125V13.125C6.125 13.6082 6.51675 14 7 14C7.48325 14 7.875 13.6082 7.875 13.125V7.875H13.125C13.6082 7.875 14 7.48325 14 7C14 6.51675 13.6082 6.125 13.125 6.125Z"
                    fill="black"
                  />
                </svg>

                <div>
                  <p className="textValueProject">Startpn Scale</p>
                  <p
                    className="textInfoProject"
                    style={{ textAlign: "center" }}
                  >
                    {loading == true ? (
                      <div
                        class="ssc-line w-100 "
                        style={{ marginBottom: "15px" }}
                      ></div>
                    ) : scaleService == true ? (
                      `R$ ${Number(valueScaleService)
                        .toFixed(2)
                        .replace(".", ",")}`
                    ) : (
                      "0,00"
                    )}{" "}
                  </p>
                </div>

                <svg
                  width="15"
                  height="8"
                  viewBox="0 0 15 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.0625 2.84131H0.9375C0.419736 2.84131 0 2.39359 0 1.84131C0 1.28903 0.419736 0.841309 0.9375 0.841309H14.0625C14.5803 0.841309 15 1.28903 15 1.84131C15 2.39359 14.5803 2.84131 14.0625 2.84131Z"
                    fill="#6B6B6B"
                  />
                  <path
                    d="M14.0625 7.15869H0.9375C0.419736 7.15869 0 6.71097 0 6.15869C0 5.60641 0.419736 5.15869 0.9375 5.15869H14.0625C14.5803 5.15869 15 5.60641 15 6.15869C15 6.71097 14.5803 7.15869 14.0625 7.15869Z"
                    fill="#6B6B6B"
                  />
                </svg>

                <div>
                  <p className="textValueProject">Custo total</p>
                  <p className="textInfoProject">
                    {loading == true ? (
                      <div
                        class="ssc-line w-100 "
                        style={{ marginBottom: "15px" }}
                      ></div>
                    ) : scaleService == false ? (
                      priceTotal.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })
                    ) : (
                      priceTotal.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })
                    )}
                  </p>
                </div>

                <svg
                  width="2"
                  height="59"
                  viewBox="0 0 2 59"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.20898 59L1.20898 7.74861e-07"
                    stroke="#C1C1C1"
                    stroke-width="0.8"
                  />
                </svg>

                <div>
                  <p className="textValueProject">Desenvolvimento</p>
                  <p
                    className="textInfoProject"
                    style={{ textAlign: "center" }}
                  >
                    {" "}
                    {loading == true ? (
                      <div
                        class="ssc-line w-100 "
                        style={{ marginTop: "15px" }}
                      ></div>
                    ) : (
                      `${totalDays} dias úteis`
                    )}{" "}
                  </p>
                </div>
              </div>

              {loading == true ? (
                <></>
              ) : (
                <button
                  style={btnFinish}
                  onClick={() => redirectToPaymentPage()}
                >
                  <p className="textBtnFinish">Prosseguir</p>
                </button>
              )}
            </footer>
          </div>
        }
      />

      <ModalCreateCustomComponent show={showCustomModal} />
    </>
  );
};

export default GeneralDefinitionUpdate;
