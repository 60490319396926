import React,{useEffect,useState} from 'react'
import api from '../../services/api'
import {Navigate} from 'react-router-dom'

const PrivateRoute =  ({ children }) =>{

    const [status,setStatus] = useState()


    const getStatus = async () => {
        const response = await api.get(`client/user/${localStorage.getItem("@MAIL")}`)
        setStatus(response.status)
    }

    useEffect(() => {

        getStatus()

    },[])

    
    return status != 401 ? children : <Navigate to="/"/> 


}

export default PrivateRoute