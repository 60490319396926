import React,{useState,useEffect} from 'react'
import backIcon from '../../images/png/backIcon.png'
import api from '../../services/api'
import BigHeads from '../BigHeads'
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const HeaderPayment = ({type}) =>{

    const [name,setName] = useState()
    const [surName,setSurName] = useState()
    const [picture,setPicture]  = useState()
    
    const getData = async () => {

        const mail = localStorage.getItem("@MAIL")
        const response = await api.get(`client/user/${mail}`)        

        setName(response.data[0].name)
        setSurName(response.data[0].surname)
        setPicture(response.data[0].picture)

    }

    const navigate = useNavigate()

    const setType = () => {

        localStorage.setItem("@TYPE","mobile")

    }
    const exit = async () => {

        localStorage.clear()
        
        toast.success('Saindo...', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            
            progress: undefined,
            theme: "light",
          });

        window.location.href="/"

    }

    useEffect(() =>{
        setType()
        getData()

    },[])

    const columnHeader = {

        position:"fixed",
        width:"100%",
        gridColumnStart: '1',
        gridColumnEnd: '3',
        gridRowStart: '1',
        zIndex:"4",
        background:"white",
        display:"flex",
        justifyContent: "space-between",
        alignItems:"center",
        gridRowEnd: '1',
        paddingLeft:"6.6rem",
        borderBottom:"1.9px solid #d7d7d7",
        height:"4.3rem",
        paddingRight:'3.25rem'
    }

    const userInfo = {

        display:"flex",
        alignItems:"center",
        paddingLeft:"1.1rem",
        justifyContent: "space-between",
        paddingRight:"1.1rem",
        width:"234px",
        background:"white",
        height:"45px",
        border:"1.9px solid #d7d7d7",
        borderRadius:"60px"

    }

    return(
        
        
        <header style={columnHeader}>
        <ToastContainer />

        {type == 'definicao' 
        
        ?

        <>
        <img src={backIcon} style={{cursor:"pointer"}} onClick={() => navigate("/general")}/>
        <p className="textHeaderThowColumnLayout">Pagamento e contrato</p>

        <div style={{marginLeft:"2.625rem",marginRight:"3.5rem"}}>
     
        </div>

        <div style={{marginRight:"10.563rem"}}>
  
        <svg width="382" height="35" viewBox="0 0 382 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="54" y="13.897" width="101" height="6.17647" rx="3.08824" fill="#EFF0F7"/>
        <rect x="54" y="14" width="101" height="6" rx="3" fill="#1172EB"/>
        <rect x="227" y="14" width="101" height="6.17647" rx="3.08824" fill="#EFF0F7"/>
        <rect x="227" y="14" width="101" height="6" rx="3" fill="#1172EB"/>
        <circle cx="17.5" cy="17.5" r="17.5" fill="#1172EB"/>
        <path d="M16.6996 22.2061V12.9261L14.8916 13.3421V12.0941L17.3716 11.0061H18.4596V22.2061H16.6996Z" fill="white"/>
        <circle cx="190.534" cy="17.5" r="17.5" fill="#1172EB"/>
        <path d="M186.425 22.147V21.203C187.182 20.6056 187.897 20.003 188.569 19.395C189.251 18.7763 189.854 18.1683 190.377 17.571C190.91 16.9736 191.326 16.387 191.625 15.811C191.934 15.235 192.089 14.6803 192.089 14.147C192.089 13.7523 192.019 13.3843 191.881 13.043C191.753 12.7016 191.539 12.4296 191.241 12.227C190.942 12.0136 190.537 11.907 190.025 11.907C189.534 11.907 189.123 12.019 188.793 12.243C188.462 12.4563 188.211 12.7443 188.041 13.107C187.881 13.4696 187.801 13.859 187.801 14.275H186.505C186.505 13.539 186.659 12.9096 186.969 12.387C187.278 11.8536 187.699 11.4483 188.233 11.171C188.766 10.8936 189.369 10.755 190.041 10.755C191.033 10.755 191.843 11.0376 192.473 11.603C193.113 12.1576 193.433 12.9896 193.433 14.099C193.433 14.7603 193.267 15.4163 192.937 16.067C192.606 16.707 192.185 17.331 191.673 17.939C191.161 18.5363 190.611 19.0963 190.025 19.619C189.449 20.1416 188.91 20.611 188.409 21.027H193.817V22.147H186.425Z" fill="white"/>
        <circle cx="363.567" cy="17.5" r="17.5" fill="#1172EB"/>
        <path d="M363.092 22.3391C362.377 22.3391 361.732 22.2111 361.156 21.9551C360.58 21.6884 360.116 21.2937 359.764 20.7711C359.423 20.2484 359.241 19.5977 359.22 18.8191H360.58C360.591 19.4591 360.815 20.0137 361.252 20.4831C361.689 20.9417 362.303 21.1711 363.092 21.1711C363.881 21.1711 364.473 20.9524 364.868 20.5151C365.273 20.0777 365.476 19.5497 365.476 18.9311C365.476 18.4084 365.348 17.9817 365.092 17.6511C364.847 17.3204 364.511 17.0751 364.084 16.9151C363.668 16.7551 363.209 16.6751 362.708 16.6751H361.876V15.5391H362.708C363.433 15.5391 363.999 15.3737 364.404 15.0431C364.82 14.7124 365.028 14.2484 365.028 13.6511C365.028 13.1497 364.863 12.7391 364.532 12.4191C364.212 12.0884 363.732 11.9231 363.092 11.9231C362.473 11.9231 361.977 12.1097 361.604 12.4831C361.231 12.8457 361.023 13.3044 360.98 13.8591H359.62C359.652 13.2511 359.812 12.7124 360.1 12.2431C360.399 11.7737 360.804 11.4111 361.316 11.1551C361.828 10.8884 362.42 10.7551 363.092 10.7551C363.817 10.7551 364.42 10.8831 364.9 11.1391C365.391 11.3951 365.759 11.7364 366.004 12.1631C366.26 12.5897 366.388 13.0591 366.388 13.5711C366.388 14.1364 366.233 14.6537 365.924 15.1231C365.615 15.5817 365.151 15.8911 364.532 16.0511C365.193 16.1897 365.743 16.5044 366.18 16.9951C366.617 17.4857 366.836 18.1311 366.836 18.9311C366.836 19.5497 366.692 20.1204 366.404 20.6431C366.127 21.1551 365.711 21.5657 365.156 21.8751C364.601 22.1844 363.913 22.3391 363.092 22.3391Z" fill="white"/>
        </svg>


        </div>


        <div class="dropdown">
        <button style={userInfo} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <div style={{position:"relative",top:"-5px",right:"18px"}}>
        {picture != undefined 
        
        ?
        <img  src={"data:image/;base64;"+ picture} style={{width:"37px",height:"37px",borderRadius:"100%",position:"relative",top:"5px",left:"5px"}}/>        
        :
        <BigHeads/>

        }
        </div>
        <p className="textClientInfoHeader">{name} {surName}</p>

        <svg width="8" height="8" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.182668 1.78582C0.0688405 1.57763 0.0688405 1.32582 0.182668 1.11763C0.446809 0.634515 1.14065 0.634515 1.40479 1.11763L2.41937 2.97328C3.12207 4.25852 4.96793 4.25852 5.67064 2.97327L6.68521 1.11763C6.94935 0.634515 7.64319 0.634515 7.90733 1.11763C8.02116 1.32582 8.02116 1.57763 7.90733 1.78582L6.67725 4.03563C5.53942 6.11672 2.55058 6.11672 1.41275 4.03563L0.182668 1.78582Z" fill="black"/>
        </svg>


        </button>

        
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="/profile">Perfil</a>
            <a class="dropdown-item" href="/dashboard">Projetos</a>
            <a class="dropdown-item" onClick={() => exit()} href="#">Sair</a>
        </div>
        </div>

    
        </>
    
        :
        
        <>
        <p className="textHeaderThowColumnLayout">Projetos</p>

        <div class="dropdown">
        <button style={userInfo} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <div style={{position:"relative",top:"-5px",right:"18px"}}>
        {picture != undefined 
        
        ?
        <img  src={"data:image/;base64;"+ picture} style={{width:"37px",height:"37px",borderRadius:"100%",position:"relative",top:"5px",left:"5px"}}/>        
        :
        <BigHeads/>

        }
        </div>
        <p className="textClientInfoHeader">{name} {surName}</p>

        <svg width="8" height="8" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.182668 1.78582C0.0688405 1.57763 0.0688405 1.32582 0.182668 1.11763C0.446809 0.634515 1.14065 0.634515 1.40479 1.11763L2.41937 2.97328C3.12207 4.25852 4.96793 4.25852 5.67064 2.97327L6.68521 1.11763C6.94935 0.634515 7.64319 0.634515 7.90733 1.11763C8.02116 1.32582 8.02116 1.57763 7.90733 1.78582L6.67725 4.03563C5.53942 6.11672 2.55058 6.11672 1.41275 4.03563L0.182668 1.78582Z" fill="black"/>
        </svg>


        </button>

        
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="/profile">Perfil</a>
            <a class="dropdown-item" href="/dashboard">Projetos</a>
            <a class="dropdown-item" onClick={() => exit()} href="#">Sair</a>
        </div>
        </div>

        </>


        }

       
        </header>
            
    )

}

export default HeaderPayment