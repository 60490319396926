import React, { createContext, useState } from 'react'

export const PaymentContext = createContext({})

const PaymentProvider = ({ children }) => {

    const [formData, setFormData] = useState({
        mail: "",
        name: "",
        userCpf: "",
        cep: "",
        userAddress: "",
        address: "",
        uf: "",
        userCity: "",
        number: "",
        complement: "",
        userDistrict: "",
        dueDate: "",
        cityState: "",
        ufState: "",
    });

    const updateState = (key, value) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [key]: value,
        }));
      };


    return (
        <PaymentContext.Provider value={{ formData, updateState }}>
            {children}
        </PaymentContext.Provider>
    )

}

export default PaymentProvider