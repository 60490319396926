import { useEffect, useState } from "react"
import checkOn from "../../../images/Svg/checkOn.svg"
import api from "../../../services/api"


const CardFunctionaLitiesCart = ({id,checked,globalId,checkFollowUp,custom,project_id}) => {

    const [image,setImage] = useState()
    const [price,setPrice] = useState()
  
    const [daysToDevelop,setDaysToDevelop] = useState()
    const [nameComponent,setNameComponent] = useState()
    const [check,setCheck] = useState(false)
  

    const [nameCustom,setNameCustom] = useState()
    const [descriptionCustom,setDescriptionCustom] = useState()
    const [typeProject,setTypeProject] = useState()

    const urlParams = new URLSearchParams(window.location.search);
    const idProject = urlParams.get("id")


      const getInfo = async () => {
   
        const typeProject = await api.post("project/project/info/pay", {
          mail: localStorage.getItem("@MAIL"),
          project_id:idProject
        });

        const response = await api.post(`components/simple/sidebar/`,{
  
          "type":typeProject.data[0].type_project,
          "id":id
  
        })
 
                    
        setTypeProject(typeProject.data[0].type_project)

        if(typeProject.data[0].type_project == "web"){   
  
            const imageDesktop = (response.data[0][0].desktop_icon)
            setImage(imageDesktop)
  
  
        }else{
  
  
          const imageMobile =response.data[0][0].mobile_icon
          setImage(imageMobile)

        }
  
        setNameComponent(response.data[0][0].name)
        setPrice(response.data[0][0].price)
        setDaysToDevelop(response.data[0][0].develop_time)
      }
  
      const getInfoCustom = async () => {
        const response = await api.get(`custom/project/component/${project_id}`)

        setNameCustom(response.data[0].name)
        setDescriptionCustom(response.data[0].description)
  
      }
   
  
      useEffect(() => {
  
        getInfo()
        getInfoCustom()
  
      },[idProject])

      useEffect(() => {

        getInfo()


      },[])
  
      useEffect(() =>{
        
  
        if(checked != 0 && checked != undefined){
          setCheck(true)
  
  
        }
  
      },[checked])
  
  
      setInterval(() => {
  
  
        if(checked != 0 && checked != undefined){
          setCheck(true)
  
  
        }
      },[1000])

    return (
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "12px 23px",
            borderBottom: "1px solid #E5E5E5",
        }}>

            <div style={{
                display: "flex",
                gap: "20px",
                alignItems: "center"
            }}>
                <img src={image} style={typeProject == "web" ? { width: "90px", height: "60px", objectFit: "contain",border: "1px solid var(--color-primary)", borderRadius: "3px" }: { width: "35px", height: "77px", objectFit: "contain", border: "1px solid var(--color-primary)", borderRadius: "3px"}} />

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "14px"
                }}>
                    <p className="textSelectFeatSection" >{custom == "false" ? nameComponent : nameCustom}</p>

                    <p className="textSelectFeatSection">{check == true  && checkFollowUp != 0  ? "Implementada" :  "Em desenvolvimento"}</p>

                </div>
            </div>
            <img src={check ? checkOn : ""} />
        </div>
    )
}

export default CardFunctionaLitiesCart