import editIcon from "../../../images/Svg/editIcon.svg";
import searchIcon from "../../../images/Svg/searchFunctionaIcon.svg";

const SearchFunctionaLities = ({ addedProject,onChange }) => {
  return (
    <div
      style={{
        padding: "21px 20px",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #E5E5E5",
      }}
    >
      <div
        style={{
          border: "1px solid #D7D7D7",
          borderRadius: "60px",
          height: "35px",
          display: "flex",
          alignItems: "center",
          padding: "0 12px",
          gap: "14px",
          width: "79%",
          backgroundColor: "#fff",
        }}
      >
        <img src={searchIcon} />
        <input
        onChange={onChange}
          className="textInputSearchFunctionaLities"
          placeholder="Pesquisar"
          style={{ border: "none", outline: "none" }}
        />
      </div>

      <img src={editIcon} onClick={addedProject} />
    </div>
  );
};

export default SearchFunctionaLities;
