import React from 'react'
import scaleServiceBackground from '../../images/Svg/scaleServiceBackground.svg'
import SwitchButton from '../../components/SwitchButton';
import ThreeColumLayoutFollowUpPage from "../../layouts/ThreeColumLayoutFollowUpPage";

const Scale = () => {

    return (

        <ThreeColumLayoutFollowUpPage
            typeHeader={"follow"}
            colum1Data={

                <>
                <SwitchButton page="scale" />

                    <div
                        style={{
                            display: "flex",
                            height: "100vh",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >


                        <img src={scaleServiceBackground} />

                    </div>

                </>


            }
        />
    )

}

export default Scale