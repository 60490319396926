import { useEffect, useState } from 'react';
import webIcon from "../../../images/Svg/webIcon.svg"
import mobileIcon from "../../../images/Svg/mobileIcon.svg"
import profileChat from "../../../images/Svg/profileChat.svg"
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import api from '../../../services/api';

const SpinerPercentag = ({ percent }) => {
    const [types, setTypes] = useState([])

    const typeProject = async () => {
        const response = await api.post(`global/types/project`, {

            global_id: localStorage.getItem("@GLOBAL_ID_PROJECT2")
        })


        const localEvent = localStorage.setItem("@GLOBAL_TYPE_PROJECT_LOCAL_VALUE")

        setTypes(response.data)
        console.log(response.data)
    }

    useEffect(()=>{
        typeProject()
    },[])

    return (
        <div style={{
            padding: "24px 25px"
        }}>
            <h3 className="textTitleSpinerCard">Acompanhamento do projeto</h3>

            <div style={{
                border: "1px solid #D7D7D7",
                borderRadius: "15px",
                padding: "43px 0 28px",
                display: "flex",
                flexDirection: "column",
            }}>

                <div style={{ width: "134px", height: "134px", margin: "0 auto" }}>

                    <CircularProgressbar
                        value={percent}
                        text={`${percent} %`}
                        strokeWidth={"13"}
                        className="text_circular_progressbar_followUp"
                        styles={buildStyles({
                            rotation: 0.50,
                            strokeLinecap: 'round',
                            textSize: '16px',
                            pathTransitionDuration: 0.5,
                            pathColor: `var(--color-primary)`,
                            textColor: '#000000',
                            trailColor: '#00000010',
                            backgroundColor: '#3e98c7',
                        })}

                    />
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "24px",
                    borderBottom: "1px solid #D7D7D7",
                    marginTop: "35px"
                }}>
                    <div style={{
                        margin: "0 auto",
                        display: "flex",
                        flexDirection: "column",
                        gap: "9px",
                    }}>
                        <p className="textTitleCardSpinerPorcent">Status Overview</p>
                        <p className="textTitleCardSpinerPorcent">Entrega final</p>
                        <p className="textContentCardSpinerPorcent">{localStorage.getItem("@PROJECT_DUE_DAY") != undefined && localStorage.getItem("@PROJECT_DUE_DAY")}</p>
                    </div>
                </div>

                <div style={{
                    borderBottom: "1px solid #D7D7D7",
                    padding: "19px 0"
                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: "12px"
                    }}>
                        <p className="textTitleCardSpinerPorcent">Nome do projeto</p>
                        <p className="textContentCardSpinerPorcent">Novo Projeto</p>
                    </div>
                </div>

                <div style={{
                    borderBottom: "1px solid #D7D7D7",
                    padding: "19px 0"
                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "15px"
                    }}>
                        {types?.totalWeb ? <img src={webIcon} /> : ""}
                        {types?.totalMobile ? <img src={mobileIcon} /> : ""}
                    </div>
                </div>

                <div style={{
                    margin: "20px auto 0"
                }}>
                    <div style={{
                        display: "flex",
                        gap: "16px",
                        alignItems: "center",
                        border: "1px solid #D7D7D7",
                        borderRadius: "10px",
                        padding: "7px 9px"
                    }}>
                        <img src={profileChat} />
                        <p className="textChatCardSpinerPorcent">falar com seu gestor de projeto</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SpinerPercentag