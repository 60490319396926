import Button from "../../RegisterMobile/components/Button"
import Input from "../../RegisterMobile/components/Input"
import logoPlatform from "../../../images/Svg/logoPlatform.svg"
import { useEffect, useState } from "react"
import FooterTextNavigate from "../../RegisterMobile/components/FooterTextNavigate"
import { useNavigate } from "react-router-dom"
import api from "../../../services/api"
import { toast } from "react-toastify"

const LandingMobile = () => {
    const [btnState, setBtnState] = useState(false)
    const [mail,setMail] = useState()
    const [password,setPassword] = useState()

    const [mailError,setMailError] = useState()
    const [passwordError,setPasswordError] = useState()
    const [messageError,setMessageError] = useState()
    
    const [btnDisable,setBtnDisble] = useState(false)
    const navigate = useNavigate();

    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const login = async () => {


        if(mail == undefined){

            setMailError("Informe um email")

        }
        
        if(password == undefined){

            setPasswordError("Informe uma senha")

        }

        if(mail == undefined || password == undefined){

            return false
        }


        const response = await api.post("sessions/user",{

            mail:mail,
            password:password

        })
        

        if(response.status == 200){
            setMessageError()
            localStorage.setItem("@PROJECT_CHANGE_ID",response.data.user.project_id)
            localStorage.setItem("@PROJECT_CHANGE_ID2",response.data.user.project_id)

            localStorage.setItem("@MAIL",mail)
            localStorage.setItem("@TOKEN",response.data.token)

            window.location.href=`/functionalities/?id=${response.data.user.project_id}`


        }else{
            toast.error('Email ou senha inválidos!', {
                position: "top-right",
                autoClose: 1300,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }



    }


    useEffect(() => {

        if(mail != undefined){

            setMailError()

        }

        if(password != undefined){

            setPasswordError()

        }

        if(mail !== undefined){

            const responseValidateMail = validateEmail(mail)

            if(responseValidateMail == false){
    
                setMailError('email inválido!')
                setBtnDisble(true)
            }else{



                setBtnDisble(false)
            }
    
        }
            


        if(String(password).length > 0){

            if(String(password).length < 8){

                setPasswordError("Senha deve ter pelo menos 8 caracteres")
                setBtnDisble(true)

            }else{

                setBtnDisble(false)

            }

        }
       

        

    },[mail,password])

    const container = {
        padding: "0 39px 6vh",
        backgroundColor: "#E5E5E520"
    }

    const containerLogo = {
        marginBottom: "72px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        paddingTop: "122px"
    }

    const boxInputs = {
        display: "flex",
        flexDirection: "column",
        marginTop: "28px",
        gap: "30px"
    }

    return (
        <div style={container}>
            <div style={containerLogo}>
                <img src={logoPlatform} />
            </div>
            <h3 className="textTitleForm">Faça o login</h3>
            <div style={boxInputs}>
                <Input error={mailError}  value={mail} onChange={(e)=> setMail(e.target.value)} name="E-mail" type={"email"} placeholder="Seu e-mail de cadastro" />
                <Input error={passwordError}  value={password} onChange={(e)=> setPassword(e.target.value)} name="Senha" type={"password"} placeholder="8+  Caracteres" />
            </div>

            <div style={{ textAlign: "end", marginTop: "25px" }} onClick={() => navigate("/forgot/password")}>
                <p className="textHasAccountLogin">Esqueceu a senha?</p>
            </div>

            <div style={{ marginTop: "48px" }}>
                <Button width="100%" height="45px" background="var(--color-primary)"
                    borderRadius="60px" marginTop="0" name="Login"
                    border="none" disabled={btnState}  onClick={() => login()}/>
            </div>

            <div style={{ marginTop: "115px", width: "100%"}}>
                <FooterTextNavigate question={"Não e cadastrado?"} response={"Cadastre-se"} onClick={() => navigate("/register")} />
            </div>
        </div>
    )
}

export default LandingMobile