import React, { useEffect, useState,useContext } from 'react'

import customComponent from '../../images/Svg/customComponent.svg'
import customComponentWeb from '../../images/Svg/customComponentWeb.svg'
import {CartContext} from '../../context/cart'
import api from '../../services/api'
import Modal from 'react-bootstrap/Modal';


const PreviewCustomFunctionalitie = ({id,name}) => {

  const {removeCustomComponent} = useContext(CartContext)
  const [showCustomModal,setShowCustomModal] = useState(false)
  const [itemId,setId] = useState()

  
  const [nameEdit,setNameEdit] = useState()
  const [description,setDescription] = useState()

  const [nameError,setNameError] = useState()
  const [descriptionError,setDescriptionError] = useState()

    const functionalitie = {

        width:"100%",
        height:"96px",
        paddingBottom:"2.25rem",
        height:"90px",
        borderBottom:"1px solid #D7D7D7"
          
          
      }


      const getModal = async (id) => {

        setId(id)

        const response = await api.get(`custom/component/edit/${id}`)

        setNameEdit(response.data[0].name)
        setDescription(response.data[0].description)
        setShowCustomModal(true)

      }


      const storage = async () => {

        if(name == undefined){


            setNameError("Informe um nome")

        }

        
        if(description == undefined){


            setDescriptionError("Informe uma descrição")

        }

        if(name == undefined || description == undefined){

            return false

        }

        const response = await api.put("custom/component/",{

            "name":nameEdit,
            "description":description,
            "id":itemId

          })

          if(response.status == 200){

            window.location.href="/functionalities"


          }

    }
    return(

      <>
    
    <button   className='btn'   style={functionalitie} type="button">
   
    {name != undefined 
    
    ?

    <div style={{display:"flex",alignItems:'center',width:"290px",justifyContent:"space-between"}}>
    
    <div>

    { localStorage.getItem("@TYPE_PROJECT") == "web" 
    ?
    <img src={customComponentWeb}  style={{objectFit:'cover',width:'84px',borderRadius:'5px'}}/>

    :
    <img src={customComponent}  style={{objectFit:'contain',width:'35px',height:"76px",borderRadius:'5px'}}/>

    }


    </div>
    <div style={{height:'4.5rem',display:"flex",width:"400px",alignItems:"center",justifyContent:"space-between"}}>
    
    <div style={{marginLeft:"1.25rem",width:"210px"}}>
    <p className="textFunctionlitieCartPrimary">{name}</p>
    <p className="textFunctionlitieCartPrimary" style={{color:"#1172EB"}} onClick={() => getModal(id)  }>Editar</p>

    </div>



    </div>


    </div>
      
    
    : 

    <></>

    }


    </button>

        <Modal show={showCustomModal}  style={{borderRadius:"20px"}}   centered={true} animation={true}>
        
        <Modal.Header style={{border:"none",padding:0}}>
        
        <div style={{display:"flex",paddingBottom:"10px",paddingTop:"10px",paddingLeft:"30px",alignItems:"center",width:"100%",borderBottom:"1px solid #D7D7D7"}}>
        
        <div onClick={() => setShowCustomModal(false)} >
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.49773 6.36766L12.5007 1.35916C12.8113 1.04823 12.8113 0.544109 12.5007 0.2332C12.1902 -0.0777335 11.6867 -0.0777335 11.3761 0.2332L6.37365 5.24222L1.37119 0.2332C1.06064 -0.0777335 0.557125 -0.0777335 0.246591 0.2332C-0.0639429 0.544134 -0.0639678 1.04825 0.246591 1.35916L5.24956 6.36766L0.246591 11.3762C-0.0639678 11.6871 -0.0639678 12.1912 0.246591 12.5021C0.557149 12.8131 1.06066 12.8131 1.37119 12.5021L6.37365 7.4931L11.3761 12.5021C11.6867 12.8131 12.1902 12.8131 12.5007 12.5021C12.8113 12.1912 12.8113 11.6871 12.5007 11.3762L7.49773 6.36766Z" fill="black"/>
        </svg>
        </div>

        <p className="textHeaderModalCreateProject">Editar Funcionalidade</p>

        </div> 
        
        </Modal.Header>
        
        <Modal.Body style={{width:"100%",paddingLeft:"52px",paddingRight:"30px"}}>

        
        <p className="textPrimaryModalCreateProject">Obervação</p>
        <p className="textSecondaryModalCreateProject">O preço e o prazo de entrega das funcionalidades novas e calculado manualmente e cobrado a parte.</p>

        <div style={{display:"flex",flexDirection:"column",width:"390px",position:"relative"}}>
          <label className="labelModalCreateProject">Título da funcionalidade</label>
          <input className="textInputModalCreateProject" maxLength={60} onChange={(e) => setNameEdit(e.target.value)} value={nameEdit} style={{outline:"none",height:"45px",border:"1px solid #D7D7D7",borderRadius:"7px",paddingLeft:"12px",marginTop:"14px"}} type={"text"} placeholder="Insira um nome"/>
          {nameError != undefined ?<p className="error_message_modal_create_custom">informe um título</p> :<></>}
        </div>

        <div style={{display:"flex",flexDirection:"column",width:"390px",marginTop:"33px",position:"relative"}}>
          <label className="labelModalCreateProject">Descrição da funcionalidade</label>
          <textarea className="textInputModalCreateProject" value={description} maxLength={600} onChange={(e) => setDescription(e.target.value)} style={{outline:"none",height:"240px",border:"1px solid #D7D7D7",borderRadius:"7px",paddingLeft:"12px",marginTop:"14px",paddingTop:"12px",resize:"none"}} type={"text"} placeholder="Insira uma descrição completa"/>
          {descriptionError != undefined ?<p className="error_message_modal_create_custom">informe uma descrição</p> :<></>}

        </div>

        <button className="textBtnModalCreateProject" onClick={() => storage()}  style={{border:"none",color:"white",background:"#1172EB",width:"390px",height:"40px",borderRadius:"60px",marginTop:"2.5rem",marginBottom:"1rem"}}>Salvar</button>

        </Modal.Body>
       
      </Modal>

    

    </>
    )

}

export default PreviewCustomFunctionalitie