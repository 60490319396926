import React,{createContext,useState} from 'react'

import api from '../services/api'

export const VelocityContext = createContext({})

function VelocityProvider({children}){

    const [update,setUpdate] = useState(false)
    const [priceComponent,setPriceComponent] = useState(0)
    const [daysToDevelop,setDaysToDevelop] = useState()

    const [itemsInCart,setItemsInCart] = useState()

    const changeVelocity = async (velocity,startpn_scale) => {

        const response = await api.post("velocity",{

            "mail":localStorage.getItem("@MAIL"),        
            "velocity":velocity,

        })
        
    
          setPriceComponent(response.data.price)
          setDaysToDevelop(response.data.days)

          setUpdate(!update)


    }

    const storageScaleService = async (startpn_scale) => {


        if(startpn_scale == false ){

            const responseInfoComponent = await api.post(`cart/all/value`,{
        
                "mail":localStorage.getItem("@MAIL"),
                "startpn_scale":startpn_scale
    
              })
        
              setPriceComponent(responseInfoComponent.data.price)
              setDaysToDevelop(responseInfoComponent.data.developTime)
              setItemsInCart(responseInfoComponent.data.items)

              return false

        }

        const response = await api.post("/cart/scale/service",{
  
          "mail":localStorage.getItem("@MAIL"),
          "status":startpn_scale
  
        })
  
            
        setPriceComponent(response.data.price)
        setDaysToDevelop(response.data.developTime)
        setItemsInCart(response.data.items)
  
      }
  
      const calcStartpnScale = priceComponent * 1 /100

      const total = Number(calcStartpnScale).toFixed(2)


    
    return(
    <VelocityContext.Provider value={{changeVelocity,storageScaleService,update,priceComponent,daysToDevelop,total}}>
        {children}
    </VelocityContext.Provider>
    )

}

export default VelocityProvider