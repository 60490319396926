const PercentageLine = ({ percent }) => {

    const container = {
        width: "100%",
        backgroundColor: "#D7D7D7",
        height: "8px",
        border: "1px solid trasnparent",
        borderRadius: "10px"
    }

    const box = {
        width: `${percent}%`,
        height: "100%",
        backgroundColor: "#1172EB",
        border: "1px solid trasnparent",
        borderRadius: "10px"
    }

    return (
        <div style={container}>
            <div style={box}
            ></div>
        </div>
    )
}

export default PercentageLine