import { useState } from "react"
import InfoProjectMobile from "./InfoProject"

const BoxCardsCardsForPurchase = ({ projects }) => {
    const [totalLength, setTotalLength] = useState(false)



    return (
        <div style={{
            paddingBottom: "20px",
            margin: "0 25px 18px",
            overflowX: "auto",
            maxWidth: "100%",
            display: "flex",
            flexWrap: "nowrap",
            gap: "15px"
        }}>
            {
                    projects.length > 0 ?
                    projects.map(item => {
                        return (

                            <InfoProjectMobile setTotalLength={setTotalLength} id={item.id} desktop_icon={item.desktop_icon} mobile_icon={item.mobile_icon} project_id={item.project_id} name={item.name} type_project={item.type_project} />

                        )

                    })
                    :
                    <></>
            }
   

        </div>
    )
}

export default BoxCardsCardsForPurchase