import { useEffect, useState } from "react"
import prototypeIcon from "../../../images/Svg/prototype.svg"
import CardEndDateProject from "./CardEndDateProject"
import api from "../../../services/api"
import CardPrototypeMobile from "./CardPrototypeMobile"


const EndDateProject = () => {
    const [projects, setProjects] = useState()

    const getProjects = async () => {


        const response = await api.post("project/project/follow", {
            mail: localStorage.getItem("@MAIL"),
            global_id: localStorage.getItem("@GLOBAL_ID_PROJECT2")
        })

        setProjects(response.data)

    }

    useEffect(() => {
        getProjects()
    }, [])

    return (
        <div style={{
            padding: "24px 25px"
        }}>

            <div style={{
                border: "1px solid #D7D7D7",
                borderRadius: "15px",
                padding: "21px 0",
                display: "flex",
                flexDirection: "column",
                height: "603px"
            }}>

                <div style={{
                    padding: "0 22px 18px",
                    borderBottom: "1px solid #D7D7D7",
                }}>
                    <h3 className="textTitleDateEndDateProjectCard">Datas de entrega</h3>
                </div>

                <div style={{
                    padding: "19px 21px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    height: "90%",
                    overflowY: "scroll"
                }}>

                    <CardPrototypeMobile />
                   
                    {
                        projects?.map(item => {

                            return (

                                <CardEndDateProject
                                    id={item.id}
                                    name={item.name}
                                    type={item.type_project}
                                    project_id={item.project_id}
                                    picture={prototypeIcon}
                                    date={"10/04/2022"}
                                />
                            )
                        })
                    }



                </div>

            </div>

        </div>
    )
}

export default EndDateProject