import React,{createContext,useState} from 'react'

import api from '../services/api'

export const DashboardContext = createContext({})

function DashboardProvider({children}){

    const [totalDashboard,setTotalDashboard] = useState(0)
    const [globalDashboardState,setGlobalDashboardState] = useState(false)

    const getTotalDashboard = async () => {

        const response = await api.post('dashboard/total',{

            mail:localStorage.getItem("@MAIL"),
            project_id:localStorage.getItem("@PROJECT_CHANGE_ID_FOLLOW_PAGE"),

        })


        
        setTotalDashboard(response.data.total)
      }
  

    const getDashboard = async () => {

        const response = await api.post('dashboard/total',{

            mail:localStorage.getItem("@MAIL"),
            project_id:localStorage.getItem("@PROJECT_CHANGE_ID_FOLLOW_PAGE"),

        })

    }

    const updateGlobalDashboard  = async (id,globalId,check) => {

        const globalDashboard = await api.post("/checklist/global/project",{

            mail:localStorage.getItem("@MAIL"),
            state:!check,
            componentId:id,
            project_id:localStorage.getItem("@PROJECT_CHANGE_ID_FOLLOW_PAGE"),
            global_id:globalId
          })

          setGlobalDashboardState(!globalDashboardState)
    

    }
  

    
    return(
    <DashboardContext.Provider value={{getTotalDashboard,getDashboard,updateGlobalDashboard,globalDashboardState,totalDashboard}}>
        {children}
    </DashboardContext.Provider>
    )

}

export default DashboardProvider