const ButtonCreated = ({ onClick }) => {
  const container = {
    width: "60px",
    height: "60px",
    color: "#fff",
    backgroundColor: "var(--color-primary)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    bottom: "39px",
    right: "22px",
    borderRadius: "60px",
    fontSize: "42px",
    boxShadow: "0px 0px 20px -5px #000",
  };

  return (
    <div className="btnAddProject" onClick={onClick} style={container}>
      +
    </div>
  );
};

export default ButtonCreated;
