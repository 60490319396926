import { useContext, useEffect, useState } from "react";
import btnAdd from "../../../images/Svg/btnAdd.svg";
import showSelectIcon from "../../../images/Svg/showSelect.svg";
import mobileIcon from "../../../images/Svg/mobile.svg";
import webIcon from "../../../images/Svg/web.svg";
import api from "../../../services/api";
import { CartContext } from "../../../context/cart";

const SelectAndAdded = ({
  value,
  setValue,
  list,
  addedPlatform,
  unique,
  typeFunctionaLities,
  id
}) => {

  const [valueName, setValueName] = useState();
  const [typeProjectChange, setTypeProjectChange] = useState();
  const [projects,setProjects] = useState()
  
  const parentElement = document.querySelector("#selectAdd");
  const [showSelect, setShowSelect] = useState(false);
  const [width, setWidth] = useState(parentElement?.offsetWidth - 41 || "100%");



  const getProject = async () => {
    const response = await api.post("project/info/sidebar", {
      mail: localStorage.getItem("@MAIL"),
      project_id: id,
    });

    localStorage.setItem("@TYPE_PROJECT", response.data[0]?.type_project);
    setTypeProjectChange(response.data[0]?.type_project);
    setValueName(response.data[0]?.name);
  };
  const getProjects = async () => {

    const response = await api.get(`project/all/${localStorage.getItem("@MAIL")}`)
    setProjects(response.data)

  }
  const updateNameProject = async (newNameProject) => {
    if (newNameProject == undefined || newNameProject == "") {
      return false;
    }

    
    const response = await api.put("project/project", {
      name: newNameProject,
      project_id:id,
    });
    
  };

  const redirectToSelectProject = (id) => {

    window.location.href=`/functionalities/?id=${id}`

  }
  const removeProject = async (id) => {

    const response = await api.delete(`/project/project/${id}`)

    if (response.status == 200) {

      window.location.href = "/functionalities"
    }




  }
  window.addEventListener("resize", function () {
    setWidth(parentElement?.offsetWidth - 41 || "100%");
  });

  useEffect(() => {
    if (value) {
      localStorage.setItem("@PROJECT_CHANGE_ID2", value.project_id);
      localStorage.setItem("@TYPE_PROJECT", value.type_project);
    }
  }, [value]);
  useEffect(() => {
    localStorage.setItem("@NAME_PROJECT", value?.name);
    setValueName(value?.name);
  }, [value]);
  useEffect(() => {
    getProject();
  }, []);
  useEffect(()=>{
    if(localStorage.getItem("@NAME_PROJECT")){
       setValue(list.find(item => item.name === localStorage.getItem("@NAME_PROJECT")))
    }
  },[])

  useEffect(() => {

    getProjects()

  },[])

  
  return (
    <div
      style={{
        padding: "21px 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #E5E5E5",
      }}
    >
      <div id="selectAdd" style={{ position: "relative", width: "80%" }}>
        <button
          className="textSelectProject"
          onClick={() => setShowSelect(!showSelect)}
          style={{
            backgroundColor: "#fff",
            color: "#000000",
            border: "1px solid #D7D7D7",
            width: "100%",
            height: "45px",
            borderRadius: "10px",
            position: "relative",
            padding: "0 35px 0 10px",
            overflow: "hidden",
            display: "flex",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <img
            src={
              (value?.type_project == "web" && webIcon) ||
                (typeProjectChange == "web" && webIcon) ||
              (value?.type_project == "mobile" && mobileIcon) ||
              (typeProjectChange == "mobile" && mobileIcon) ||
              (value?.type_project  == null && mobileIcon)
            }
          />
          {typeFunctionaLities ? (
            <input
              value={valueName}
              onChange={(e) => setValueName(e.target.value)}
              onBlur={() => updateNameProject(valueName)}
              style={{
                height: "20px",
                width: "77%",
                outline: "none",
                border: "none",
              }}
            />
          ) : (
            value?.name || list[0]?.name
          )}
          <img
            style={{
              transform: showSelect ? "rotate(0deg)" : "rotate(180deg)",
              transition: "0.3s",
              position: "absolute",
              right: "16px",
              top: "18px",
            }}
            src={showSelectIcon}
          />
        </button>
        {showSelect && (
          <ul
            style={{
              position: "absolute",
              top: "48px",
              backgroundColor: "#fff",
              border: "1px solid #D7D7D7",
              borderRadius: "0 0 10px 10px",
              width: unique ? "100%" : width,
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {projects.map((item) => {
              
              if(item.name != valueName){

                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: item?.type_project == "web" ? "20px" : "27px",
                      borderBottom: "1px solid #d7d7d7d7",
                      paddingBottom: "13px",
                    }}
                  >
                    <img
                      style={{
                        marginLeft: item?.type_project == "mobile" && "5px",
                        width: item?.type_project == "web" && "29px",
                      }}
                      src={
                        (item?.type_project == "web" && webIcon) ||
                        (item?.type_project == "mobile" && mobileIcon)
                      }
                    />
  
                    <li
                      className="textSelectProject"
                      onClick={() => {
                        redirectToSelectProject(item.project_id)

                      }}
                    >
                      {item?.name}
                      <div onClick={() => removeProject(item.project_id)} style={{ marginLeft: "1.135rem", marginTop:"-1rem", position: "absolute", right: "25px", cursor: "pointer" }}>

                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.6247 3.16667H14.1705C13.9868 2.2732 13.5006 1.4704 12.794 0.893568C12.0874 0.316738 11.2035 0.0011515 10.2913 0L8.70801 0C7.79585 0.0011515 6.91197 0.316738 6.20535 0.893568C5.49874 1.4704 5.01259 2.2732 4.82884 3.16667H2.37467C2.16471 3.16667 1.96335 3.25007 1.81488 3.39854C1.66642 3.54701 1.58301 3.74837 1.58301 3.95833C1.58301 4.16829 1.66642 4.36966 1.81488 4.51812C1.96335 4.66659 2.16471 4.75 2.37467 4.75H3.16634V15.0417C3.1676 16.0911 3.58504 17.0972 4.3271 17.8392C5.06916 18.5813 6.07525 18.9987 7.12468 19H11.8747C12.9241 18.9987 13.9302 18.5813 14.6723 17.8392C15.4143 17.0972 15.8318 16.0911 15.833 15.0417V4.75H16.6247C16.8346 4.75 17.036 4.66659 17.1845 4.51812C17.3329 4.36966 17.4163 4.16829 17.4163 3.95833C17.4163 3.74837 17.3329 3.54701 17.1845 3.39854C17.036 3.25007 16.8346 3.16667 16.6247 3.16667ZM8.70801 1.58333H10.2913C10.7824 1.58393 11.2612 1.73642 11.6622 2.01989C12.0632 2.30336 12.3666 2.70393 12.531 3.16667H6.46839C6.63272 2.70393 6.93618 2.30336 7.33715 2.01989C7.73812 1.73642 8.21696 1.58393 8.70801 1.58333ZM14.2497 15.0417C14.2497 15.6715 13.9995 16.2756 13.5541 16.721C13.1087 17.1664 12.5046 17.4167 11.8747 17.4167H7.12468C6.49479 17.4167 5.8907 17.1664 5.4453 16.721C4.9999 16.2756 4.74968 15.6715 4.74968 15.0417V4.75H14.2497V15.0417Z" fill="black" />
                    <path d="M7.91666 14.2503C8.12663 14.2503 8.32799 14.1669 8.47646 14.0185C8.62492 13.87 8.70833 13.6686 8.70833 13.4587V8.70866C8.70833 8.4987 8.62492 8.29733 8.47646 8.14887C8.32799 8.0004 8.12663 7.91699 7.91666 7.91699C7.7067 7.91699 7.50534 8.0004 7.35687 8.14887C7.20841 8.29733 7.125 8.4987 7.125 8.70866V13.4587C7.125 13.6686 7.20841 13.87 7.35687 14.0185C7.50534 14.1669 7.7067 14.2503 7.91666 14.2503Z" fill="black" />
                    <path d="M11.0837 14.2493C11.2936 14.2493 11.495 14.1659 11.6435 14.0175C11.7919 13.869 11.8753 13.6676 11.8753 13.4577V8.70768C11.8753 8.49772 11.7919 8.29636 11.6435 8.14789C11.495 7.99942 11.2936 7.91602 11.0837 7.91602C10.8737 7.91602 10.6723 7.99942 10.5239 8.14789C10.3754 8.29636 10.292 8.49772 10.292 8.70768V13.4577C10.292 13.6676 10.3754 13.869 10.5239 14.0175C10.6723 14.1659 10.8737 14.2493 11.0837 14.2493Z" fill="black" />
                  </svg>

                  </div>
                    </li>
                  </div>
                );

              }
            
            })}
          </ul>
        )}
      </div>
      <img onClick={addedPlatform} src={btnAdd} />
    </div>
  );
};

export default SelectAndAdded;
